<template>
  <v-container
    id="gauge_add_success"
    fluid
    class="gauge-add-success pt-0"
  >
    <page-section header-image>
      <gauge-list-image :src="gauge.image" />
      <h1 class="mb-0">{{ gauge.title }}</h1>
    </page-section>

    <page-section centered>
      <h2>Gauge Successfully Added!</h2>

      <template slot="actions">
        <v-btn 
          class="mb-3"
          depressed
          rounded
          large
          color="accent"
          title="View this Gauge"
          :to="{ name: 'gaugeDetail', params: { gid: gauge.gid } }"
        >
          View New Gauge
        </v-btn>
        <v-btn 
          text
          exact
          :to="{ name: 'dashboard' }"
          class="primary--text super-bare mb-1">Go To Dashboard</v-btn>
      </template>
    </page-section>
  </v-container>
</template>

<script>
import GaugesConnectorMixin from '@/mixins/connectors/gauges'
import GaugeListImage from '@/components/GaugeListImage/GaugeListImage'

export default {
  name: 'GaugeAddSuccess',

  components: {
    GaugeListImage
  },

  mixins: [GaugesConnectorMixin]
}
</script>

import Vue from 'vue'
import GaugesGetters from '../vuex/GaugesGetters'
import GaugeMutations from '../vuex/GaugeMutations'
import UserGetters from '../vuex/UserGetters'

const privateStore = Vue.observable({
  loading: false,
  fetched: false
})

export default {
  created () {
    // @todo is this needed?
    // this.fetchGauges()
  },

  mixins: [
    GaugesGetters,
    GaugeMutations,
    UserGetters
  ],

  computed: {
    gid () {
      const {
        params: {
          gid
        } = {}
      } = this.$route

      return gid
    },
    gauge () {
      return this.getGaugeById(this.gid)
    },
    gauges () {
      return this.getMyGauges
    },
    archivedGauges () {
      return this.getArchivedGauges
    },
    sharedGauges () {
      return this.getSharedGauges
    },
    gaugesLoading: {
      get () {
        return privateStore.loading
      },
      set (val) {
        privateStore.loading = val
      }
    },
    gaugesFetched: {
      get () {
        return privateStore.fetched
      },
      set (val) {
        privateStore.fetched = val
      }
    },
    gaugesReady () {
      return !this.gaugesLoading && this.gaugesFetched
    }
  },

  methods: {
    async fetchGauges () {
      if (!this.gaugesLoading && !this.gaugesFetched) {
        this.gaugesLoading = true
        await this.getUserGaugeData({ userId: this.getUserId })
        this.gaugesLoading = false
        this.gaugesFetched = true
      }
    }
  }
}

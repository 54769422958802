import authRequest from './utils/authRequest'

const ENDPOINT = '/notifications'

export default {
  async fetch ({ uid, limit, next }) {
    if (!uid) throw new Error('[notificationsApi] fetch: uid parameter is required')

    const { data = {} } = await authRequest({
      url: `${ENDPOINT}/${uid}`,
      method: 'get',
      params: {
        limit,
        next
      }
    })

    return data
  },

  async create ({ uid, gid, ...payload }) {
    if (!uid || !gid) throw new Error('[notificationsApi] post: uid and gid parameters are required')

    const { data = {} } = await authRequest({
      url: `${ENDPOINT}/${uid}/${gid}`,
      method: 'post',
      data: payload
    })

    return data
  },

  async update ({ uid, gid, ...payload }) {
    if (!uid || !gid) throw new Error('[notificationsApi] put: uid and gid parameters are required')

    const { data = {} } = await authRequest({
      url: `${ENDPOINT}/${uid}/${gid}`,
      method: 'put',
      data: payload
    })

    return data
  },

  async delete ({ uid, gid }) {
    if (!uid || !gid) throw new Error('[notificationsApi] put: uid and gid parameters are required')

    const { data = {} } = await authRequest({
      url: `${ENDPOINT}/${uid}/${gid}`,
      method: 'delete'
    })

    return data
  }
}

import scroll from 'zenscroll'

export default {
  data () {
    return {
      loading: false
    }
  },
  methods: {
    formSubmit (submitFn = () => {}) {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          scroll.to(this.$el)
          return false
        }

        this.loading = true

        submitFn()

        return true
      })
    }
  }
}

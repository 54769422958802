<template>
  <div class="dashboard-map">
    <map-controller
      private
      :gauges="getAllGauges"
      :public-gauges="publicGauges">
    </map-controller>
  </div>
</template>

<script>
import DisplayManagerMixin from '@/mixins/DisplayManagerMixin'
import { mapState, mapGetters } from 'vuex'

let DashboardMap = {
  name: 'dashboard-map',

  mixins: [DisplayManagerMixin],

  data () {
    return {}
  },

  computed: { 
    ...mapGetters(['getAllGauges']),
    ...mapState({
      publicGauges: state => state.publicData.gauges
    })
  }
}

export default DashboardMap
</script>

<style lang="sass">
  .dashboard-map
    position: relative
    z-index: 1
    height: 350px
</style>


import Checkbox from './_ui/Form/Checkbox/Checkbox'
import Collapse from './_ui/Collapse/Collapse'
import DateMenu from './_ui/DateMenu/DateMenu'
import DatePicker from './_ui/DatePicker/DatePicker'
import Drawer from './_ui/Drawer/Drawer'
import FilterList from './_ui/FilterList/FilterList'
import FloatingQuote from './_ui/FloatingQuote/FloatingQuote'
import ImageInput from './_ui/Form/ImageInput/ImageInput'
import LineChart from './_ui/LineChart/LineChart'
import ReactiveLineChart from './_ui/LineChart/ReactiveLineChart'
import MapController from './_ui/Map/MapController'
import MapDrawer from './_ui/Map/MapDrawer'
import Modal from './_ui/Modal/Modal'
import NumberCounter from './_ui/NumberCounter/NumberCounter'
import PageSection from './_ui/PageSection/PageSection'
import PillAlert from './_ui/PillAlert/PillAlert'
import Radio from './_ui/Form/Radio/Radio'
import SortableTable from './_ui/SortableTable/SortableTable'
import Spinner from './_ui/Spinner/Spinner'
import Stat from './_ui/Stat/Stat'
import Sticky from './_ui/Sticky/Sticky'
import Tooltip from './_ui/Tooltip/Tooltip'

export default Object.assign({},
  { Checkbox },
  { Collapse },
  { DateMenu },
  { DatePicker },
  { Drawer },
  { FilterList },
  { FloatingQuote },
  { ImageInput },
  { LineChart },
  { ReactiveLineChart },
  { MapController },
  { MapDrawer },
  { Modal },
  { NumberCounter },
  { PageSection },
  { PillAlert },
  { Radio },
  { SortableTable },
  { Spinner },
  { Stat },
  { Sticky },
  { Tooltip }
)

<template>
  <v-snackbar
    class="refresh-ui"
    v-model="showRefresh"
    top
    multi-line
    :timeout="-1"
    color="secondary">
    New content is available! Please refresh the app.
    <v-btn color="white" text @click="handleRefresh" :loading="refreshing">Refresh Now</v-btn>
  </v-snackbar>
</template>

<script>
  import { ServiceWorkerBus } from '@/buses'

  export default {
    name: 'refresh-ui',

    mounted() {
      ServiceWorkerBus.$on('show-refresh', registration => {
        // adding it to a non-observed instance property
        this.$registration = registration
        this.showRefresh = true
      })
    },

    data() {
      return {
        showRefresh: false,
        refreshing: false
      }
    },

    methods: {
      handleClose() {
        this.showRefresh = false
      },
      handleRefresh() {
        this.showRefresh = false

        if (this.$registration) {
          // Just to ensure registration.waiting is available before
          // calling postMessage()
          if (!this.$registration.waiting) return

          this.refreshing = true

          // see self.addEventListener('message') in src/service-worker.js
          this.$registration.waiting.postMessage('skipWaiting')
        }
      }
    }
  }
</script>

<style lang="sass">
  .refresh-ui
    .v-snack__content
      color: #fff
      font-weight: bold
</style>

<template>
  <!-- TODO: mobile layout wrong -->
  <footer class="footer__wrapper mt-3 pa-3">
    <v-container fluid tag="footer" class="footer">
      <v-row class="justify-space-between">
        <v-col cols="12" sm="4" class="mb-2">
          <v-row
            :class="{
              'justify-center': $vuetify.breakpoint.xsOnly
            }"
          >
            <a href="/" title="myRAINge Log Home Page" class="footer__mrl-logo">
              <img src="/img/mrl-logo.svg" alt="myRAINge Log Logo"/>
            </a>

            <div class="footer__mrl-info">
              <div><router-link to="/contact">Contact Us</router-link></div>
              <div><router-link to="//info.myraingelog.arizona.edu" target="_blank">Knowledge Base</router-link></div>
              <div>
                <router-link to="/logout" v-if="isAuthenticated">Log Out</router-link>
                <router-link to="/login" v-else>Log In</router-link>
              </div>
            </div>
          </v-row>
        </v-col>

        <v-col cols="12" sm="4" class="mb-2 text-center">
          <v-btn color="primary"
            href="http://eepurl.com/dn5ao1"
            target="_blank"
            rel="noopener"
            large
            depressed
            rounded>
            Sign up to stay informed
          </v-btn>
        </v-col>

        <v-col cols="12" sm="4" class="mb-2 text-center text-sm-right">
          <div class="footer__ua-logo">
            <a href="//www.arizona.edu/" title="University of Arizona Homepage"><img src="./ua-logo.svg" alt="The University of Arizona"/></a>
          </div>
        </v-col>

        <v-col cols="12" class="footer__credits">
          <p>Brought to you by <a href="//cct.cals.arizona.edu/">Cyber Communications and Technologies (CCT)</a><br>in the <a href="//cals.arizona.edu/">College of Agriculture and Life Sciences</a> at The <a href="//arizona.edu/">University of Arizona</a>.</p>
          <p class="footer__copyright">MyRAINge Log v{{ appVers }}</p>
          <p class="footer__copyright">Copyright {{ currentYear }} © Arizona Board of Regents</p>
        </v-col>
      </v-row>

      <!-- <div class="footer__inner">
        <div class="footer__flex">
          <div class="footer__contact">
            
          </div>

          
        </div>
      
        

      </div> -->
    </v-container>
  </footer>
</template>

<script>
  import { dateToYear } from '@/utils/dateUtils'
  import { mapGetters } from 'vuex'

  let Footer = {
    components: {},
    data() {
      return {
        currentYear: dateToYear()
      }
    },
    computed: {
      ...mapGetters(['isAuthenticated']),
			appVers () {
				return this.$root.$options.APP.VERSION
			},
    }
  }

  export default Footer
</script>

<style lang="sass" src="./Footer.sass"></style>
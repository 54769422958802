<template>
  <v-container
    id="helpers-add"
    fluid
    class="helpers-add pt-0"
  >
    <page-section
      header-image
      :header-image-icon="$vuetify.icons.values.mrl_add_user"
    >
      <h1>Add Helpers</h1>
      <p>Connect with other myRAINge Log members</p>
    </page-section>

    <page-section
      centered
      slim
      :overlay-loading="!gaugesReady"
    >
      <helpers-form
        v-if="gauges.length"
        add
        :gauges="gauges"
      />
      <div
        v-else
        class="pill--danger"
      >
        Uh oh. You need to have at least one gauge setup before assigning any Helpers. Sorry about that! Head back to your 
        <router-link :to="{ name: 'dashboard' }">Gauges Dashboard</router-link> to create your first gauge!
      </div>
    </page-section>
  </v-container>
</template>

<script>
import HelpersForm from '@/components/HelpersForm/HelpersForm'
import GaugesConnectorMixin from '@/mixins/connectors/gauges'
import DisplayManager from '@/mixins/DisplayManagerMixin'

const HelpersAdd = {
  components: {
    HelpersForm
  },

  mixins: [
    GaugesConnectorMixin,
    DisplayManager
  ]
}

export default HelpersAdd
</script>

import authRequest from './utils/authRequest'

const ENDPOINT = '/mail-chimp'

const mailChimpApi = {
  updateSubscribedUser({ emailAddress, listId, params = {} }) {
    if (!emailAddress) throw new Error('[updateSubscribedUser] emailAddress required to update subscribed user')

    return authRequest({ 
      url: `${ENDPOINT}/update-subscribed-user`,
      method: 'put',
      data: {
        emailAddress,
        listId,
        params
      }
    }).then((response) => {
      console.log('updateSubscribedUser: ', response)
    })
  }
}

export default mailChimpApi

import AwsConfig from "./lib/aws/config"
import offlineApi from './localstorage'
import authRequest from './utils/authRequest'

import { uploadImage } from './imageUploader'

/** Serverless API
 * endpoints:
  GET - https://1zafopg125.execute-api.us-west-2.amazonaws.com/dev/gauge
 */

export function mapGauge(item) {
  let image = (item.image && typeof item.image === "string") ? item.image : ""; // if there's a binary, just skip that map
  if (image && image.indexOf("user/") === 0) {
    image = AwsConfig.userImageBaseUrl + image
  } else if (image && image.indexOf("data:image/") === 0) {//base64
    console.info("should we have a base64 image? ...we do")
    // image = item.image //this is not needed... just for reference
  }

  return {
    ...item,
    uid: item.uid || item.userId,
    image
  }
}

export default {
  getGauges (options) {
    if (!options || !options.userId) throw new Error('missing param in getGauges api call')

    let { userId: uid } = options

    return authRequest({ url: `/gauges-all/${uid}` }).then(function (response) {
      console.log("[api] GET gauges resp:", response)

      let { data = [] } = response
      let items = data.map(mapGauge)

      //offline - save to localstorage
      offlineApi.gauges.set(items, false) //bool=doLogs

      return items
    })
  },

  addGauge (data) {
    if (!data || !data.userId) throw new Error('missing param in addLog api call')

    let uid = data.userId,
        imageFile = data.image
    let payload = mapGauge(data)

    
    console.log("[api] addGauge imageFile:", imageFile)

    if (typeof imageFile === "object" && imageFile.type && imageFile.size) {
      payload.imageType = imageFile.type
      payload.imageSize = imageFile.size
    }
    
    console.log("[api] addGauge", payload)

    let response = authRequest({ url: `/gauges/${uid}`, method: 'post', data: payload }).then(response => {
      console.log("[api] addGauge response",response);

      if (response.data.s3psk && typeof imageFile === "object" && imageFile.type && imageFile.size) {
        console.log("uploading the image...")
        return uploadImage({
          signed_request : response.data.s3psk,
          imageFile : imageFile,
          imageType : imageFile.type
        }).catch(err => {
          console.warn("Caught an error in uploadImage Promise")
        }).then(resp => {
          return response.data
        })
      } else {
        console.log("there's no image to add...")
        return response.data
      }            
    }).catch(error => {
      // console.warn(error);
      throw new Error(error)
    })

    return response.then(response => {
      let mappedResponse = mapGauge(response)
      offlineApi.gauges.add([mappedResponse], false)

      return mappedResponse
    })
  },

  updateGauge(data) {
    if (!data || !data.gid || !data.uid) throw new Error('missing param in updateGauge api call')

    // make actual api call
    let uid = data.uid,
        gid = data.gid,
        imageFile = data.newImage
    // delete data.cb

    let payload = mapGauge(data)

    if (typeof imageFile === "object" && imageFile.type && imageFile.size) {
      payload.imageType = imageFile.type
      payload.imageSize = imageFile.size
      console.log("[api] updateGauge: adding new image...")
    }

    console.log("[api] updateGauge payload: %o", payload)

    return authRequest({ url: `/gauges/${uid}/${gid}`, method: 'put', data: payload }).then(function (response) {
      console.log("[api] updateGauge response",response);
      if (response && response.status && response.status === 200) {
        //response will have ALL_NEW fields, not just the fields that changed

        if (response.data.s3psk && typeof imageFile === "object" && imageFile.type && imageFile.size) {
            console.log("uploading the image...")
            return uploadImage({
              signed_request : response.data.s3psk,
              imageFile : imageFile,
              imageType : imageFile.type

            }).catch(err => {
              console.warn("Caught an error in uploadImage Promise")
            }).then(resp => {
              // delete response.data.s3psk
              return mapGauge(response.data.Attributes || response.data)
            })
          } else {
            console.log("there's no image to add...")
            return mapGauge(response.data.Attributes || response.data)
          }

        // return response.data//mapGauge(response.data)
      } else {
        console.warn("[api] PUT gauge Error: no response or status!200")
        throw new Error(response);
      }
      
    }).catch(function (error) {
      console.warn(error);
      throw new Error(error)
    });
  },

  deleteGauge({ uid, gid }) {
    if (!gid || !uid) throw new Error('missing param in deleteGauge api call')
  
    return authRequest({ url: `/gauges/${uid}/${gid}`, method: 'delete' }).then(function (response) {
      console.log("[api] deleteGauge response",response)

      let { data } = response

      return data
    })
  },

}
// import APP from '../scripts/init';
import appConfig from '../config';

import Vue from 'vue'
import Vuex from 'vuex'

import chart from './modules/chart'
import dateRange from './modules/dateRange'
import helpers from './modules/helpers'
import gaugeLogs from './modules/gaugeLogs'
import gauges from './modules/gauges'
import gtmPlugin from './plugins/gtm'
import notifications from './modules/notifications'
import offline from './modules/offline'
import publicData from './modules/publicData'
import reports from './modules/reports'
import ui from './modules/ui'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appVers: appConfig.VERSION || 'devApp'
  },
  actions: {},
  modules: {
    chart,
    dateRange,
    helpers,
    gaugeLogs,
    gauges,
    notifications,
    offline,
    publicData,
    reports,
    ui,
    user
  },
  plugins: [gtmPlugin]
})

<template>
  <div class="stat">
    <div class="stat__stat" v-if="formatted" v-html="stat"></div>
    <div class="stat__stat" v-else><strong>{{ stat }}</strong></div>
    <div class="stat__label">{{ label }}</div>
  </div>
</template>

<script>
  export default {
    name: 'stat',

    props: {
      formatted: Boolean,
      stat: String,
      label: String
    }
  }
</script>

<style lang="sass" src="./Stat.sass"></style>
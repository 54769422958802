<template>
  <transition name="modal">
    <div :class="classObject" v-if="modalOpen === id">
      <div class="modal__wrapper" @click="maskClose" @keyup.esc="close">
        <div class="modal__container">

          <pill-alert type="danger" v-if="this.$slots['error']">
            <slot name="error"></slot>
          </pill-alert>
          
          <div class="modal__header" v-show="this.$slots['header']">
            <slot name="header"></slot>
          </div>

          <div class="modal__body" v-show="this.$slots['body']">
            <slot name="body"></slot>

            <div class="modal__actions" v-show="this.$slots['actions']">
              <slot name="actions"></slot>
              <v-btn text large @click.native="close" class="primary--text bare" v-if="!hideCancel">CANCEL</v-btn>
            </div>
          </div>

          <!-- <v-icon color="white" class="modal__close" size="25" @click.native="close" role="button">
            close
          </v-icon> -->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  let Modal = {
    props: {
      id: {
        type: String,
        required: true
      },
      hideCancel: Boolean,
      onClose: {
        type: Function,
        default: () => {}
      },
      disableClose: {
        type: Boolean,
        default: false
      },
      escape: {
        type: Boolean,
        default: true
      }
    },
    updated() {
      if (this.modalOpen === this.id) {
        window.addEventListener('keyup', (e) => {
          if (e.keyCode === 27) this.close()
        })
      } else {
        window.removeEventListener('keyup', () => {})
      }
    },
    methods: {
      close(e) {
        // TODO: disableClose doesnt work as expected
        !this.disableClose && this.$store.dispatch('modalClose').then(() => {
          this.onClose()
        })
      },
      maskClose(e) {
        if (e.target.className === 'modal__wrapper' && this.escape) this.close()
      }
    },
    computed: {
      modalOpen() { 
        return this.$store.state.ui.modalOpen
      },
      classObject() {
        return {
          'modal__mask': true
        }
      }
    }
  }

  export default Modal
</script>

<style lang="sass" src="./Modal.sass"></style>
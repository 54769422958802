<template>
  <v-container fluid grid-list-lg class="user_forgot_pass pt-0">
    <page-section header>
      <h1>Forgot Password</h1>
      <p>Click the button to send a link to your email.</p>
    </page-section>

    <page-section centered>
      <div class="form__wrapper">
        <transition name="fade" mode="out-in">
          <pill-alert type="danger" v-if="error">{{ error }}</pill-alert>
        </transition>

        <form @submit.prevent="formSubmit(handleSubmit)" novalidate>
          <v-text-field
            v-model="email"
            v-validate="'required|email'"
            label="Email"
            required
            outlined
            single-line
            aria-required="true"
            data-vv-name="email"
            data-vv-as="email"
            name="email"
            :error-messages="errors.collect('email')">
          </v-text-field>

          <div class="form__actions">
            <v-btn type="submit" 
              color="accent"
              depressed
              rounded
              large
              class="form__submit"
              :loading="loading">
              Send Reset link to my Email
            </v-btn>
            <router-link to="/signup">Whoa! I don’t have an account.</router-link>
          </div>
        </form>
      </div>
    </page-section>
  </v-container>
</template>
        

<script>
import FormValidation from '@/mixins/FormValidation'
import { mapActions } from 'vuex'

export default {
  name: 'user_forgot_pass',

  components: {},

  data () {
    return {
      error : '',
      wasSuccess : false,
      email: ''
    }
  },

  mixins: [FormValidation],

  methods: {
    ...mapActions(['forgotPassword']),
    async handleSubmit () {
      try {
        await this.forgotPassword(this.email)

        this.$router.push({ 
          name: 'changePassword', 
          params: { success: 'Check your email for the verification code.' },
          query: { email: this.email }
        })
      } catch ({ message }) {
        this.loading = false
        this.error = message
      }
    }
  }
}
</script>

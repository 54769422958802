<template>
  <v-container fluid grid-list-lg id="gauge_detail" :class="getClasses" class="pt-0">
    <!-- TODO: this probably need to be an official snackbar or toast alert -->
    <div class="top-success-message" v-if="$route.params.success">
      {{ $route.params.success }}
    </div>

    <div class="top-success-message" v-if="$route.params.error">
      {{ $route.params.error }}
    </div>

    <page-section
      header-image
      class="gauge-detail__header mb-3"
    >
      <gauge-list-image :src="gauge.image"/>

      <h1>{{ gauge.title }}</h1>

      <v-menu
        class="gauge-detail__header__settings"
        bottom
        offset-y
      >
        <template v-slot:activator="{ on }">
          <div v-on="on" class="d-flex align-items gauge-detail__header__settings__activator">
            <span class="hidden-xs-only">Settings</span>
            <v-icon class="ml-2">settings</v-icon>
          </div>
        </template>

        <v-list dark class="accent white--text gauge-detail__header__settings-menu">
          <v-list-item :to="{ name: 'editGauge'}" v-if="editor && !gauge.archived">
            <v-list-item-title>Edit</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="gaugeLogs.length && contributor && !gauge.archived" :to="{ name: 'gaugeEmpty'}">
            <v-list-item-title>Reset</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="owner && !gauge.archived" :to="{ name: 'helpersAdd' }">
            <v-list-item-title>Add Helper</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'export', query: { gauge: gauge.gid } }">
            <v-list-item-title>Export</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="owner && !gauge.archived" @click="archiveGauge">
            <v-list-item-title>Archive</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="owner && gauge.archived" @click="handleUnarchive">
            <v-list-item-title>Unarchive</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="owner" @click.native="deleteGauge">
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <p class="gauge-detail__description mb-4" v-if="gauge.description">{{ gauge.description}}</p>

      <div class="gauge-detail__summary white--text pb-3">
        <p><strong>SUMMARY BY DATE RANGE:</strong></p>
        <div class="d-flex justify-center">
          <p class="mx-2">Cumulative: <strong>{{ getTotalCumulationWithModifier({ gid: gauge.gid }) }}"</strong></p>
          <p class="mx-2">Observation Count: <strong>{{ logsByDateRange.length }}</strong></p>
        </div>
      </div>

      <v-btn
        v-if="previousGauge"
        class="gauge-detail__previous"
        :to="{name: 'gaugeDetail', params: {gid: previousGauge, transition: 'slide-right'}}"
        icon>
        <v-icon color="primary" size="35">keyboard_arrow_left</v-icon>
      </v-btn>

      <v-btn
        v-if="nextGauge"
        class="gauge-detail__next"
        :to="{name: 'gaugeDetail', params: {gid: nextGauge, transition: 'slide-left'}}"
        icon>
        <v-icon color="primary" size="35">keyboard_arrow_right</v-icon>
      </v-btn>
    </page-section>

    <page-section centered class="gauge-detail__not-owner mb-3" v-if="!owner">
      You are <strong>{{ getRoleWithArticle }}</strong> of this gauge. <a :href="`mailto:${gauge.sharedByEmail}`">Contact the owner</a>.
    </page-section>

    <page-section centered class="gauge-detail__not-owner mb-3" v-if="gauge.archived">
      This gauge was <strong>archived</strong> on {{ archivedDate }}.
    </page-section>
    
    <v-row class="fill-height mb-1">
      <v-col
        :class="{
          'pr-0': $vuetify.breakpoint.mdAndUp
        }"
        cols="12"
        md="4"
      >
        <!-- LOGS -->
        <page-section class="gauge-detail__log">
          <span class="gauge-detail__log__header">
            <h2>Log</h2>

             <v-btn
              :to="{ name: 'addLog'}"
              color="accent"
              depressed
              rounded
              class="gauge-list-item__add-icon mr-0">
              Add Observation
              <v-icon right size="20" class="v-icon--with-circle">add</v-icon>
            </v-btn>
          </span>

          <div v-if="!gaugeLogs.length && !isLoadingLogs">
            <p>Congrats on setting up a new rain gauge! Time to get started. Once you add observations, they will begin to appear here.</p>
          </div>

          <notifications-alert
            v-if="showNotification"
            dismissible
          >
            <span>
              {{ notificationCumulativeRain }} of precipitation has been estimated since {{ notificationCumulativeFrom }}.
            </span>
            <br><br>
            <router-link
              :to="{
                name: 'reportsBuilder',
                query: {
                  gids: gid,
                  prebuilt: true
                }
              }"
            >
              <strong>View Report for Details</strong>
            </router-link>
          </notifications-alert>

          <div class="gauge-detail__log-list">
            <spinner v-if="isLoadingLogs" :size="50" color="accent"/>
            <log-list
              v-else
              :logs="gaugeLogs"
              :gauge="gauge"
            />
          </div>
        </page-section>
      </v-col>

      <v-col class="flex-grow-1" cols="12" md="8">
        <!-- CHART -->
        <page-section class="gauge-detail__chart">
          <header class="gauge-detail__chart-header">
            <h2>Rainfall Chart</h2>
            <v-row>
              <v-col cols="12" sm="8" class="mt-0">
                View, print and share a summary for your rain gauge for a given time period.
              </v-col>
              <v-col cols="12" sm="4" class="text-sm-right">
                <gauge-report-loader
                  v-if="gauge.gid"
                  :gid="gauge.gid"
                />
              </v-col>
            </v-row>
          </header>

          <div class="gauge-detail__chart-chart">
            <reactive-line-chart v-bind="chartProps" />
            <div class="d-flex justify-end">
              <v-btn
                text
                small
                color="primary"
                @click.native="$dialogBus.$emit(
                  'open-dialog',
                  {
                    type: 'ChartHelperDialog',
                    props: {}
                  }
                )"
              >
                Explain this chart
              </v-btn>
            </div>
            <div class="gauge-detail__chart-empty-state" v-if="modifiedLogData.length < 2">
              <p>We need more data to generate a chart! Add more logs or check to make sure your date range includes previously entered observations.</p>
              <v-btn
                depressed
                rounded
                color="accent"
                :to="{ name: 'addLog'}">
                Add observation
                <v-icon right size="20" class="v-icon--with-circle">add</v-icon>
              </v-btn>
            </div>
          </div>
        </page-section>

        <!-- MAP -->
        <page-section class="gauge-detail__map">
          <h2>Map</h2>
          <gauge-detail-map :activeGauge="gauge" v-lock-on-scroll/>
        </page-section>
      </v-col>
    </v-row>

    <page-section centered>
      <h2>Preferences</h2>
      <v-row class="mx-0">
        <v-col
          cols="12"
          sm="12"
          md="8"
          class="gauge-detail__preferences__left pa-4 mb-3"
        >
          <gauge-preferences
            v-if="gaugesReady && !notificationsLoading"
            v-model="preferences"
            :gauge="gauge"
            :notification-settings="notificationSettings"
            :disabled="!editor"
          />

          <div class="gauge-detail__preferences__actions">
            <transition name="fade" mode="out-in">
              <pill-alert type="danger" v-if="preferencesError">{{ preferencesError }}</pill-alert>
            </transition>
            <v-btn
              class="mt-6"
              depressed
              rounded
              color="accent"
              :loading="preferencesLoading"
              @click="handlePreferencesSave"
            >
              <template v-if="preferencesShowCheck">
                <v-icon color="white">check</v-icon>
              </template>
              <template v-else>
                Save
              </template>
            </v-btn>
          </div>
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="4"
          class="gauge-detail__preferences__right preferences-box pa-4 mb-3"
        >
          <h3>Helpers</h3>

          <p>Invite folks to help you manage each of your gauges as an Editor or Contributor.</p>

          <spinner v-if="loadingHelpers" :size="50"/>

          <div class="gauge-detail__helpers" v-else-if="getHelpersByGid(gauge.gid).length">
            <div
              v-for="helper in getHelpersByGid(gauge.gid)"
              :key="helper.sharedWithUid"
              class="gauge-detail__helpers-item"
            >
              <div class="gauge-detail__helpers-item-name">
                {{ helper.sharedWithName }}, <span>{{ helper.shareActive ? firstCharUpper(helper.shareLevel) : 'Inactive' }}</span>
              </div>
            </div>
          </div>

          <div class="gauge-detail__preferences__actions">
            <v-btn 
              text
              v-if="getHelpersByGid(gauge.gid).length && owner"
              :to="{ name: 'helpers', query: { filter: gauge.title } }"
              class="bare primary--text">
              Edit
            </v-btn>
            <v-btn
              depressed
              rounded
              color="accent"
              :disabled="!owner"
              :to="{ name: 'helpersAdd' }">
              Add Helper
              <v-icon right color="white" size="25">
                {{ $vuetify.icons.values.mrl_login }}
              </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </page-section>
  </v-container>
</template>

<script>
import GaugeDetailMap from '@/components/GaugeDetailMap/GaugeDetailMap'
import LogList from '@/components/LogList/LogList'
import GaugeListImage from '@/components/GaugeListImage/GaugeListImage'
import { mapGetters, mapActions } from 'vuex'
import { firstCharUpper } from '@/utils/generalUtils'
import {
  dateToDayShort,
  dateToDay,
  subtractDays
} from '@/utils/dateUtils'
import {
  defaultLineOptions,
  observationDatasetObject
} from '@/utils/chartUtils'
import {
  NETWORK_ERROR_SMALL,
  DEFAULT_ADDITIONAL_PUBLIC_PROPS
} from '@/utils/constants'
import GaugePreferences from '@/components/GaugePreferences/GaugePreferences'
import GaugeReportLoader from '@/components/GaugeReportLoader/GaugeReportLoader'
import NotificationsAlert from '@/components/NotificationsAlert/NotificationsAlert'
import DisplayManagerMixin from '@/mixins/DisplayManagerMixin'
import NotificationsConnectorMixin from '@/mixins/connectors/notifications'
import GaugesConnectorMixin from '@/mixins/connectors/gauges'
import GaugePermissions from '@/views/Gauges/mixins/GaugePermissions'
import { decimalDoublePad } from '@/utils/numUtils'
import { pick } from 'lodash'

export default {
  name: 'GaugeDetail',

  components: {
    LogList,
    GaugeListImage,
    GaugeDetailMap,
    GaugeReportLoader,
    GaugePreferences,
    NotificationsAlert
  },

  mixins: [
    GaugePermissions,
    DisplayManagerMixin,
    NotificationsConnectorMixin,
    GaugesConnectorMixin
  ],

  data () {
    return {
      unarchiveLoading: false,
      innerLoading: false,
      firstCharUpper,
      preferences: {},
      preferencesShowCheck: false,
      preferencesError: '',
      preferencesLoading: false,
      showNotification: false,
      notificationCopy: null
    }
  },

  computed: {
    ...mapGetters([
      'from',
      'to',
      'getLogListByDateRange',
      'cumulateLogs',
      'cumulateLogsWithModifier',
      'getLogDataAsXY',
      'getLogList',
      'getLogDatesAsArray',
      'getTotalCumulationWithModifier',
      'getTotalCumulation',
      'getHelpersByGid'
    ]),
    notificationCumulativeRain () {
      return `${decimalDoublePad(
        this.notificationCopy.cumulativeRain
      )}"`
    },
    notificationCumulativeFrom () {
      // TODO: need to "minus" one day to keep in line with email
      // Check with JD to conslidate this type of logic
      return dateToDay(
        subtractDays(this.notificationCopy.startCumulatingFrom, 1)
      )
    },
    isLoadingLogs () {
      return this.allLogsLoading || this.innerLoading
    },
    archivedDate () {
      const { archived } = this.gauge
      return archived ? dateToDayShort(archived) : ''
    },
    getClasses () {
      return {
        'gauge-detail': true,
        'gauge-detail--helper': !this.owner
      }
    },
    gaugeLogs () {
      return this.getLogList(this.gauge.gid)
    },
    logsByDateRange () {
      return this.getLogListByDateRange(this.gauge.gid)
    },
    modifiedLogData () {
      // return this.cumulateLogs({ logs: this.logsByDateRange })
      return this.cumulateLogsWithModifier({ gid: this.gauge.gid })
    },
    logDatesArray () {
      return this.getLogDatesAsArray(this.logsByDateRange)
    },
    previousGauge () {
      return this.$store.getters.getPrevNextGaugeGid(this.gauge.gid, 'previous')
    },
    nextGauge () {
      return this.$store.getters.getPrevNextGaugeGid(this.gauge.gid, 'next')
    },
    chartProps () {
      return {
        chartData: {
          labels: this.logDatesArray,
          datasets: this.generateDatasets
        },
        options: defaultLineOptions()
      }
    },
    gaugePreferences () {
      return {}
    },
    generateDatasets () {
      // creates dataset object for chartjs
      // see http://www.chartjs.org/docs/#line-chart-dataset-structure (or other chart type dataset)
      const xyData = this.getLogDataAsXY(this.modifiedLogData)

      return [
        observationDatasetObject({
          data: xyData
        })
      ]
    }
  },

  mounted () {
    // only fetch gauge logs if this is archived
    // all logs (of non archived gauges) are fetched on mount of dashboard container
    this.gauge.archived && this.getGaugeLogs(this.gauge.gid)
      .then(() => { this.innerLoading = false })
      .catch(() => { this.innerLoading = false })

    if (this.getPendingNotificationByGid(this.gid)) {
      this.markNotificationAsRead(this.gid)
      this.notificationCopy = {
        ...this.getPendingNotificationByGid(this.gid)
      }
      this.showNotification = true
    }
  },

  methods: {
    ...mapActions([
      'updateGauge',
      'getGaugeLogs',
      'unarchiveGauge'
    ]),
    deleteGauge () {
      this.$dialogBus.$emit('open-dialog', {
        type: 'GaugeDeleteDialog',
        props: {
          gauge: this.gauge
        }
      })
    },
    archiveGauge () {
      this.$dialogBus.$emit('open-dialog', {
        type: 'GaugeArchiveDialog',
        props: {
          gauge: this.gauge
        }
      })
    },
    async handleUnarchive () {
      this.unarchiveLoading = true

      await this.unarchiveGauge(this.gauge.gid)
      this.$router.replace({ name: 'dashboard', params: { success: 'Gauge was successfully unarchived!' } })
    },
    async handlePreferencesSave () {
      try {
        // TODO: updateGauge must have certain fields in order to not break
        // maybe the updateGauge action should merge in require properties so
        // no other call needs to be concerned with it
        this.preferencesLoading = true
        const {
          uid,
          gid,
          image
        } = this.gauge
        const gaugeUpdates = pick(this.preferences, ['isPublic', 'publicProps', 'units'])
        const notificationUpdate = pick(this.preferences, ['sendEmail', 'threshold'])

        if (this.editor) {
          await this.updateGauge({
            uid,
            gid,
            image,
            ...gaugeUpdates,
            publicProps: gaugeUpdates.publicProps && gaugeUpdates.isPublic
              ? DEFAULT_ADDITIONAL_PUBLIC_PROPS
              : false
          })
        }

        await this.updateNotification({
          gid,
          ...notificationUpdate
        })

        this.preferencesShowCheck = true
        setTimeout(() => {
          this.preferencesShowCheck = false
        }, 5000)
        this.preferencesError = ''
      } catch (e) {
        console.error(e)
        this.preferencesShowCheck = false
        this.preferencesError = NETWORK_ERROR_SMALL
      } finally {
        this.preferencesLoading = false
      }
    }
  }
}
</script>


<style lang="sass" src="./GaugeDetail.sass"></style>

import * as Leaflet from 'leaflet'

export function commonTileLayer() {
  return {
    id: 'street',
    label: 'Street',
    urlTemplate: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}?access_token={accessToken}',
    options: {
      attribution: 'Tiles &copy; Esri',
      maxZoom: 18,
      accessToken: 'pk.eyJ1IjoibWF0dC1lLWtpbmciLCJhIjoiY2l6eWtwaGhxMDA2MTJxbXlvY2RuM2h5byJ9.50i1OwaHIrEI9nlAzl-dIQ',
      label: 'Street'
    }
  }
}

export function satelliteTileLayer() {
  return {
    id: 'satellite',
    label: 'Satellite',
    urlTemplate: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    options: {
      attribution: 'Tiles &copy; Esri &mdash',
      maxZoom: 18,
      label: 'Satellite'
    }
  }
}

// shadows removed for the sake of switching over to "panes"
export function myGaugeIcon() {
  return Leaflet.icon(Object.assign({}, {
    iconUrl: require('@/assets/images/gauge-location.png'),
    // shadowUrl: require('@/assets/images/gauge-location-shadow.png'),
    iconSize: [26, 44],
    // shadowSize: [31, 15],
    iconAnchor: [13, 43],
    // shadowAnchor: [1, 14],
    popupAnchor: [0, -35]
  }))
}

export function publicGaugeIcon() {
  return Leaflet.icon(Object.assign({}, {
    iconUrl: require('@/assets/images/gauge-grey-location.png'),
    // shadowUrl: require('@/assets/images/gauge-location-shadow.png'),
    iconSize: [26, 44],
    // shadowSize: [31, 15],
    iconAnchor: [13, 43],
    // shadowAnchor: [1, 14],
    popupAnchor: [0, -35]
  }))
}

export function layerTypeLookup(type) {
  let layerTypes = {
    marker: 'l-marker'
  }

  if (!layerTypes[type]) throw new Error('[layerTypeLookup] layer type does not exist')

  return layerTypes[type]
}

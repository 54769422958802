/**
* Charts API
* using axios https://github.com/mzabriskie/axios
*/
import AwsConfig from "./lib/aws/config"
import axios from 'axios'
import authRequest, { instance } from './utils/authRequest'
import LocalStorage from './localstorage'
import { convertLngLatToId, getHistoricalUrl } from '@/utils/awsUtils'
import {
  processHistoricalData,
  historicStart, 
  historicEnd,
  massageTheoreticalData,
  massageHistoricalData } from './utils/chartMath'


const chartRequest = axios.create({
  baseURL: "https://data.rcc-acis.org",
  timeout: 1000 * 60 * 5, // 5 min
  headers: {
    'Content-Type' : 'application/json', 
    'Accept' : 'application/json'
  },
});

const noAuthRequest = instance()

export default {
  getHistoricalDataFromS3(lngLat) {
    delete axios.defaults.headers.common['Authorization']
    return axios.get(getHistoricalUrl(lngLat)).then((response) => {
      let { data } = response

      return data
    })
  },

  getAcisGridData({loc, sdate, edate, grid = '21', elems}) {
    if (!loc || !sdate || !edate || !elems) throw new Error('Missing correct params in getMonthlyData api call')

    return chartRequest.post('/GridData', { loc, sdate, edate, grid, elems }).then((response) => {
      let { data = [] } = response.data

      return data
    })

    // return axios.get('https://httpbin.org/status/504').then(reponse => response)
  },

  // TODO: add timers or check chrome profiling
  async getHistoricalData({loc, sdate, edate}) {
    if (!loc || !sdate || !edate) throw new Error('Missing correct params in getHistoricalData api call')

    const massageRawResponse = (response) => {
      let [rawDaily, rawMonthly] = response

      return massageHistoricalData(
        processHistoricalData({ 
          startDate: sdate,
          endDate: edate,
          rawDaily,
          rawMonthly 
        })
      )
    }

    try {
      let data = massageRawResponse(await this.getHistoricalDataFromS3(loc))

      return Promise.resolve(data)
    } catch(e) {
      // calls getAcisGridData with daily params and monthly params, when both are returned, process data
      return Promise.all([
        this.getAcisGridData({ loc, sdate: historicStart, edate: historicEnd, elems: 'pcpn' }), // daily data
        this.getAcisGridData({ loc, sdate: historicStart.substring(0,7), edate: historicEnd.substring(0,7), elems: 'mly_pcpn' }) // monthly data
      ]).then((response) => {
        console.log("[API] GET [daily, monthy]: ", response)

        return this.postHistoricalData({
          lngLat: loc,
          data: response
        }).then(() => {
          return massageRawResponse(response)
        })
      })
    }
  },

  getTheoreticalData({loc, sdate, edate}) {
    if (!loc || !sdate || !edate) throw new Error('Missing correct params in getTheoreticalData api call')
    
    // get theoretical data and then passes it to massage util function
    return this.getAcisGridData({ loc, sdate, edate, elems: 'pcpn' }).then((response) => {
      return massageTheoreticalData(response)
    })
  },

  postHistoricalData({lngLat = '', data}) {
    if (!lngLat || !data) throw new Error('Missing correct params in postHistoricalData api call')

    return noAuthRequest({
      url: `historical-data/${convertLngLatToId(lngLat)}`,
      method: 'post',
      data: JSON.stringify(data)
    })
  }
}

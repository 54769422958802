<template>
  <div class="sticky">
    <slot></slot>
  </div>
</template>

<script>
  let Sticky = {
    components: {},
    props: {
      disabled: Boolean
    },
    data() {
      return {
        ticking: false,
        latestKnownScrollY: 0,
        topClassName: 'started-from-the-bottom-now-were-here'
      }
    },
    mounted() {
      window.addEventListener('scroll', this.onScroll.bind(this))
    },
    destroyed(){
      window.removeEventListener('scroll', this.onScroll.bind(this))
    },
    methods: {
      onScroll() {
        this.latestKnownScrollY = window.scrollY
        this.requestTick()
      },
      requestTick() {
        if (!this.ticking) {
          window.requestAnimationFrame(this.handleUpdate.bind(this))
        }

        this.ticking = true
      },
      handleUpdate() {
        this.ticking = false
        
        if (this.disabled) {
          this.$slots.default[0].elm.classList.remove(this.topClassName)
          return
        }

        this.$el.style.height = this.$el.style.height || `${this.$slots.default[0].elm.offsetHeight}px`

        let parentTop = this.$el.getBoundingClientRect().top

        if (parentTop <= 0) {
          this.$slots.default[0].elm.classList.add(this.topClassName)
        } else if (parentTop > 0) {
          this.$slots.default[0].elm.classList.remove(this.topClassName)
        }
      }
    }
  }

  export default Sticky
</script>

<style lang="sass">
  .sticky
    position: relative
</style>

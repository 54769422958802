<template>
  <v-container fluid grid-list-lg id="gauge_edit" class="gauge-edit pt-0">
    <form @submit.prevent="formSubmit(editGauge)" class="form" novalidate>
      <page-section headerImage class="gauge-edit__header">
        <image-input name="editImage"
          no-remove
          :src="form.image"
          v-on:src-change="(value) => {form.image = value}"
          v-model="form.newImage">
          <template slot="button">
            <v-btn outlined small rounded text color="accent" @click.prevent class="ma-0">
              <v-icon size="32">photo_camera</v-icon>
              Add Photo 
            </v-btn>
          </template>
          <template #preview="{ src }">
            <div class="gauge-edit__preview-img"
              :style="{
                backgroundImage: `url(${src})`
              }">
            </div>
          </template>
        </image-input>

        <div class="form__wrapper">
          <v-text-field
            class="page-section__large-input"
            v-model="form.title"
            v-validate="'required'"
            placeholder="Gauge Name"
            required
            outlined
            single-line
            aria-required="true"
            data-vv-name="title"
            name="title"
            :error-messages="errors.collect('title')"
            aria-autocomplete="off"
            autocomplete="off"
          />
        </div>
      </page-section>

      <page-section>
        <div class="form__wrapper">
          <div class="form__errors" v-if="error">
            {{ error }}
          </div>

          <v-row :class="getLocationClasses">
            <div class="gauge-edit__location-archive" v-if="disableLocationChange">
              <div class="text-center" v-if="owner">
                <p>Dude! You've already started collecting data. If you move your gauge, you're contributing to bad data.</p>

                <p>If you want to change the location of this gauge, we highly recommend archiving it and making a new gauge.</p>

                <v-btn
                  @click="archiveGauge"
                  rounded
                  depressed
                  color="accent">
                  Okay, let's archive
                </v-btn>
              </div>

              <div class="text-center" v-else>
                <p>You do not own this gauge, thus you don’t have the permissions to move its location.</p>
                <p>If you want to move this gauge, please contact the gauge owner.</p>
              </div>
            </div>
            <v-col cols="12" :sm="disableLocationChange ? '12' : '6'">
              <gauge-detail-map :activeGauge="gauge" v-lock-on-scroll v-if="disableLocationChange"/>
              <gauge-add-map v-model="mapLatLng" v-else v-lock-on-scroll/>
            </v-col>

            <v-col sm="6" v-if="!disableLocationChange">
              <h3 class="mt-3">Location</h3>
              <p class="mb-3">Allow the system to detect your location or double click and drag the marker to set the latitude and longitude values.</p>

              <v-text-field
                v-model="form.latitude"
                v-validate="'required'"
                label="Latitude"
                required
                outlined
                single-line
                hide-details
                aria-required="true"
                data-vv-name="latitude"
                name="latitude"
                class="mb-3"
                :error-messages="errors.collect('latitude')"
                aria-autocomplete="off"
                autocomplete="off"
              />

              <v-text-field
                v-model="form.longitude"
                v-validate="'required'"
                label="Longitude"
                required
                outlined
                single-line
                hide-details
                aria-required="true"
                data-vv-name="longitude"
                name="longitude"
                class="mb-3"
                :error-messages="errors.collect('longitude')"
                aria-autocomplete="off"
                autocomplete="off"
              />

              <a href="http://www.rcn.montana.edu/resources/converter.aspx" target="_blank" rel="noopener">Convert your geographic units</a>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <p id="description_label">
                <strong>Describe the gauge type and location</strong>
              </p>
              <v-textarea
                v-model="form.description"
                name="description"
                aria-labelledby="description_label"
                outlined
                single-line
                placeholder="Describe the gauge type and location. For example “Clear PVC gauge with cap mounted on T-post. Located near water trough on NE side, 30 ft from any trees."
                rows="4">
              </v-textarea>
            </v-col>
          </v-row>
        </div>

        <h2>Preferences</h2>

        <div class="form__wrapper gauge-edit__preferences-wrapper">
          <gauge-preferences
            v-if="gaugesReady && !notificationsLoading"
            v-model="preferences"
            :gauge="gauge"
            :notification-settings="notificationSettings"
          />
        </div>

        <template slot="actions">
          <div class="d-flex justify-center align-center">
            <v-btn 
              v-if="owner"
              class="super-bare primary--text"
              text
              large
              @click="deleteGauge">Delete</v-btn>
            <v-btn 
              type="submit"
              :loading="loading"
              depressed
              rounded
              color="accent"
              large
              class="mx-4">Update Gauge</v-btn>
            <v-btn
              v-if="owner"
              class="super-bare primary--text"
              text
              large
              exact
              @click="archiveGauge">Archive</v-btn>
          </div>
          <v-btn text large exact :to="{ name: 'gaugeDetail' }" class="mt-2 primary--text">Cancel</v-btn>
        </template>
      </page-section>
    </form>
  </v-container>
</template>

<script>
import {
  NETWORK_ERROR,
  DEFAULT_ADDITIONAL_PUBLIC_PROPS
} from '@/utils/constants'
import { mapActions, mapGetters } from 'vuex'
import FormValidation from '@/mixins/FormValidation'
import UnitsPreference from '@/components/UnitsPreference/UnitsPreference'
import GaugePreferences from '@/components/GaugePreferences/GaugePreferences'
import RccDataMixin from '@/mixins/RccDataMixin'
import GaugeFormMixin from '@/mixins/GaugeFormMixin'
import GaugeListImage from '@/components/GaugeListImage/GaugeListImage'
import GaugeDetailMap from '@/components/GaugeDetailMap/GaugeDetailMap'
import GaugeAddMap from '@/components/GaugeAddMap/GaugeAddMap'
import GaugeEditor from '../mixins/GaugeEditor'
import DisplayManagerMixin from '@/mixins/DisplayManagerMixin'
import NotificationsConnectorMixin from '@/mixins/connectors/notifications'
import GaugesConnectorMixin from '@/mixins/connectors/gauges'
import GaugePermissions from '@/views/Gauges/mixins/GaugePermissions'
import { pick } from 'lodash'

import scroll from 'zenscroll'

const GaugeEdit = {
  name: 'gauge-edit',

  components: {
    GaugeListImage,
    GaugeAddMap,
    GaugeDetailMap,
    UnitsPreference,
    GaugePreferences
  },

  mixins: [
    GaugeEditor,
    FormValidation,
    RccDataMixin,
    GaugeFormMixin,
    DisplayManagerMixin,
    NotificationsConnectorMixin,
    GaugesConnectorMixin,
    GaugePermissions
  ],

  data () {
    return {
      error: '',
      preferences: {}
    }
  },

  computed: {
    getLocationClasses() {
      return {
        'mb-2': true,
        'gauge-edit__location': true,
        'gauge-edit__location--archive': this.getLogCount(this.gauge.gid) > 1
      }
    },
    locationChanged() {
      const activeGauge = this.gauge

      return (this.form.latitude !== activeGauge.latitude || this.form.longitude !== activeGauge.longitude)
    },
    disableLocationChange() {
      return this.getLogCount(this.gauge.gid) > 1
    },
    mapLatLng: {
      // validation for this latLng array happend in GaugeAddMap component
      get() {
        return [this.form.latitude, this.form.longitude]
      },
      set(value) {
        this.form.latitude = value[0]
        this.form.longitude = value[1]
      }
    },
    ...mapGetters([
      'getLogCount'
    ])
  },

  methods: {
    ...mapActions([
      'updateGauge'
    ]),
    archiveGauge() {
      this.$dialogBus.$emit('open-dialog', {
        type: 'GaugeArchiveDialog',
        props: {
          gauge: this.gauge
        }
      })
    },
    deleteGauge() {
      this.$dialogBus.$emit('open-dialog', {
        type: 'GaugeDeleteDialog',
        props: {
          gauge: this.gauge
        }
      })
    },
    updateLatLng ({ layer }) {
      let {lat, lng} = layer._latlng
      this.form.latitude = lat
      this.form.longitude = lng
    },
    async editGauge () {
      try {
        const gaugePreferences = pick(this.preferences, ['isPublic', 'publicProps', 'units'])
        const notificationPrefereneces = pick(this.preferences, ['sendEmail', 'threshold'])

        await this.updateGauge({
          ...this.form,
          ...gaugePreferences,
          publicProps: gaugePreferences.publicProps && gaugePreferences.isPublic
            ? DEFAULT_ADDITIONAL_PUBLIC_PROPS
            : false
        })

        try {
          await this.updateNotification({
            gid: this.gauge.gid,
            ...notificationPrefereneces
          })
        } catch (e) {
          // handle notification error separately
        }

        // on success set a param in order to show success message on GaugeDetail page
        setTimeout(() => { this.loading = false }, 600)
        this.$router.push({ name: 'gaugeDetail', params: { success: 'Gauge was successfully updated!' }})
      } catch (e) {
        this.error = NETWORK_ERROR
        this.loading = false
        scroll.to(this.$el)
      }
    }
  }
}

export default GaugeEdit
</script>

<style lang="sass" src="./GaugeEdit.sass"></style>

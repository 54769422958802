import Vue from 'vue'

const mutationLookup = {
  USER_LOGIN(store) {
    let {
      user: {
        user: {
          attributes: {
            email
          } = {}
        } = {}
      } = {}
    } = store

    return { email }
  },
  USER_LOGOUT(store) { 
    let {
      user: {
        user: {
          attributes: {
            email
          } = {}
        } = {}
      } = {}
    } = store

    return { email }
  }
}

export default function gtmPlugin(store) {
  store.subscribe((m, s) => {
    try {
      let mutation = m.type.split('/')[0]
      let payload = mutationLookup[mutation] && mutationLookup[mutation](s)

      if (payload) {
        Vue.gtm.trackEvent({
          event: 'mutation',
          noninteraction: mutation,
          ...payload
        })
      }
    } catch(e) {
      throw new Error(`Error with gtm.trackEvent: ${e}`)
    }
  })
}

import AwsConfig from "@/api/lib/aws/config"
import axios from 'axios'
import store from '@/store'


//https://github.com/mzabriskie/axios
export var instance = () => {
  return axios.create({
    baseURL: AwsConfig.ApiGatewayBaseURL,
    timeout: 10000,
    headers: {},
    method: 'get'
  })
}

export default function authRequest(config) {
  if (!config && !config.url) throw new Error('[authRequest] Must provide request config and url.')

  let request = instance()

  request.defaults.headers.common['Authorization'] = store.getters.getToken
  
  if (config && config.contentType) {
    request.defaults.headers.common['content-type'] = config.contentType //this doesn't work 
  }

  // this is the actual axios request being made
  return request(config)
    .then(response => {
      let { 
        data: {
          errorMessage,
        } = {}
      } = response

      if (errorMessage) throw new Error(errorMessage)

      return response
    }).catch((error) => {
      throw Object.assign(new Error(error.message), { ...error })
    })

  // let request = instance()

  // return Auth.getToken().then((token) => {
  //   request.defaults.headers.common['Authorization'] = token;
  //   if (config && config.contentType) {
  //     request.defaults.headers.common['content-type'] = config.contentType; //this doesn't work 
  //   }

  //   // this is the actual axios request being made
  //   return request(config)
  // }).then(response => {
  //   let { 
  //     data: {
  //       errorMessage,
  //     } = {}
  //   } = response

  //   if (errorMessage) throw new Error(errorMessage)

  //   return response
  // }).catch((error) => {
  //   throw Object.assign(new Error(error.message), { ...error })
  // })
}

<template>
  <page-section
    centered
    slim
    class="rain-notifications-settings"
    :loading="!gaugesReady && notificationsLoading"
    :overlay-loading="!gaugesReady && notificationsLoading"
  >
    <h2>Precipitation Notifications</h2>

    <p>Receive email notifications when estimated precipitation (utilizing PRISM) surpasses threshold.</p>

    <div
      v-if="!notifications.length"
      class="form__errors"
    >
      Precipitation notifications have not been setup for this account or you haven't setup your first gauge. Please <router-link :to="{ name: 'contact' }">contact support</router-link> if you have any questions.
    </div>

    <rain-notification-settings-form
      v-else
      :notifications="notifications"
      :gauges="gauges"
      :shared-gauges="sharedGauges"
      :on-submit="handleSubmit"
    />

    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="error"
        class="form__errors"
      >
        {{ error }}
      </div>
      <div
        v-if="success"
        class="form__success"
      >
        {{ success }}
      </div>
    </transition>
  </page-section>
</template>

<script>
import RainNotificationSettingsForm from '@/components/RainNotificationSettingsForm/RainNotificationSettingsForm'
import GaugesConnectorMixin from '@/mixins/connectors/gauges'
import NotificationsConnectorMixin from '@/mixins/connectors/notifications'

export default {
  name: 'RainNotificationSettings',

  components: {
    RainNotificationSettingsForm
  },

  mixins: [
    GaugesConnectorMixin,
    NotificationsConnectorMixin
  ],

  data () {
    return {
      error: '',
      success: ''
    }
  },

  methods: {
    async handleSubmit (updates = []) {
      try {
        // TODO: should cumulativeRain AND/OR startCumulatingFrom
        // be reset by any of these updates?
        await await Promise.all(
          updates.map(u => {
            const {
              updatedAt,
              startCumulatingFrom,
              ...rest
            } = u
            return this.updateNotification(rest)
          })
        )

        try {
          this.$gtm.trackEvent({
            event: 'GAEvent-v4',
            category: 'Notifications',
            label: '',
            action: 'submit'
          })
        } catch (e) {}

        this.success = 'Woo hoo! Notification settings updated.'
      } catch (e) {
        this.error = 'One or more gauge notification settings could not be updated. Please refresh and try again.'
        throw e
      }
    }
  }
}
</script>

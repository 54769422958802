export default {
  watch: {
    gauge (newGauge, oldGauge) {
      this.form = { ...this.form, ...newGauge }
    }
  },

  data () {
    return {
      form: {
        uid: '',
        gid: '',
        latitude: '',
        longitude: '',
        title: '',
        description: '',
        isPublic: true,
        publicProps: false,
        hasAlerts: true,
        units: 'in',
        image: '',
        newImage: '' // only used for GaugeEdit
      }
    }
  },

  mounted () {
    const publicProps = (this.gauge.publicProps === undefined)
      ? false
      : this.gauge.publicProps

    this.form = {
      ...this.form,
      ...this.gauge,
      publicProps
    }
  }
}
<!-- TODO: update FAQs -->
<template>
  <v-container fluid grid-list-lg id="help" class="help pt-0">
    <page-section  header>
        <h1>How do I...</h1>
    <!--/end for dev-->
    </page-section>
    <page-section>
      <div class="help__wrapper">

        <div class="help__question">
          <collapse>
            <div class="help__trigger" slot="trigger" @click="trackHelp('Can I save this App to my phone?')">
              <h3 class="help__trigger-header">Can I save this App to my phone?</h3>  
              <div class="help__trigger-icon">
                <v-icon class="log-list__item-trigger__icon" :size="arrowSize" color="primary">keyboard_arrow_down</v-icon>
              </div>  
            </div>
            <div class="help__content" slot="content">
              Yes! Go to the home page (click on the MyRAINge Log logo at the top or bottom of the page) and then follow your device-specific instructions to “Add To Homescreen”:
              <br><br>
              <b>iOS:</b>
              <ol>
                <li>
                  In Safari, at the bottom of the screen you'll see an icon with an arrow pointing up from a square, click this and then select "Add to Home Screen".
                </li>
                <li>
                  Choose a name for the app icon. The Web App will now act as a native app on your homescreen and you will not have to type the URL in again!
                </li>
              </ol>
              <b>Android:</b>
              <ol>
                <li>
                  In Chrome, the App install notification should automatically happen if you visit the site at least twice, with at least five minutes between visits
                </li>
                <li>
                  Depending on your Android version, you should also find a button to “Add to homescreen” in the menu
                </li>
              </ol>

            </div>  
          </collapse>
        </div>

        <div class="help__question">
          <collapse>
            <div class="help__trigger" slot="trigger" @click="trackHelp('How does Offline Mode work?')">
              <h3 class="help__trigger-header">How does Offline Mode work?</h3>  
              <div class="help__trigger-icon">
                <v-icon class="log-list__item-trigger__icon" :size="arrowSize" color="primary">keyboard_arrow_down</v-icon>
              </div>  
            </div>
            <div class="help__content" slot="content">
              You can go offline (no network connection) and add observations (“Logs”) to an existing gauge. 
              <br><br>
              <b>Requirements:</b>
              <ul>
                <li>
                  Android mobile device (phone/tablet) using Chrome or Firefox browsers 
                  <ul>
                    <li>
                      iOS will not work due at this time (iPhone, iPad), but MacOS will work in Chrome or Firefox
                    </li>
                    <li>
                      Most laptop computers will also work, using current versions of Chrome or Firefox
                    </li>
                  </ul>
                </li>
                <li>
                  You cannot be browsing in a Private/Incognito session
                </li>
                <li>
                  You must already have an account
                </li>
                <li>
                  The Gauge you want to add Logs to must already exist
                </li>
              </ul>
              <b>Steps:</b>
              <ol>
                <li>
                  Signin/Login to MyRAINge Log and go to “My Gauges” Dashboard
                </li>
                <li>
                  Add the Gauge you want to add Logs to (see “How do I create a Gauge”)
                </li>
                <li>
                  <b>Do not logout or go to the public section of the site - the app is ready for you to go offline</b>
                </li>
                <li>
                  Using this same device and same web browser (or Web App saved to your Homescreen) head offline to your gauge location and re-open the browser
                </li>
                <li>
                  You should see your gauges dashboard or gauge detail page load and there will be a Notification Bar at the bottom of your screen that indictates you are “Offline”
                </li>
                <li>
                  Add Logs following the process in “How do I add an observation to my Gauge”
                </li>
                <li>
                  Each action will add to the Sync Queue in the Offline Notification Bar
                </li>
                <li>
                  Go back online with this same device &amp; browser and the Offline Notification Bar will turn green and show a “Sync Now” button - click this to sync your changes to the server.
                </li>
              </ol>
              
              We welcome any questions, bug reports or feedback on this feature - please Contact Us!
            </div>  
          </collapse>
        </div>

        

        <div class="help__question">
          <collapse>
            <div class="help__trigger" slot="trigger" @click="trackHelp('How do I change/reset my password?')">
              <h3 class="help__trigger-header">How do I change/reset my password?</h3>  
              <div class="help__trigger-icon">
                <v-icon class="log-list__item-trigger__icon" :size="arrowSize" color="primary">keyboard_arrow_down</v-icon>
              </div>  
            </div>
            <div class="help__content" slot="content">
              If you are logged in, go to your account page by clicking <b>Account</b> from the member menu. Scroll towards the bottom of the account page and click on the button “Click Here to Change Your Password”. This will initiate the password reset cycle:
              <ol>
                <li>
              You will be redirected to a form that requires a <b>Verification Code</b> and your new password
                </li>
                <li>
              You will be sent an email containing a the <b>Verification Code</b> needed for the form mentioned above. 
                </li>
                <li>
              Complete the form. Upon success you will be redirected to the Login page. Use your new password to login.
                </li>
              </ol>
              If you are not logged in, go to the <b>Login</b> page and click on “Dang, I forgot my password.” The system will prompt your for your email address. Submit the same email address as used to create the account and click “Send Reset Link to My Email”. This will initiate the same password reset cycle as above.  
            </div>  
          </collapse>
        </div>

        <div class="help__question">
          <collapse>
            <div class="help__trigger" slot="trigger" @click="trackHelp('How do I change my email/username?')">
              <h3 class="help__trigger-header">How do I change my email/username?</h3>  
              <div class="help__trigger-icon">
                <v-icon class="log-list__item-trigger__icon" :size="arrowSize" color="primary">keyboard_arrow_down</v-icon>
              </div>  
            </div>
            <div class="help__content" slot="content">
              If you are logged in, go to your account page by clicking <b>Account</b> from the member menu. Scroll towards the middle of the account page to find the “Update Email” section.
              <ol>
                <li>
                  Enter your new email address, replacing your old email
                
                  <div class="form__errors">
                    IMPORTANT: If you leave your browser session you will only be able to access your account using the email sent to this new email address, 
                    so you will not be able to sign in to your account until you confirm this new email, so please make sure the new email is correct and you can access it.
                  </div>
                </li>
                <li>
                  You will be asked to confirm that you understand the process, and that you are ready to continue
                </li>
                <li>
                  You will be redirected to a form that requires a <b>Verification Code</b> (your password will be required if you refresh the page or click the link from the email)
                </li>
                <li>
                  You will be sent an email containing a the <b>Verification Code</b> needed for the form mentioned above. 
                </li>
                <li>
                  Complete the form. Upon success you will be redirected to the Account page, with confirmation of the email change
                </li>
              </ol>
            </div>  
          </collapse>
        </div>

        <div class="help__question">
          <collapse>
            <div class="help__trigger" slot="trigger" @click="trackHelp('How do I create a Gauge?')">
              <h3 class="help__trigger-header">How do I create a Gauge?</h3>  
              <div class="help__trigger-icon">
                <v-icon class="log-list__item-trigger__icon" :size="arrowSize" color="primary">keyboard_arrow_down</v-icon>
              </div>  
            </div>
            <div class="help__content" slot="content">
              While on your <b>Dashboard</b> (which can be accessed by clicking on <b>Gauges</b> in the member menu), click on the “Add Gauge” button within the <b>My Gauges</b> section (https://myraingelog.arizona.edu/dashboard/gauge/add). 
              You will be taken to a form to add a new gauge. From top to bottom, here is a brief explanation of each field:
              <ol>
                <li>
                  Add Photo: clicking this button will trigger the browser dialog to upload a photo
                  <ul>
                    <li>
                      Only accepts jpg, png and gif
                    </li>
                    <li>
                      3MB max file size
                    </li>
                  </ul>
                </li>
                <li>
                  Map: use this map to drop a pin of the exact location of your gauge. This will auto fill the Latitude/Longitude fields
                  <ul>
                    <li>
                      Find My Location: uses the browser’s location API to determine your location. This will automatically drop a pin at your location if the service is enabled.
                    </li>
                    <li>
                      I’ll Find It Myself: double click on the map to drop a pin, which then becomes draggable. 
                    </li>
                  </ul>
                </li>
                <li>
                  Latitude/Longitude: auto populated when dropping a pin on the map, but you can also manually input these values.
                </li>
                <li>
                  Gauge Name: give your gauge a descriptive name.
                </li>
                <li>
                  Description: describe the location of your gauge that might help a future collaborator.
                </li>
                <li>
                  Preferences: 
                  <ul>
                    <li>
                      Public: a feature that is currently in beta, check this if you’d like to opt in. (see "What does it mean when I "opt in" for the Public feature")
                    </li>
                    <li>
                      Units: what measurement type will your be entering your readings in.
                    </li>
                  </ul>
                </li>
              </ol>
              That’s it! Upon success, you can then immediately add your first observation from the success screen by clicking on “Add Log Observation”.

            </div>  
          </collapse>
        </div>

        <div class="help__question">
          <collapse>
            <div class="help__trigger" slot="trigger" @click="trackHelp('How do I add an observation to my Gauge?')">
              <h3 class="help__trigger-header">How do I add an observation to my Gauge?</h3>  
              <div class="help__trigger-icon">
                <v-icon class="log-list__item-trigger__icon" :size="arrowSize" color="primary">keyboard_arrow_down</v-icon>
              </div>  
            </div>
            <div class="help__content" slot="content">
              <p>
                There are many places from within the application you can initiate the process of adding a new gauge observation. 
                Let’s assume you’re currently viewing the Gauge Detail page. From there click the green button in the header labeled “Add Log”. 
                You will then be presented with the <b>Add Observation</b> form.
              </p>
              
                Keep in mind that the purpose of this Observation form is to always input the current level your rain gauge. 
                Whether it’s your initial observation or your 100th observation, always enter the current level of your rain gauge. 
                This means that your initial reading doesn’t necessarily have to start at 0.00. Here is a field-by-field breakdown.
              <ol>
                <li>
                  Date: click the calendar icon to select the date the reading was taken
                </li>
                <li>
                  Measurement: although each gauge has a measurement type associated with it, you can temporarily switch the measurement type if you want to take a more accurate reading
                </li>
                <li>
                  Reading: enter the the current level of precipitation in your rain gauge. Our system will take care of the rest :) 
                </li>
                <li>
                  Description: add a useful, descriptive message about the observation
                </li>
                <li>
                  Add Photo: upload a photo with your observation to help chronicle the journey!
                </li>
              </ol>
              
              Upon a successful submission, you will be present with the options to “Reset Baseline”. Meaning, if your gauge is getting to full, or you would like to release some precipitation from your gauge, make sure to inform our new system what the new level is. 

            </div>  
          </collapse>
        </div>

        <div class="help__question">
          <collapse>
            <div class="help__trigger" slot="trigger" @click="trackHelp('How do I generate a report of my Gauge?')">
              <h3 class="help__trigger-header">How do I generate a report of my Gauge?</h3>  
              <div class="help__trigger-icon">
                <v-icon class="log-list__item-trigger__icon" :size="arrowSize" color="primary">keyboard_arrow_down</v-icon>
              </div>  
            </div>
            <div class="help__content" slot="content">
              <p>
                The reporting functionality of MyRAINge Log is based on the Latitude/Longitude of your gauge. Even if you haven’t entered any observations for the gauge, you can still run a report to uncover meaningful historical data about that Latitude/Longitude.
              </p>

              Perform the following steps to generate a report of the current status of a Gauge:
              <ol>
                <li>
                  Navigate to a detail page of a gauge: 
                  <ul>
                    <li>
                      From the member menu click Gauges, underneath the “My Gauges” sections click on a gauge.
                    </li>
                  </ul>
                </li>
                <li>
                  When on the Gauge Detail page, scroll down to “Rainfall Chart” section and click on “Generate Report”
                </li>
              </ol>
              
              The first time a report is generated, it could take up to 30 seconds. If the generation of the report runs into any issues, you will be presented with an option to “Try Again”. Here is a top-down list of the features on the report:
              <ol>
                <li>
                  Print: this triggers the print dialogue native to the browser in use. From that dialogue, you typically are able to also “Save as PDF” or “Open in PDF Viewer”. 
                </li>
                <li>
                  Date Range: set your <b>From</b> and <b>To</b> values. This date range cannot be longer than two years. 
                </li>
                <li>
                  Rainfall Chart: visual comparison of the cumulative values of your rain gauge to historical and estimated rainfall. 
                  <ul>
                    <li>
                      Use the drop down in the top right of this section to determine what data will be fetched against the date range.
                      <ul>
                        <li>
                          If “All data” or “Only my gauge data” is selected, the Chart’s Time Scale (X-Axis) will not precede the date of the first observation made on this gauge, regardless of the value set in the <b>Date Range</b>.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  Observation Log Data Table: a row/column, sortable, representation of your gauge data, compared to the raw “Historical Quantile Data”. Feel free to select all this content with your mouse and copy/paste directly into a spreadsheet based application. 
                </li>
              </ol>

            </div>  
          </collapse>
        </div>

        <div class="help__question">
          <collapse>
            <div class="help__trigger" slot="trigger" @click="trackHelp('What does it mean when I opt in for the Public feature?')">
              <h3 class="help__trigger-header">What does it mean when I "opt in" for the Public feature?</h3>  
              <div class="help__trigger-icon">
                <v-icon class="log-list__item-trigger__icon" :size="arrowSize" color="primary">keyboard_arrow_down</v-icon>
              </div>  
            </div>
            <div class="help__content" slot="content">
              <p>
                At the bottom of each Gauge detail page, there is a setting for allowing each gauge to be “Public.” This feature is currently in beta and we are only collecting data for people who want to opt-in. We will never share your personal data, but the gauge location may be shown on a public map, and summary data at that location may be used for public reports.
              </p>
              <p>
                The intent is to create a community of members sharing data to validate reported rainfall.
              </p>
              <p>
                If you would like to help us shape the future of this feature, please click the “Send Feedback” button below and let us know what you think!
              </p>
            </div>  
          </collapse>
        </div>
      </div>

      <template slot="actions">
        <v-btn :to="{ name: 'contact' }" depressed rounded color="accent" large>Send Feedback</v-btn>
      </template>
      <!--/end for dev-->
    </page-section>

    <page-section slim centered>
      <h2>Can't find what you're looking for?</h2>
      <p>Find helpful articles, practical guides, and training videos on our knowledge base website.</p>
      <template slot="actions">
        <v-btn href="//info.myraingelog.arizona.edu"
          target="_blank"
          rel="noopener"
          depressed
          rounded
          color="accent"
          large>
          Get More Help
        </v-btn>
      </template>
    </page-section>
  </v-container>
</template>

<script>
  let Help = {
    name: 'help',
    data() {
      return {
        arrowSize: 45
      }
    },
    methods: {
      trackHelp(value) {
        this.$gtm.trackEvent({
          event: 'GAEvent-v4',
          category: 'Help',
          label: value,
          action: 'click'
        })
      }
    }
  }

  export default Help
</script>

<style lang="sass" src="./Help.sass"></style>

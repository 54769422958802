<template>
  <v-container fluid grid-list-lg id="confirm_registration" class="confirm_registration pt-0">
    <page-section header>
      <h1>Confirm your Account</h1>
      <p>Please enter the Verification Code we sent to your Email.</p>
    </page-section>
    <page-section centered>
      <div class="form__wrapper">
        <div class="form__success" v-if="success">{{success}}</div>
        <div class="form__errors" v-if="error">{{error}}</div>
        <form @submit.prevent="formSubmit(handleSubmit)" class="form" novalidate>
          <v-text-field
            v-model="emailCode"
            label="Email verification code"
            v-validate="'required'"
            required
            outlined
            single-line
            aria-required="true"
            name="emailCode"
            data-vv-as="verification code"
            :error-messages="errors.collect('emailCode')">
          </v-text-field>
          <div class="form__actions">
            <v-btn type="submit" color="accent" depressed rounded :loading="loading" large>Verify</v-btn>
            <v-btn
              text
              large
              @click.native.prevent="resendCode"
              class="bare primary--text">
              RESEND CODE
            </v-btn>
          </div>
        </form>
      </div>
    </page-section>
  </v-container>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import FormValidation from '@/mixins/FormValidation'

  let Confirm = {
    name: 'confirm_registration',
    
    props: {
      confirmId: String,
      confirmCode: String
    },

    mixins: [FormValidation],

    data () {
      return {
        emailCode: this.confirmCode,
        error: '',
        success: ''
      }
    },

    created() {
      if(!this.confirmId || this.getToken) {
        this.$router.replace('/login')
      }
    },

    computed: {
      ...mapGetters(['getToken']),
    },

    methods: {
      ...mapActions([
        'confirmRegistration',
        'resendConfirmRegistration'
      ]),
      async handleSubmit () {
        try {
          await this.confirmRegistration({ userId: this.confirmId, code: this.emailCode })

          // TODO: check all your "delayed" loading booleans
        } catch ({ message }) {
          console.log(message)
          this.error = message
          this.loading = false
        }
      },
      async resendCode () {
        try {
          await this.resendConfirmRegistration(this.confirmId)

          this.success = "New verification code sent."
        } catch (error) {
          console.log(error.message || error)
          this.error = error.message || error
        }
      }
    }
  }

  export default Confirm
</script>

<template>
  <v-row
    class="gauge-list"
    tag="ul"
  >
    <v-col
      v-for="gauge in getGauges"
      :key="gauge.gid"
      cols="12"
      sm="12"
      md="6"
      tag="li"
    >
      <gauge-list-item
        :gauge="gauge"
        :notification="notifications.includes(gauge.gid)"
        :loading="allLogsLoading"
      />
    </v-col>
  </v-row>
</template>

<script>
import GaugeListItem from './GaugeListItem/GaugeListItem'
import DisplayManagerMixin from '@/mixins/DisplayManagerMixin'

const GaugeList = {
  components: {
    GaugeListItem
  },

  mixins: [DisplayManagerMixin],

  props: {
    archived: [Boolean, String],
    gauges: {
      type: Array,
      default: () => ([])
    },
    // array of gids with pending notifications
    notifications: {
      type: Array,
      default: () => ([])
    }
  },

  computed: {
    getGauges () {
      return this.gauges.filter(g => !!g.archived === !!this.archived)
    }
  }
}

export default GaugeList
</script>

<style lang="sass">
  .gauge-list
    list-style: none
    padding-left: 0 !important

  @media(max-width: $desktop-breakpoint)
    .gauge-list
      padding: 0

      .gauge-list-item
        padding-right: ($gutter-common / 2)
        padding-left: ($gutter-common / 2)

  @media(max-width: $tablet-breakpoint)
    .gauge-list
      padding: 0

      .gauge-list-item
        padding-right: 10px
        padding-left: 10px
</style>

<template>
  <transition name="fade" mode="out-in">
    <div v-if="publicGaugesLoading">
      <div class="app__loader text-center">
        <div class="home-loading-ripple"></div>
      </div>
    </div>
    <div
      v-else
      class="public-container"
    >
      <router-view></router-view>
    </div>
  </transition>
</template>

<script>
  import { mapActions } from 'vuex'
  import DisplayManagerMixin from '@/mixins/DisplayManagerMixin'

  let PublicContainer = {
    mixins: [DisplayManagerMixin],

    created() {
      this.getPublicGauges()
    },

    methods: {
      ...mapActions(['getPublicGauges'])
    }
  }

  export default PublicContainer
</script>

<template>
  <div class="phone-image">
   <!-- <div class="phone-image__outline"> -->
     <!-- <div class="phone-left"></div>
     <div class="phone-top"></div>
     <div class="phone-right"></div>
     <div class="phone-speaker"></div> -->
     <slot name="image"></slot>
   <!-- </div> -->
  </div>
</template>

<script>
  let PhoneImage = {
    // props: {
    //   img: Object
    // },
    data() {
      return {}
    }
  }

  export default PhoneImage
</script>

<style lang="sass" src="./PhoneImage.sass"></style>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions([
      'createNotification',
      'updateNotification',
      'deleteNotification',
      'markNotificationAsRead',
      'clearAllPendingNotifications'
    ])
  }
}
<template>
  <div class="map-drawer" :class="classObject">
    <div class="map-drawer__inner">
      <slot></slot>
    </div>

    <div class="map-drawer__close" @click="close()">
      <v-icon color="black">close</v-icon>
    </div>
  </div>
</template>

<script>
  import { reduce } from 'lodash'
  import { LEAFLET_PAN_TO_OFFSET } from '@/store/mutation-types'

  let MapDrawer = {
    props: {
      value: Boolean
    },

    watch: {
      isActive(val) {
        this.$emit('input', val)
      },
      value(val) {
        if (val !== this.isActive) this.isActive = val
      }
    },

    data() {
      return {
        isActive: this.value
      }
    },

    computed: {
      classObject() {
        return {
          'map-drawer': true,
          'map-drawer--open': this.isActive,
          'map-drawer--closed': !this.isActive
        }
      }
    },

    methods: {
      close() {
        this.$emit('close')
        this.isActive = !this.isActive
      }
    }
  }

  export default MapDrawer
</script>

<style lang="scss">
  $width: 200px;
  $height: 150px;

  .map-drawer {
    text-align: left;
    width: $width;
    height: 100%;
    color: $common-text-color;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1001;
    padding: ($gutter-common / 2);
    box-shadow: 0px 1px 5px $color-gray;
    transition: all .25s ease;
  }

  .map-drawer__inner {
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    ul {
      margin: 0;
      padding: ($gutter-common / 2) 0;
      list-style: none;
      border-top: 1px solid $color-gray-light;

      &:first-child {
        padding-top: 0;
        border-top: none;
      }
    }
  }

  .map-drawer__close {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .map-drawer--open {
    transform: translateX(0);
  }

  .map-drawer--closed {
    transform: translateX(-($width + 5));
  }

  @media(max-width: $desktop-breakpoint) {
    .map-drawer {
      width: 100%;
      height: $height;
      position: absolute;
      top: auto;
      bottom: 0;
      padding: ($gutter-common / 2);
      box-shadow: 2px 0px 5px $color-gray;
    }

    .map-drawer--open {
      transform: translateY(0);
    }

    .map-drawer--closed {
      transform: translateY($height);
    }
  }
</style>
<template>
  <v-container fluid grid-list-lg id="profile" class="profile pt-0">
    <div class="top-success-message" v-if="success">
      {{ success }}
    </div>

    <page-section header>
      <h1>My Account</h1>

      <div class="white--text">
        <p class="text-body-2 mb-0"><strong>UUID:</strong> {{ getUserId }}</p>
      </div>
    </page-section>

    <page-section centered v-if="!isUserEmailVerified">
      <h2>Important: Verify Your Email</h2>
      <form @submit.prevent="verifyEmail" class="form" novalidate>
        <div class="form__wrapper">
          <transition name="fade" mode="out-in">
            <div class="form__errors" v-if="msgConfirmEmail">
              {{ msgConfirmEmail }}
            </div>
          </transition>

          <v-text-field
            v-model="form.email"
            v-validate="'required'"
            label="Email"
            required
            outlined
            single-line
            data-vv-name="email"
            name="email"
            :error-messages="errors.collect('email')"
            aria-autocomplete="off"
            autocomplete="off"
          />
        </div>

        <div class="form__actions">
          <v-btn type="submit" color="accent" :loading="updateEmailLoading" depressed rounded large>Verify Email</v-btn>
        </div>
      </form>
    </page-section>

    <page-section centered class="profile__personal-info">
      <v-icon color="secondary" size="40" class="profile__user-icon ma-0">{{ $vuetify.icons.values.mrl_login }}</v-icon>
      <h2>Personal Info</h2>

      <form @submit.prevent="updateName" class="form" novalidate>
        <div class="form__wrapper">
          <transition name="fade" mode="out-in">
            <div class="form__success" v-if="msgUpdateName">
              {{ msgUpdateName }}
            </div>
          </transition>

          <v-text-field
            v-model="form.name"
            v-validate="'required'"
            label="Name"
            required
            outlined
            single-line
            data-vv-name="name"
            name="name"
            :error-messages="errors.collect('name')"
            aria-autocomplete="off"
            autocomplete="off"
          />
        </div>

        <div class="form__actions">
          <v-btn
            type="submit" 
            color="accent"
            :loading="personalSettingsLoading"
            depressed
            rounded
            large
          >
            Save Info
          </v-btn>
        </div>
      </form>
    </page-section>

    <page-section centered slim v-if="isUserEmailVerified">
      <h2>Update Email</h2>
      <form @submit.prevent="handleEmailChange" class="form profile__update-email-form" novalidate>
        <div class="form__wrapper">
          <transition name="fade" mode="out-in">
            <div class="form__success" v-if="msgChangeEmail">
              {{ msgChangeEmail }}
            </div>
          </transition>

          <v-text-field
            v-model="form.email"
            v-validate="'required|email'"
            label="Email"
            required
            outlined
            single-line
            data-vv-name="email"
            name="email"
            hint="NOTE: You will not be able to sign in to your account until you confirm this new email, so please make sure it is correct and you can access it before updating your email."
            persistent-hint
            :error-messages="errors.collect('email')"
            aria-autocomplete="off"
            autocomplete="off"
          />
        </div>

        <div class="form__actions">
          <v-btn
            type="submit" 
            color="accent"
            :loading="updateEmailLoading"
            depressed
            rounded
            large
          >
            Update Email
          </v-btn>
        </div>
      </form>

      <h2>Update Email Preferences</h2>

      <p>If you'd like to stop receiving MyRAINge Log News and Feature Updates, locate any previous email sent to you, scroll to the bottom of the email and click on "update your preferences". This link will take you to a web page where you can control your email subscription preferences.</p>
    </page-section>

    <rain-notification-settings id="rain-notifications" />

    <page-section centered>
      <h2>Password Reset</h2>
      <v-btn
        @click.native="changePassword"
        color="accent"
        :loading="changePasswordLoading"
        depressed
        rounded
        large>
        Change your password
      </v-btn>
    </page-section>
  </v-container>
</template>

<script>
import UserGetters from '@/mixins/vuex/UserGetters'
import RainNotificationSettings from '@/components/RainNotificationSettings/RainNotificationSettings'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'Profile',

  mixins: [UserGetters],

  components: {
    RainNotificationSettings
  },

  props: {
    success: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      personalSettingsLoading: false,
      updateEmailLoading: false,
      form: {
        name: '',
        email: ''
      },
      msgUpdateName: '',
      msgChangeEmail: '',
      msgConfirmEmail: '',
      changePasswordLoading: false
    }
  },
  computed: {
    ...mapState({
      route: state => state.route
    }),
    nextPath() {
      return {
        name: 'ConfirmEmail',
        query: {
          user: this.getUserId,
          email: this.getUserEmail
        }
      }
    }
  },
  mounted () {
    this.form.name = this.getUserFullName
    this.form.email = this.getUserEmail
  },
  methods: {
    ...mapActions([
      'updateUserAttributes',
      'forgotPassword',
      'changeEmail',
      'resendEmailConfirmationCode'
    ]),

    clearAllMsgs() {
      this.msgUpdateName = ''
      this.msgChangeEmail = ''
    },
    
    async updateName() {
      try {
        this.$validator.validateAll()
        if (this.errors.any()) return false

        this.personalSettingsLoading = true
        
        await this.updateUserAttributes({ name: this.form.name })

        this.clearAllMsgs()
        this.msgUpdateName = "Your Name has been successfully updated!"
        this.personalSettingsLoading = false
      } catch ({ message }) {
        console.error(message)
        this.personalSettingsLoading = false
      }
    },

    async verifyEmail() {
      try {
        const newEmail = this.form.email.trim().toLowerCase()

        this.$validator.validateAll()
        if (this.errors.any()) return false

        this.updateEmailLoading = true
        
        await this.resendEmailConfirmationCode({ userId: this.getUserId })

        this.clearAllMsgs()
        
        this.updateEmailLoading = false
        this.$router.replace(this.nextPath)
      } catch (error) {
        console.error(error.message || error)
        this.msgChangeEmail = error.message || error
        this.updateEmailLoading = false
      }
    },

    async handleEmailChange() {
      try {
        this.msgChangeEmail = ""

        // trim it and convert to lowercase
        const newEmail = this.form.email.trim().toLowerCase()

        // compare it with the email that is in the store
        if (newEmail === this.getUserEmail.trim().toLowerCase()) {
          this.msgChangeEmail = "Email has not changed, no update necessary."

          return false
        }

        this.$validator.validateAll()
        if (this.errors.any()) return false

        if (window.confirm("** IMPORTANT **\n\nYou will not be able to sign in to your account until you confirm this new email, so please make sure it is correct and you can access it.\n\nDo you want to continue this change now?")) {
          this.updateEmailLoading = true
        
          await this.changeEmail(newEmail)

          this.clearAllMsgs()
          
          this.updateEmailLoading = false
          this.$router.replace(this.nextPath)
        }
      } catch (error) {
        console.error(error.message || error)
        this.msgChangeEmail = error.message || error
        this.updateEmailLoading = false
      }
    },

    async changePassword() {
      try {
        this.changePasswordLoading = true

        await this.forgotPassword(this.getUserEmail)

        this.$router.push({ 
          name: 'changePassword', 
          params: { success: 'Check your email for the verification code.' },
          query: { email: this.getUserEmail }
        })
      } catch (error) {
        console.error(error.message || error)
        this.changePasswordLoading = false
      }
    }
  }
}
</script>

<style lang="sass" src="./Profile.sass"></style>

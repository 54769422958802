<template>
  <filter-list class="helpers-list"
    :items="helpers" 
    label="Filter Helpers by name or gauge title"
    :loading="loadingHelpers"
    :value="defaultFilter">

    <li class="helpers-list__item"
      slot-scope="props" 
      slot="list"
      :key="props.item.uid">
      <div class="helpers-list__item-name">{{ props.item.name }}</div>

      <div class="helpers-list__item-others">
        <span>{{ props.item.gaugeCount }} Gauges</span>
        <v-btn 
          text
          :to="{ name: 'helpersEdit', params: { id: props.item.uid } }"
          class="ma-2 primary--text super-bare">Edit</v-btn>
      </div>
    </li>

    <template slot="empty-item">
      <li class="helpers-list__item"
        style="pointer-events: all !important;"
        v-if="!loadingHelpers && !helpers.length">
        <div class="helpers-list__item-name">Uh oh. No helpers...</div>

        <div class="helpers-list__item-others">
          <v-btn color="accent" depressed rounded :to="{name: 'helpersAdd'}" large>Add Your First Helper</v-btn>
        </div>
      </li>

      <li class="helpers-list__item" v-else>
        <div class="helpers-list__item-name">Sorry! Nothing matched your search</div>
      </li>
    </template>
  </filter-list>
</template>

<script>
  import scroll from 'zenscroll'
  import DisplayManagerMixin from '@/mixins/DisplayManagerMixin'
  import { mapGetters } from 'vuex'

  let HelpersList = {   
    mixins: [DisplayManagerMixin],

    watch: {
      '$route.query': {
        handler(queryObj) {
          scroll.setup(500, 150)
          let { filter } = queryObj
          this.defaultFilter = filter || ''

          this.$nextTick(() => {
            if (filter) scroll.to(this.$el, undefined, 100)
          })
        },
        immediate: true,
        deep: true
      }
    },

    data() {
      return {
        defaultFilter: ''
      }
    },
    
    computed: {
      ...mapGetters([
        'getHelpers',
        'getHelperByUid',
        'getHelperGaugeByComposite',
        'getAllHelperGaugesByUid',
        'getActiveHelperGaugesByUid'
      ]),
      helpers() {
        return this.getHelpers.map((helper) => {
          let {
            sharedWithUid: uid,
            sharedWithName: name,
          } = helper

          return {
            uid,
            name,
            gaugeCount: this.getActiveHelperGaugesByUid(uid).length,
            gaugeNames: this.flattenGaugeNames(uid)
          }
        })
      }
    },

    methods: {
      flattenGaugeNames(uid) {
        return this.getActiveHelperGaugesByUid(uid).reduce((outcome, gauge) => {
          return `${outcome} ${gauge.title}`
        }, '')
      },
    }
  }

  export default HelpersList
</script>

<style lang="sass">
  .helpers-list
    padding: ($gutter-common / 2)

    .filter-list__search-wrapper
      margin-bottom: ($gutter-common / 2)

    .filter-list__list
      padding-left: 0 !important
      height: 325px
      overflow: auto
      -webkit-overflow-scrolling: touch

  .helpers-list__item-name
    color: $common-text-color
    font-weight: bold

  .helpers-list__item-others
    justify-content: space-around

    > *
      padding: 0 5px

  .helpers-list__item
    color: $color-gray
    justify-content: space-between
    border: 1px solid $color-gray
    border-radius: 10px
    padding: ($gutter-common / 2) ($gutter-common / 3)
    margin-bottom: ($gutter-common / 2)
    display: flex
    align-items: center
</style>
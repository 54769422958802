import Vue from 'vue'
import notificationsApi from '@/api/notifications'
import { datePicker } from '@/utils/dateUtils'

const RESET_NOTIFICATION = {
  cumulativeRain: 0,
  notificationCount: 0,
  startCumulatingFrom: datePicker() // today
}

const state = {
  items: []
}

const getters = {
  getNotificationByGid: (state) => (gid) => {
    return state.items.find(item => item.gid === gid)
  },
  getPendingNotificationByGid: (state, getters) => (gid) => {
    return getters.gaugesWithPendingNotifications.includes(gid)
      ? getters.getNotificationByGid(gid)
      : null
  },
  gaugesWithPendingNotifications: (state) => {
    return state.items.reduce((withNotifications, i) => {
      const {
        gid,
        cumulativeRain = 0,
        threshold = 0
      } = i
      const numPass = !isNaN(cumulativeRain) && !isNaN(threshold)

      return numPass && (cumulativeRain >= threshold)
        ? withNotifications.concat(gid)
        : withNotifications
    }, [])
  }
}

const actions = {
  async listNotifications ({ commit, getters }) {
    if (!getters.getUserId) throw new Error('No user id')

    try {
      const response = await notificationsApi.fetch({
        uid: getters.getUserId,
        limit: getters.getAllGauges.length
      })

      commit('setNotifications', response)

      return response
    } catch (e) {
      console.error('[listNotifications] :', e)
      throw e
    }
  },

  async createNotification ({ commit, getters }, payload) {
    if (!getters.getUserId || !payload.gid) { throw new Error('No user id or gauge id') }

    try {
      const notificationPayload = {
        uid: getters.getUserId,
        ...RESET_NOTIFICATION,
        ...payload
      }

      const response = await notificationsApi.create(notificationPayload)
      commit('updateNotification', response)

      return response
    } catch (e) {
      console.error('[createNotification] :', e)
      throw e
    }
  },

  async updateNotification ({ commit, getters }, payload) {
    if (!getters.getUserId || !payload.gid) { throw new Error('No user id or gauge id') }

    try {
      const notificationPayload = {
        ...payload,
        uid: getters.getUserId
      }

      const response = await notificationsApi.update(notificationPayload)
      commit('updateNotification', response)

      return response
    } catch (e) {
      console.error('[updateNotification] :', e)
      throw e
    }
  },

  async markNotificationAsRead ({ dispatch }, gid) {
    if (!gid) throw new Error('[markNotificationAsRead] required gid')

    await dispatch('updateNotification', {
      gid,
      ...RESET_NOTIFICATION
    })
  },

  async deleteNotification ({ commit, getters }, gid) {
    if (!getters.getUserId || !gid) { throw new Error('No user id or report id') }

    try {
      await notificationsApi.delete({
        uid: getters.getUserId,
        gid
      })

      commit('deleteNotification', gid)

      return 'SUCCESS'
    } catch (e) {
      console.error('[deleteNotification] :', e)
      throw e
    }
  },

  async clearAllPendingNotifications ({ dispatch, getters }) {
    await Promise.all(
      getters.gaugesWithPendingNotifications.map(
        gid => dispatch('markNotificationAsRead', gid)
      )
    )
  }
}

export const mutations = {
  setNotifications (state, payload) {
    state.items = payload
  },
  updateNotification (state, payload) {
    const { gid } = payload
    const index = state.items.findIndex(item => item.gid === gid)

    if (index > -1) {
      Vue.set(state.items, index, {
        ...state.items[index],
        ...payload
      })
    } else {
      state.items.push(payload)
    }
  },
  deleteNotification (state, gid) {
    const index = state.items.findIndex(item => item.gid === gid)

    if (index > -1) {
      Vue.delete(state.items, index)
    }
  }
}

export default { state, getters, actions, mutations }

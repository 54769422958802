<template>
  <router-link
    :to="{ name: 'gaugeDetail', params: { gid: getGid } }"
    :class="getClasses"
    :data-title="gauge.title"
  >
    <gauge-list-image
      v-if="!noImage"
      :src="gauge.image"
    />

    <div class="gauge-list-item__info">
      <h3 class="gauge-list-item__title">
        {{ gauge.title }}
      </h3>

      <div class="d-flex align-center">
        <div v-if="loading">
          <spinner
            :size="40"
            color="accent"
          />
        </div>
        <template v-else-if="!gauge.archived">
          <gauge-list-item-stats
            v-if="getLogList(gauge.gid).length > 1"
            :gid="gauge.gid"
          />

          <div
            v-else
            class="d-flex align-center"
          >
            <v-btn
              :to="newLogRoute"
              color="accent"
              depressed
              rounded
              small
              class="ma-0 mr-n1"
            >
              Get started!
            </v-btn>
            <tooltip>You have not logged any observations.</tooltip>
          </div>
        </template>
        <template v-else>
          <div class="d-flex align-center">
            <stat
              :stat="archivedDate"
              label="Archived On"
            />
          </div>
        </template>

        <v-icon
          v-if="notification"
          color="error"
          class="ml-1"
        >
          {{ $vuetify.icons.values.mrl_rain_alert }}
        </v-icon>
      </div>
    </div>

    <v-btn
      v-if="!gauge.archived"
      :to="newLogRoute"
      fab
      color="accent"
      depressed
      :small="$vuetify.breakpoint.smAndUp"
      :x-small="$vuetify.breakpoint.xsOnly"
      class="gauge-list-item__add-icon">
      <v-icon
        :small="$vuetify.breakpoint.xsOnly"
        class="v-icon--with-circle"
      >
        add
      </v-icon>
    </v-btn>
  </router-link>
</template>

<script>
import GaugeListImage from '@/components/GaugeListImage/GaugeListImage'
import GaugeListItemStats from '@/components/GaugeList/GaugeListItemStats/GaugeListItemStats'
import { dateToDayShort } from '@/utils/dateUtils'
import { mapGetters } from 'vuex'

const GaugeListItem = {
  components: {
    GaugeListImage,
    GaugeListItemStats
  },

  props: {
    noImage: Boolean,
    gauge: {
      type: Object,
      default: () => {
        return {}
      }
    },
    loading: Boolean,
    notification: Boolean
  },

  computed: {
    ...mapGetters([
      'getLogList'
    ]),
    getClasses() {
      return {
        'gauge-list-item': true
      }
    },
    archivedDate() {
      const { archived } = this.gauge
      return archived ? dateToDayShort(archived) : ''
    },
    getGid() {
      let { gid } = this.gauge

      return gid
    },
    newLogRoute() {
      return { name: 'addLog', params: { gid: this.getGid } }
    }
  },

  methods: {
    handleClick() {
      this.$router.push({ name: 'gaugeDetail', params: { gid: this.getGid } })
    }
  }
}

export default GaugeListItem
</script>

<style lang="sass" src="./GaugeListItem.sass"></style>

<template>
  <v-container fluid grid-list-lg id="helpers" class="helpers pt-0">
    <div class="top-success-message" v-if="$route.params.added">
      WAY TO GO!
      <br />
      You just added a helper to your account.
    </div>

    <div class="top-success-message" v-if="$route.params.saved">
      SUCCESS!
      <br />
      Changes to Helper have been updated.
    </div>

    <div class="top-success-message" v-if="$route.params.deleted">
      Helper has been successfully removed from your gauges.
    </div>

    <page-section header>
      <h1>Helpers</h1>
    </page-section>

    <page-section slim v-if="getHelpers.length">
      <h2 id="my-helpers">My Helpers</h2>

      <helpers-list/>
    </page-section>

    <page-section centered slim>
      <h2>Add Helpers</h2>
      <p>Invite folks to help you manage each of your gauges in one of two roles:</p>

      <h3>Contributor</h3>
      <p>Access to add observations but cannot edit gauge related settings.</p>

      <h3>Editor</h3>
      <p>Full access to add and edit observations and modify gauge settings. Cannot relocate or delete the gauges shared with them.</p>

      <div slot="actions">
        <v-btn
          depressed
          rounded
          large
          color="accent"
          :to="{ name: 'helpersAdd' }">
          Add Helper
          <v-icon right color="white" size="30">
            {{ $vuetify.icons.values.mrl_login }}
          </v-icon>
        </v-btn>
      </div>
    </page-section>
  </v-container>
</template>

<script>
  import HelpersList from '@/components/HelpersList/HelpersList'
  import DisplayManagerMixin from '@/mixins/DisplayManagerMixin'
  import { mapGetters } from 'vuex'

  let Helpers = {
    components: {
      HelpersList
    },

    mixins: [DisplayManagerMixin],

    computed: {
      ...mapGetters(['getHelpers'])
    }
  }

  export default Helpers
</script>

var globalInput;

const InputNoScroll = {
  bind(el, binding, vnode) {
    if (binding.value) {
      const {
        context: {
          $refs: {
            [`${binding.value}`]: {
              $refs: {
                input
              } = {}
            } = {}
          }
        } = {}
      } = vnode
    
      globalInput = input;
    
      globalInput.addEventListener('mousewheel', (e) => {
        // this is a "hack" to remove 
        globalInput.blur()
      }) 
  
      return
    } else {
      console.warn('v-input-no-scroll requires binding value')
    }
  },
  unbind(el, binding, vnode) {
    if (binding.value && globalInput) {
      globalInput.removeEventListener('mousewheel', () => {})
    }
  }
}

export default InputNoScroll

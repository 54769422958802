<template>
  <span class="number-counter">{{ animatedNumber }}</span>
</template>

<script>
  import TWEEN from 'tween'

  let NumberCounter = {
    mounted() {
      this.doTheAnimation(this.number)
    },

    props: {
      number: {
        type: [Number, String],
        required: true,
        default: 0
      },
      format: Function
    },

    watch: {
      number(newValue, oldValue) {
        this.doTheAnimation(newValue, oldValue)
      }
    },

    data() {
      return {
        animatedNumber: 0
      }
    },

    methods: {
      doTheAnimation(newValue, oldValue = this.animatedNumber) {
        var vm = this
        
        function animate () {
          if (TWEEN.update()) {
            window.requestAnimationFrame(animate)
          }
        }

        new TWEEN.Tween({ tweeningNumber: Number(oldValue) })
          .easing(TWEEN.Easing.Quadratic.Out)
          .to({ tweeningNumber: Number(newValue) }, 750)
          .onUpdate(function () {
            vm.animatedNumber = (vm.format)
              ? vm.format(this.tweeningNumber)
              : this.tweeningNumber.toFixed(0)
          })
          .start()

        animate()
      }
    }
  }

  export default NumberCounter
</script>

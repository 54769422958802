import { mapActions } from 'vuex'

export default {
  created() {
    this.getPublicSummary()
  },

  methods: {
    ...mapActions(['getPublicSummary'])
  },

  render(h) {
    return h('div', [this.$slots.default])
  }
}
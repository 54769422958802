<template>
  <v-container
    class="about pt-0"
    fluid
    id="about"
  >
    <page-section header>
      <h1>We want to help you succeed</h1>
      <p>
        Built on science and trusted by ranchers and land managers.
      </p>
    </page-section>

    <page-section slim>
      <!-- <div class="embed-responsive embed-responsive-16by9 hidden">
        <iframe src="https://www.youtube.com/embed/LJyW-U35RWc?rel=0" frameborder="0" allowfullscreen></iframe>
      </div> -->
      <h2>Why We Made this</h2>
      <p>Our goal is to help make it easier for Ranchers and Land Managers to collect, manage and use precipitation observations to support management decisions. MyRAINge Log is designed with rangeland management in mind, bringing together cutting edge web technology, climate data, and visualization tools focused on collecting and analyzing cumulative precipitation from remote, unattended rain gauges. </p>

      <p>This tool emerged from discussions with ranchers and land managers in central Arizona on the need for an online tool specifically designed around the type of infrequent, cumulative precipitation observations often collected at remote, rangeland sites. Our tool adds unique ‘climate context’ and near-real time precipitation alerts based on gridded climate estimates. These features help put your data in context (“Does this precipitation observation indicate drought or not?”) helping you to quickly interpret your data and support management decisions.</p>
    </page-section>

    <page-section centered>
      <h2>Who's behind this</h2>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="team-member"
        >
          <div class="team-member__img">
            <img src="./images/MikeCrimmins.jpg">
          </div>
          <h3 class="team-member__name">
            Michael A. Crimmins <span class="subset">Ph.D.</span>
          </h3>
          <ul class="team-member__titles">
            <li>Associate Professor &amp; Extension Specialist - Climate Science</li>
          </ul>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="team-member"
        >
          <div class="team-member__img">
            <img src="./images/MitchMcClaran.jpg">
          </div>
          <h3 class="team-member__name">
            Mitch McClaran <span class="subset">Ph.D.</span>
          </h3>
          <ul class="team-member__titles">
            <li>Professor, School of Natural Resources and the Environment</li>
            <li>Director for Research, Santa Rita Experimental Range</li>
            <li>Assistant Director, Arizona Experiment Station</li>
          </ul>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="team-member"
        >
          <div class="team-member__img">
            <img src="./images/JulieBrugger.jpg">
          </div>
          <h3 class="team-member__name">
            Julie Brugger
          </h3>
          <ul class="team-member__titles">
            <li>Research Scientist, School of Natural Resources and the Environment</li>
          </ul>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="team-member"
        >
          <div class="team-member__img">
            <img src="./images/DougTolleson.jpg">
          </div>
          <h3 class="team-member__name">
            Doug Tolleson <span class="subset">Ph.D.</span>
          </h3>
          <ul class="team-member__titles">
            <li>Associate Extension Specialist &amp; Research Scientist</li>
          </ul>
        </v-col>
      </v-row>
      <template slot="actions">
        <v-btn
          depressed
          color="accent"
          rounded
          :to="{ name: 'contact' }"
          large
        >
          Say Hello
        </v-btn>
      </template>
    </page-section>
    <page-section
      centered
      slim>
      <h2>PROJECT FUNDERS</h2>

      <a href="http://westrme.wsu.edu/" rel="noopner" target="_blank">
        <img src="./images/western-logo.png" alt="Western Extension Risk Management Education" class="about__financial-support__image">
      </a>
      <!-- <h3>Western Extension Risk Management Education</h3> -->
      <p>Providing tools and training to support producers in establishing long-term risk management strategies that will strengthen the economic viability of their farm and ranch enterprises.</p>
      <a href="http://westrme.wsu.edu/" target="_blank" rel="noopener" class="about__financial-support__link">
        westrme.wsu.edu&nbsp;&nbsp;<v-icon color="primary" size="18">open_in_new</v-icon>
      </a>

      <a href="https://swclimatehub.info/" rel="noopner" target="_blank">
        <img src="./images/southwest-climate-hub-color.png" alt="USDA Southwest Climate Hub" class="about__financial-support__image">
      </a>
      <!-- <h3>USDA Southwest Climate Hub</h3> -->
      <p>The USDA Southwest Climate Hub: developing and delivering climate-smart information and technologies.</p>
      <a href="https://swclimatehub.info/" target="_blank" rel="noopener" class="about__financial-support__link">
        swclimatehub.info&nbsp;&nbsp;<v-icon color="primary" size="18">open_in_new</v-icon>
      </a>
    </page-section>

    <get-started />
  </v-container>
</template>

<script>
import GetStarted from '@/components/GetStarted/GetStarted'

export default {
  name: 'About',
  components: {
    GetStarted
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" src="./About.sass"></style>

<template>
  <v-alert
    :icon="false"
    type="error"
    class="notifications-alert"
  >
    <slot />

    <template
      v-if="settings"
      #append
    >
      <v-btn
        v-if="clearAll"
        color="white"
        outlined
        small
        rounded
        :loading="loading"
        class="mr-1"
        @click="handleClear"
      >
        Clear all
      </v-btn>
      <v-btn
        icon
        small
        :to="{ name: 'account', hash: '#rain-notifications' }"
      >
        <v-icon>settings</v-icon>
      </v-btn>
    </template>

    <template
      v-if="dismissible"
      #close="{ toggle }"
    >
      <v-btn
        icon
        small
        @click="handleClose(toggle)"
      >
        <v-icon>close</v-icon>
      </v-btn>
    </template>
  </v-alert>
</template>

<script>
export default {
  name: 'NotificationsAlert',

  props: {
    dismissible: Boolean,
    settings: Boolean,
    clearAll: Boolean
  },

  data () {
    return {
      loading: false
    }
  },

  methods: {
    handleClose (fn) {
      this.$emit('close')
      fn()
    },
    handleClear () {
      this.loading = true
      this.$emit('clear')
    }
  }
}
</script>

<style lang="sass" src="./NotificationsAlert.sass" />

<template>
  <form @submit.prevent="formSubmit(handleSave)" class="helpers-form form" novalidate>
    <v-row>
      <v-col cols="12">
        <p id="helperEmail">
          Please enter the email address of the member you would like to add as a helper. They must be a registered myRAINge Log member.
        </p>
        <v-text-field
          v-model="sharedWithEmail"
          v-validate="'required'"
          label="Email"
          required
          outlined
          single-line
          aria-required="true"
          aria-labelledby="helperEmail"
          data-vv-name="email"
          name="email"
          :disabled="edit"
          :hint="(sharedWithName) ? `Helper name: ${sharedWithName}`: ''"
          persistent-hint
          :error-messages="errors.collect('sharedWithEmail')"
          aria-autocomplete="off"
          autocomplete="off"
        />
      </v-col>

      <v-col cols="12" class="helpers-form__invite" v-if="userNotFound">
        <pill-alert type="danger">
          We could not find a registered MyRAINge Log member with that email address. Please provide them this url and invite them to join! <a href="https://myraingelog.arizona.edu/signup." target="_blank" rel="noopener">https://myraingelog.arizona.edu/signup</a>
        </pill-alert>
      </v-col>

      <v-col cols="12" class="helpers-form__invite" v-if="userExists">
        <pill-alert type="danger">
          {{ sharedWithName }} is already setup as a Helper. <router-link :to="{ name: 'helpersEdit', params: { id: userExists } }">Edit Helper permissions</router-link>
        </pill-alert>
      </v-col>
    </v-row>

    <v-row class="helpers-form__roles">
      <v-col cols="12" md="6">
        <h3>Contributor</h3>
        <p>Access to add observations but cannot edit gauge related settings.</p>
      </v-col>

      <v-col cols="12" md="6">
        <h3>Editor</h3>
        <p>Full access to add and edit observations and modify gauge settings. Cannot relocate or delete the gauges shared with them.</p>
      </v-col>
    </v-row>

    <div class="helpers-form__list">
      <div class="helpers-form__list-row">
        <div class="helpers-form__list-row__title">&nbsp;</div>

        <div class="helpers-form__radio-group">
          <span class="helpers-form__labels">Editor</span>
          <span class="helpers-form__labels helpers-form__labels--contrib">Contributor</span>
          <span class="helpers-form__labels helpers-form__labels--inactive" v-if="edit">Active</span>
        </div>
      </div>

      <div class="helpers-form__list-row">
        <div class="helpers-form__list-row__title"><strong>ALL GAUGES</strong></div>

        <div class="helpers-form__radio-group">
          <radio label="Editor"
            hide-label
            :name="ROLE_EDITOR"
            :value="ROLE_EDITOR"
            v-model="masterRadio"/>
          <radio label="Contributor" 
            hide-label
            :name="ROLE_CONTRIBUTOR" 
            :value="ROLE_CONTRIBUTOR"
            v-model="masterRadio"/>
          <div v-if="edit">&nbsp;</div>
        </div>
      </div>

      <template>
        <div v-for="(gauge) in sortedGauges"
          :key="gauge.gid"
          class="helpers-form__list-row"
          :data-title="gauge.title">
          <div class="helpers-form__list-row__title">{{ gauge.title }}</div>

          <div class="helpers-form__radio-group">
            <radio :label="`${gauge.title} - Editor`"
              class="helpers-form__radio-editor"
              hide-label
              :name="gauge.gid" 
              :value="ROLE_EDITOR"
              :disabled="shareActiveLookup[gauge.gid] == false"
              v-model="shareLevelLookup[gauge.gid]"/>
            <radio :label="`${gauge.title} - Contributor`" 
              hide-label
              class="helpers-form__radio-contributor"
              :name="gauge.gid" 
              :value="ROLE_CONTRIBUTOR"
              :disabled="shareActiveLookup[gauge.gid] == false"
              v-model="shareLevelLookup[gauge.gid]"/>
            <checkbox v-model="shareActiveLookup[gauge.gid]"
              label="Make inactive" 
              hide-label
              name="inactive"
              v-if="(shareLevelLookup[gauge.gid] && shareActiveLookup[gauge.gid] != undefined) && edit"/>
            <div v-else-if="edit">&nbsp;</div>
          </div>
        </div>
      </template>
    </div>

    <div class="form__actions">
      <v-btn text large @click.native="handleReset" class="primary--text bare">Reset</v-btn>
      <pill-alert type="danger" v-if="error">
        {{ error }}
      </pill-alert>
      <v-btn 
        type="submit"
        color="accent"
        depressed
        rounded
        small
        :disabled="disableSubmit"
        :loading="loading"
        large>
        {{ getSubmitLabel }}
      </v-btn>
      <v-btn text large @click.native="handleRemove" v-if="edit" class="helpers-form__remove-btn bare primary--text">Remove Helper</v-btn>
      <v-btn text exact large :to="{ name: 'helpers' }" role="button" class="primary--text bare">Back to Helpers</v-btn>
    </div>
  </form>
</template>

<script>
import DisplayManagerMixin from '@/mixins/DisplayManagerMixin'
import scroll from 'zenscroll'
import FormValidation from '@/mixins/FormValidation'
import {
  ROLE_EDITOR,
  ROLE_CONTRIBUTOR
} from '@/utils/constants'
import { reduce, map, sortBy } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

const HelpersForm = {
  mixins: [
    DisplayManagerMixin,
    FormValidation
  ],

  mounted () {
    scroll.setup(500, 150)
  },

  watch: {
    helper: {
      handler: 'mergeHelperData',
      immediate: true
    },
    gauges: {
      handler() { 
        this.setShareLevelLookup()
        this.setShareActiveLookup()
      },
      immediate: true
    },
    masterRadio(val) {
      if (val !== false) this.handleMasterRadio(val)
    }
  },

  props: {
    add: Boolean,
    edit: Boolean,
    helper: {
      type: Object,
      default: () => {
        return {}
      }
    },
    gauges: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data () {
    return {
      ROLE_CONTRIBUTOR,
      ROLE_EDITOR,
      loading: false,
      userFound: false,
      userNotFound: false,
      userExists: false,
      masterRadio: false,
      sharedWithEmail: '',
      sharedWithName: '',
      sharedWithUid: '',
      uid: '',
      shareLevelLookup: {},
      shareActiveLookup: {},
      error: ''
    }
  },

  computed: {
    sharedByUid() {
      return this.getUserId
    },
    ...mapGetters([
      'getHelperGaugeByComposite',
      'getHelperByUid',
      'getAllHelperGaugesByUid',
      'getUserId',
      'getUserEmail'
    ]),
    disableSubmit() {
      return !this.anyGaugeSelected
    },
    anyGaugeSelected() {
      return Object.values(this.shareLevelLookup).includes(ROLE_CONTRIBUTOR) 
        || Object.values(this.shareLevelLookup).includes(ROLE_EDITOR)
    },
    getSubmitLabel() {
      return !this.edit ? 'Add Helper' : 'Save Edits'
    },
    sortedGauges() {
      return sortBy(this.gauges, ['title'])
    }
  },

  methods: {
    ...mapActions([
      'getCognitoUser',
      'updateHelper',
      'notifyHelper'
    ]),
    mergeHelperData() {
      map(this.helper, (val, key) => { this[key] = val })
    },
    setShareLevelLookup(override) {
      /**
        loops over state.gauges.all to create lookup object for shareLevel:
        {
          'uid-gid': 'editor',
          'uid-gid': 'contributor'
        }

        this.shareLevelLookup is used for v-model on radio buttons
        */
      this.shareLevelLookup = reduce(this.gauges, (outcome, gauge) => {
        let composite = `${this.sharedWithUid}--${gauge.gid}`
        let { shareLevel } = this.getHelperGaugeByComposite(composite) || {}

        outcome[gauge.gid] = (override !== undefined) ? override : shareLevel

        return outcome
      }, {})
    },
    setShareActiveLookup(override) {
      /**
        loops over state.gauges.all to create lookup object for shareActive:
        {
          'uid-gid': true,
          'uid-gid': 'false'
        }

        this.shareActiveLookup is used for v-model for checkboxes
        */
      this.shareActiveLookup = reduce(this.gauges, (outcome, gauge) => {
        let composite = `${this.sharedWithUid}--${gauge.gid}`
        let { shareActive } = this.getHelperGaugeByComposite(composite) || {}

        outcome[gauge.gid] = (override !== undefined) ? override : shareActive

        return outcome
      }, {})
    },
    async handleSave() {
      try {
        this.error = ''
        this.loading = true
        
        // check if helper is MRL users
        // TODO: need to check if user already has this helper
        if (this.add) {
          await this.checkHelper()
          this.helperIsNotMe()
          this.helperDoesntExist()
        }

        let saveParams = this.add ? { added: true } : { saved: true }

        let payloads = reduce(this.shareLevelLookup, (outcome, val, gid) => {
          if (val !== undefined) outcome.push({ 
            gid,
            uid: this.sharedWithUid,
            sharedWithName: this.sharedWithName,
            sharedWithEmail: this.sharedWithEmail,
            shareLevel: this.shareLevelLookup[gid],
            sharedByUid: this.sharedByUid,
            shareActive: (this.shareActiveLookup[gid] === undefined) ? true : this.shareActiveLookup[gid]
          })

          return outcome
        }, [])

        const notificationPayload = {
          email: this.sharedWithEmail,
          message: `Hello there!\n\n${this.getUserEmail} has invited you to be a Helper on one or more of their gauges. What an honor, huh? Log into MyRAINge Log and checkout your dashboard to start helping!\n\nhttps://myraingelog.arizona.edu/dashboard\n\nThanks,\nThe MyRAINge Log Team`
        }

        if (this.add || (this.edit && payloads > this.getAllHelperGaugesByUid(this.sharedWithUid))) {
          await this.notifyHelper(notificationPayload)
        }

        // creates an array of "dispatch('updateHelper', payload)"
        Promise.all(payloads.map(payload => this.updateHelper(payload)))
          .then(() => {
            this.$router.replace({ name: 'helpers', params: saveParams })
            setTimeout(() => { this.loading = false }, 600)
          }).catch(e => {
            this.loading = false
            this.error = 'Uh oh. Something happened while saving this Helper. Go back to your Helpers list and refresh the page to see if your updates were captured.'
          })
      } catch(e) {
        this.loading = false
        if (e.checkFailed) this.error = e.message
        throw new Error(e.message)
      }
    },
    handleMasterRadio(value) {
      this.shareLevelLookup = Object.assign({}, this.shareLevelLookup, this.gauges.reduce((outcome, gauge) => {
        if (this.shareActiveLookup[gauge.gid] !== false) outcome[gauge.gid] = value

        return outcome
      }, {}))

      setTimeout(() => { this.masterRadio = false }, 1000)
    },
    helperIsNotMe() {
      if (this.sharedByUid === this.sharedWithUid) {
        throw Object.assign(new Error('You can\'t share gauges with yourself! Please use an email address of another MyRAINge Log Account.'), { checkFailed: true })
      }

      return true
    },
    helperDoesntExist() {
      this.userExists = false

      if (this.getHelperByUid(this.sharedWithUid)) {
        scroll.to(this.$el)
        this.userExists = this.sharedWithUid
        throw Object.assign(new Error('This helper already exists.'), { checkFailed: true })
      }

      return true
    },
    async checkHelper() {
      try {
        if (!this.sharedWithEmail) return

        this.userFound = this.userNotFound = false
        
        let { name, uid } = await this.getCognitoUser(this.sharedWithEmail)

        this.sharedWithName = name
        this.sharedWithUid = uid
        this.userFound = true

        return true
      } catch(e) {
        if (e.message.includes('Member Not Found')) this.userNotFound = true

        scroll.to(this.$el)
        // this.error = 'Member Not Found'
        throw Object.assign(new Error('Member Not Found.'), { checkFailed: true })
      }
    },
    handleReset() {
      this.setShareActiveLookup()
      this.setShareLevelLookup()
    },
    handleRemove() {
      this.$dialogBus.$emit('open-dialog', {
        type: 'HelperDeleteDialog',
        props: {
          uid: this.sharedByUid,
          helper: this.helper
        }
      })
    }
  }
}

export default HelpersForm
</script>

<style lang="sass" src="./HelperForm.sass"></style>

// TODO: make this like notification or gauge mixin
import { mapGetters, mapActions } from 'vuex'
import { rccDateFormat } from '@/utils/dateUtils'

export default {
  computed: {
    ...mapGetters([
      'getGaugeLngLatString',
      'from',
      'to'
    ]),
  },
  methods: {
    ...mapActions([
      'getHistoricalData',
      'getTheoreticaldata'
    ]),
    dispatchHistoricalData({ gid, start = this.from, end = this.to, checkLoading = true }) {
      return this.getHistoricalData({
        gid,
        loc: this.getGaugeLngLatString(gid),
        sdate: rccDateFormat(start),
        edate: rccDateFormat(end),
        checkLoading
      })
    },
    dispatchTheoreticalData({ gid, start = this.from, end = this.to }) {
      return this.getTheoreticaldata({
        gid,
        loc: this.getGaugeLngLatString(gid),
        sdate: rccDateFormat(start),
        edate: rccDateFormat(end)
      })
    },
  }
}
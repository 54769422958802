<template>
  <v-container fluid grid-list-lg id="gauge_empty" class="gauge-empty pt-0">
    <page-section header-image>
      <gauge-list-image :src="gauge.image" />
      <h2>Reset Gauge</h2>
      <h1>{{ gauge.title }}</h1>
    </page-section>

    <page-section>
      <div class="gauge-empty__wrapper">
        <empty-gauge
          btn-text="Reset Gauge"
          :date="getDate"
          :gid="gauge.gid"
          :measurement="gauge.units"
        />
      </div>
    </page-section>
  </v-container>
</template>

<script>
import GaugeListImage from '@/components/GaugeListImage/GaugeListImage'
import EmptyGauge from '@/components/EmptyGauge/EmptyGauge'
import GaugesConnectorMixin from '@/mixins/connectors/gauges'
import { dateToLocal } from '@/utils/dateUtils'

const GaugeEmpty = {
  components: {
    GaugeListImage,
    EmptyGauge
  },

  mixins: [GaugesConnectorMixin],

  computed: {
    logs () {
      return this.$store.getters.getLogList(this.gid)
    },
    getDate () {
      const {
        params: {
          date = ''
        } = {}
      } = this.$route

      return date || dateToLocal(new Date())
    }
  }
}

export default GaugeEmpty
</script>

<style lang="sass">
  .gauge-empty__wrapper
    max-width: ($form-wrapper * .75)
    margin: 0 auto
</style>

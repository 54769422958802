const AWS_PREFIX = process.env.VUE_APP_AWS_PREFIX
const DATA_BUCKET_KEY = process.env.VUE_APP_DATA_BUCKET_KEY
const AWS_REGION = process.env.VUE_APP_AWS_REGION
const IDENTITY_POOL = process.env.VUE_APP_IDENTITY_POOL
const USER_POOL= process.env.VUE_APP_USER_POOL
const APP_CLIENT= process.env.VUE_APP_APP_CLIENT

export default {
  region: AWS_REGION,
  IdentityPoolId: IDENTITY_POOL,
  UserPoolId: USER_POOL,
  AppClientId: APP_CLIENT,
  ApiGatewayBaseURL: process.env.VUE_APP_AWS_API,
  imageBaseUrl: `${AWS_PREFIX}/${DATA_BUCKET_KEY}/public/images/`,
  userImageBaseUrl: `${AWS_PREFIX}/${DATA_BUCKET_KEY}/public/`,
  historicalDataUrl: `${AWS_PREFIX}/${DATA_BUCKET_KEY}/public/rcc-acis/historical/`,
  dataBucketPublicFolder: 'public/',
  userImageBucketFolder: 'user/'
}

import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      notifications: state => state.notifications.items
    }),
    ...mapGetters([
      'getNotificationByGid',
      'gaugesWithPendingNotifications',
      'getPendingNotificationByGid'
    ]),
  },

  methods: {
    ...mapActions([
      'listNotifications'
    ])
  }
}
// import Api from '@aws-amplify/api'
import authRequest from './utils/authRequest'
import { uniq, pick } from 'lodash'

const ENDPOINT = '/gauge-shares'
const HELPERS_ENDPOINT = `${ENDPOINT}/helpers`
// these fields will be "plucked" as helper properties
const HELPER_FIELDS = ['sharedWithUid', 'sharedWithEmail', 'sharedWithName']
// these fields will be "plucked" as gaugePreferences properties
const GAUGE_FIELDS = ['created', 'shareActive', 'shareLevel']

export const formatHelperComposite = (uid, gid) => `${uid}--${gid}`

export function helpersNormalizer(helpers = []) {
  try {
    let helpersResult = helpers
    let result = []
    let entities = { helpers: {}, gaugePreferences: {} }

    // this line makes this normalizer compatible with one or more helpers
    if (!Array.isArray(helpers)) helpersResult = [helpers]

    /** 
      loop over each helper and create structure like:

      result: [uid, uid],
        entities: {
          helpers: {
            uid: {
              ...HELPER_FIELDS
              gaugePreferences: ['uid--gid', 'uid--gid']
            }
          },
          gaugePreferences: {
            'uid--gid': {
              ...GAUGE_FIELDS
            }
          }
        }
     */
    helpersResult.forEach((helper) => {
      let {
        sharedWithUid,
        sharedGaugeId
      } = helper

      // create composite key of uid and gid
      const composite = formatHelperComposite(sharedWithUid, sharedGaugeId)

      result.push(sharedWithUid)

      if (entities.helpers[sharedWithUid]) {
        entities.helpers[sharedWithUid].gaugePreferences.push(composite)
      } else {
        entities.helpers[sharedWithUid] = pick(helper, HELPER_FIELDS)
        entities.helpers[sharedWithUid].gaugePreferences = [composite]
      }

      entities.gaugePreferences[composite] = pick(helper, GAUGE_FIELDS)
    })

    return { result: uniq(result), entities }
  } catch(e) {
    throw new Error(`helpersNormalizer: ${e}`)
  }
}

const helpersApi = {
  fetch(uid) {
    if (!uid) throw new Error('[helpersApi] fetch: uid parameter is required')

    // let response = await Api.get('mrlApi', `${HELPERS_ENDPOINT}/${uid}`, { response: true })
    // debugger

    return authRequest({
      url: `${HELPERS_ENDPOINT}/${uid}`,
      method: 'get'
    }).then(result => {
      let { data = {} } = result

      return data
    })
  },

  put({ uid, gid, ...data }) {
    if (!uid || !gid) throw new Error('[helpersApi] put: uid and gid parameters are required')

    return authRequest({
      url: `${ENDPOINT}/${uid}/${gid}`,
      method: 'put',
      data: { ...data }
    }).then((response) => {
      let { 
        data: {
          Attributes = {}
        } = {}
      } = response

      // merging in the payload sent from the action, this will get us things like "sharedName"
      return helpersNormalizer([{...data, ...Attributes}])
    })
  },

  delete({ uid, sharedWithUid }) {
    if (!uid || !sharedWithUid) throw new Error('[helpersApi] delete: uid and sharedWithUid parameters are required')

    return authRequest({
      url: `${HELPERS_ENDPOINT}/${uid}/${sharedWithUid}`,
      method: 'delete'
    }).then(response => {
      let { data = {} } = response

      return data
    })
  }
}

export default helpersApi

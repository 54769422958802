<template>
  <v-container
    fluid
    grid-list-lg
    id="export"
    class="export pt-0"
  >
    <page-section header>
      <h1>Export</h1>
    </page-section>

    <page-section slim>
      <pill-alert
        v-if="error"
        type="danger"
      >
        {{ error }}
      </pill-alert>

      <form
        class="export__form form"
        novalidate
        @submit.prevent="formSubmit(submit)"
      >
        <div class="export__gauge-select mb-8">
          <label
            for="gauges"
          >
            <div><strong>Select one or more gauges</strong></div>
            <p class="mt-0">Each gauge will be a separate CSV</p>
          </label>

          <v-select 
            id="gauges"
            label="Select"
            v-model="form.gauges"
            :items="gaugeItems"
            v-validate="'required'"
            :error-messages="errors.first('gauges')"
            data-vv-name="gauges"
            name="gauges"
            multiple
            outlined
            single-line
            hide-details
          >
            <template #item="{ item, on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <template v-slot:default="{ active, toggle }">
                  <v-list-item-action class="mr-5">
                    <checkbox 
                      v-model="active"
                      :name="item.value"
                      @click="toggle"/>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </template>
          </v-select>
        </div>

        <div class="export__timeframe mb-8">
          <p><strong>What timeframe?</strong></p>

          <radio
            v-model="form.timeframe"
            value="all"
            label="All logged observations"
            name="timeframe"
            class="mb-2"
          />
          <radio
            v-model="form.timeframe"
            value="daterange"
            label="Logged observations within a specific date range"
            name="timeframe"
            class="mb-3"
          />
        </div>

        <div
          v-if="form.timeframe === 'daterange'"
          class="export__date-range align-center d-flex ml-8 mt-n4 mb-8"
        >
          <date-menu
            v-model="form.from"
            v-validate="'required|validDate'"
            :error-messages="errors.collect('from')"
            data-vv-name="from"
            name="from"
            label="From"
            hide-actions
            :text-field-props="{
              appendIcon: null,
              hideDetails: true,
              outline: true,
              singleLine: true
            }"
          />

          <v-icon small>remove</v-icon>

          <date-menu
            v-model="form.to"
            v-validate="'required|validDate'"
            :error-messages="errors.collect('to')"
            data-vv-name="to"
            name="to"
            label="To"
            hide-actions
            :return-format="endOfDay"
            :text-field-props="{
              appendIcon: null,
              hideDetails: true,
              outline: true,
              singleLine: true
            }"
          />
        </div>

        <div class="export__format mb-8">
          <p><strong>How should your observations be exported into the spreadsheet column(s)?</strong></p>

          <checkbox
            v-model="form.format"
            value="raw"
            name="raw"
            label="As they were logged (accounting for gauge resets)"
            class="mb-3"
          />

          <checkbox
            v-model="form.format"
            value="difference"
            name="difference"
            label="Actual Precipitation (difference between observations)"
            class="mb-3"
          />

          <checkbox
            v-model="form.format"
            value="accumulation"
            name="accumulation"
            label="Running accumulation of precipitation (as seen in the rainfall chart)"
            class="mb-3"
          />
        </div>

        <div class="export__additional-data mb-8">
          <p><strong>Additional data to include</strong></p>

          <checkbox
            v-model="form.additional"
            value="estimated"
            name="estimated"
            label="Estimated rainfall accumulation (based on interpolated PRISM data)"
            class="mb-3"
          />

          <checkbox
            v-model="form.additional"
            value="spi"
            name="spi"
            label="Historical average rainfall labels (extremely dry, very dry, dry, wet, very wet)"
            class="mb-3"
          />
          
          <checkbox
            v-model="form.additional"
            value="notes"
            name="notes"
            label="Notes"
            class="mb-3"
          />

          <checkbox
            v-model="form.additional"
            value="location"
            name="location"
            label="Location"
            class="mb-3"
          />

          <checkbox
            v-model="form.additional"
            value="photos"
            name="photos"
            label="Photos"
            class="mb-3"
          />
        </div>

        <!-- <pre>{{ form }}</pre> -->

        <div class="form__actions text-center">
          <v-btn
            type="submit"
            color="accent"
            depressed
            rounded
            large
            :loading="loading"
          >
            Export CSV
          </v-btn>
        </div>
      </form>
    </page-section>
  </v-container>
</template>

<script>
import GaugesGetters from '@/mixins/vuex/GaugesGetters'
import FormValidation from '@/mixins/FormValidation'
import {
  endOfDay
} from '@/utils/dateUtils'

import { mapGetters } from 'vuex'

export default {
  name: 'Export',

  mixins: [
    GaugesGetters,
    FormValidation
  ],

  data () {
    return {
      endOfDay,
      success: false,
      error: null,
      form: {
        gauges: [
          ...(this.$route.query.gauge ? [this.$route.query.gauge] : [])
        ],
        timeframe: 'all', // all, daterange
        from: '',
        to: '',
        format: [
          'raw',
          'difference',
          'accumulation'
        ], // raw, difference, accumulation
        additional: []
        // additional: [
        //   'estimated',
        //   'spi',
        //   'notes',
        //   'location',
        //   'photos'
        // ]
      }
    }
  },

  computed: {
    ...mapGetters({
      from: 'from',
      to: 'to'
    }),
    gaugeItems () {
      return this.getAllGauges.map(g => ({
        text: g.title,
        value: g.gid
      }))
    }
  },

  watch: {
    'form.timeframe': {
      handler (val) {
        if (val === 'daterange') {
          this.form.from = this.from
          this.form.to = this.to
        }
      }
    }
  },

  methods: {
    async submit () {
      try {
        this.success = false
        this.error = null
        await this.exportGaugeData({ ...this.form })
        this.success = true
      } catch (e) {
        console.error(e)
        this.error = 'Uh oh. Something happened during the export process. Please refresh and try again.'
        throw e
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="sass" src="./Export.sass"/>

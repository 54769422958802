import { DATE_RANGE_UPDATE } from '../mutation-types'
import { DEFAULT_DATE_RANGE } from '@/utils/constants'
import moment from 'moment'

const FROM = moment().subtract(DEFAULT_DATE_RANGE, 'days').startOf('day').format()
const TO = moment().endOf('day').format()

const state = {
  from: FROM,
  to: TO
}

const getters = {
  from: state => state.from,
  to: state => state.to
}

const actions = {
  resetDateRange ({ commit }) {
    commit(DATE_RANGE_UPDATE, {
      from: FROM,
      to: TO
    })
  },

  updateDateRange ({ commit }, payload) {
    commit(DATE_RANGE_UPDATE, payload)
  }
}

const mutations = {
  [DATE_RANGE_UPDATE] (state, payload) {
    const { from, to } = payload

    state = Object.assign(state, {
      from: moment(from).startOf('day').format(),
      to: moment(to).endOf('day').format()
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

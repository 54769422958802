import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'isLoading',
      'isCompleted',
      'getStatus',
      'showOfflineBar',
      'getGaugeById'
    ]),
    ...mapState({
      publicGauges: state => state.publicData.gauges,
      loadingStates: state => state.ui.loadingStates,
      showAppWrapper: state => state.ui.showAppWrapper,
      isModalOpen: state => state.ui.modalOpen
    }),
    checkingAuth() {
      return this.isLoading('checkingAuth')
    },
    allLogsLoading() {
      return this.isLoading('allLogsLoading')
    },
    publicGaugesLoading() {
      return this.isLoading('publicGauges')
    },
    showDateRange() {
      const showOnRoutes = [
        'gaugeDetail',
        'dashboard',
        'gaugeReport',
        'publicGaugeReport',
        'reports',
        'reportsBuilder',
        'publicReports'
      ]
      let { name: pathName } = this.$route
        
      return showOnRoutes.includes(pathName)
    },
    loadingHelpers() {
      return this.isLoading('loadingHelpers')
    }
  }
}

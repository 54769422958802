<template>
  <v-container fluid grid-list-lg class="change-password pt-0">
    <page-section header>
      <h1>Change Password</h1>
      <p>We kind of liked your old password, but, whatever.</p>
    </page-section>

    <page-section centered>
      <div class="form__wrapper">
        <transition name="fade" mode="out-in">
          <pill-alert type="danger" v-if="error">{{ error }}</pill-alert>
        </transition>
        
        <transition name="fade" mode="out-in">
          <pill-alert type="danger" v-if="error">{{ error }}</pill-alert>
        </transition>

        <form @submit.prevent="formSubmit(handleSubmit)" class="form" novalidate>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.code"
                v-validate="'required'"
                label="Verification Code"
                required
                outlined
                single-line
                data-vv-name="code"
                data-vv-as="verification code"
                name="code"
                :error-messages="errors.collect('code')">
              </v-text-field>
              <v-text-field
                v-model="form.email"
                v-validate="'required|email'"
                label="Email Address"
                required
                outlined
                single-line
                data-vv-name="code"
                data-vv-delay="700"
                name="email"
                :error-messages="errors.collect('email')">
              </v-text-field>
              <v-text-field
                v-model="form.new_password"
                :type="showPassword ? 'text' : 'password'"
                v-validate="'required|passwordPolicy'"
                label="New Password"
                required
                outlined
                single-line
                :hint="passwordHint"
                data-vv-name="new_password"
                name="new_password"
                data-vv-as="new password"
                data-vv-delay="700"
                :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                @click:append="showPassword = !showPassword"
                :error-messages="errors.collect('new_password')">
              </v-text-field>
            </v-col>
          </v-row>

          <div class="form__actions">
            <v-btn type="submit" color="accent" depressed rounded :loading="loading" large>Update Password</v-btn>
          </div>
        </form>
      </div>
    </page-section>
  </v-container>
</template>

<script>
  import FormValidation from '@/mixins/FormValidation'
  import { mapActions } from 'vuex'

  let ChangePassword = {
    components: {},
    mixins: [FormValidation],
    props: {
      code: String,
      email: String
    },
    data() {
      return {
        // TODO: move this into FormValidation and check other forms
        showPassword: false,
        error: '',
        form: {
          code: this.code,
          email: this.email,
          new_password: ''
        },
        passwordHint: "8 characters, including uppercase, lowercase letters and numbers",
        passwordMatch: ''
      }
    },
    methods: {
      ...mapActions(['forgotPasswordConfirm']),
      async handleSubmit() {
        try {
          await this.forgotPasswordConfirm(this.form)
          
        } catch ({ message }) {
          console.error(message)
          this.loading = false
          this.error = message
          this.form.new_password = this.passwordMatch = ''
        }
      }
    }
  }

  export default ChangePassword
</script>

<template>
  <v-container
    fluid
    grid-list-lg
    :class="[
      'log-multi-add',
      onlyRequiredFields && 'log-multi-add--only-required',
      'pt-0'
    ]"
  >
    <page-section header-image>
      <gauge-list-image :src="gauge.image"/>
      <h1>{{ gauge.title }}</h1>
    </page-section>

    <page-section centered>
      <div class="log-multi-add__header">
        <pill-alert type="danger" v-if="error">{{ error }}</pill-alert>

        <h2>Add Observations</h2>

        <pill-alert class="mb-3">
          <div
            class="text-center caption common--text text--lighten-1 mt-n2"
          >
            (could change based on first entry)
          </div>
          <template v-if="getPreviousLog.reading">
            <div class="d-flex justify-space-between">
              <div class="flex-grow-1 stacked">
                <div class="stacked__top font-weight-bold">
                  Previous Observation
                  <template v-if="getPreviousLog.isReset">(reset)</template>
                </div>
                <div class="stacked__bottom">
                  {{ getPreviousLogDate }} ({{ getPreviousRelativeDate }})
                </div>
              </div>
              <div shrink class="flex-shrink-1 stacked log-add__last-reading--right">
                <div class="stacked__top">{{ getPreviousReading }}</div>
                <div class="stacked__bottom font-weight-bold">{{ humanMeasurements }}</div>
              </div>
            </div>
          </template>
          <template v-if="getNextLog.reading">
            <div class="d-flex justify-space-between">
              <div class="flex-grow-1 stacked">
                <div class="stacked__top font-weight-bold">
                  Next Observation
                  <template v-if="getNextLog.isReset">(reset)</template>
                </div>
                <div class="stacked__bottom">
                  {{ getNextLogDate }} ({{ getNextRelativeDate }})
                </div>
              </div>
              <div shrink class="flex-shrink-1 stacked log-add__last-reading--right">
                <div class="stacked__top">{{ getNextReading }}</div>
                <div class="stacked__bottom font-weight-bold">{{ humanMeasurements }}</div>
              </div>
            </div>
          </template>
        </pill-alert>
      </div>  

      <div class="log-multi-add__wrapper">
        <v-btn
          text
          large
          exact
          class="bare primary--text mb-3"
          @click="onlyRequiredFields = !onlyRequiredFields"
        >
          {{
            onlyRequiredFields
              ? 'Show all input fields'
              : 'Show only required input fields'
          }}
        </v-btn>
        <form
          class="log-multi-add__form form"
          novalidate
          @submit.prevent="formSubmit(saveRecords)"
        >
          <template
            v-for="(record, index) in form"
          >
            <div
              :key="record.id"
              :class="[
                'log-multi-add__record',
                record.isReset && 'log-multi-add__record--reset',
                record.isReset && 'mt-n3',
                'd-flex',
                'align-center',
                'mb-5'
              ]"
            >
              <div
                :class="[
                  'd-flex',
                  'align-center',
                  'flex-grow-1',
                  'flex-wrap'
                ]"
              >
                <v-icon
                  v-if="record.isReset && $vuetify.breakpoint.mdAndUp"
                  size="20"
                  class="pl-2"
                >
                  subdirectory_arrow_right
                </v-icon>

                <div
                  v-if="record.isReset"
                >
                  Reset gauge to
                </div>
                <div
                  class="log-multi-add__record-date"
                >
                  <label
                    v-if="!record.isReset"
                    :for="`${record.id}-date`"
                  >
                    Date
                  </label>
                  <date-menu
                    v-if="!record.isReset"
                    :id="`${record.id}-date`"
                    v-model="record.logDateTime"
                    v-validate="isRecordDirty(record) ? 'required|validDate' : ''"
                    :error-messages="errors.collect(`${record.id}-date`)"
                    :data-vv-name="`${record.id}-date`"
                    :name="`${record.id}-date`"
                    hide-actions
                    :text-field-props="{
                      hideDetails: true,
                      outlined: true,
                      singleLine: true,
                      disabled: record.isReset
                    }"
                  />
                  <!-- for resets only -->
                  <date-menu
                    v-else
                    :id="`${record.id}-date`"
                    :value="form[index -1].logDateTime"
                    @input="val => record.logDateTime = val"
                    v-validate="isRecordDirty(record) ? 'required|validDate' : ''"
                    :error-messages="errors.collect(`${record.id}-date`)"
                    :data-vv-name="`${record.id}-date`"
                    :name="`${record.id}-date`"
                    hide-actions
                    :text-field-props="{
                      hideDetails: true,
                      outlined: true,
                      singleLine: true,
                      disabled: record.isReset
                    }"
                  />
                </div>

                <div
                  class="log-multi-add__record-reading"
                >
                  <label
                    v-if="!record.isReset"
                    :for="`${record.id}-reading`"
                  >
                    Observation ({{ gaugeUnits }})
                  </label>
                  <v-text-field
                    v-model="record.reading"
                    :id="`${record.id}-reading`"
                    :ref="`${record.id}-reading`"
                    type="number"
                    :name="`${record.id}-reading`"
                    :data-vv-name="`${record.id}-reading`"
                    aria-required="true"
                    v-validate="isRecordDirty(record) ? 'required|decimal:3' : ''"
                    :error-messages="errors.first(`${record.id}-reading`)"
                    required
                    outlined
                    single-line
                    hide-details
                    dense
                    :v-input-no-scroll="`${record.id}-reading`"
                    aria-autocomplete="off"
                    autocomplete="off"
                    class="log-multi-add__reading"
                  />
                </div>

                <div
                  v-if="record.isReset"
                >
                   {{ humanMeasurements }}
                </div>

                <div
                  v-if="!record.isReset && !onlyRequiredFields"
                  class="log-multi-add__record-notes"
                >
                  <label for="notes">
                    Notes
                  </label>
                  <v-text-field
                    id="notes"
                    v-model="record.notes"
                    name="notes"
                    outlined
                    dense
                    single-line
                    hide-details
                  />
                </div>

                <div
                  v-if="!record.isReset && !onlyRequiredFields"
                  class="log-multi-add__record-image mt-5"
                >
                  <image-input
                    v-if="!offline"
                    v-model="record.image"
                    compact
                    name="addImage"
                  />
                </div>

                <div
                  v-if="!record.isReset"
                  class="log-multi-add__record-reset mt-5"
                >
                  <checkbox
                    v-model="record.reset"
                    name="reset"
                    label="Reset gauge"
                    @change="(val) => {
                      if (val) {
                        addResetRecord(index)
                      } else {
                        removeRecord(index + 1)
                      }
                    }"
                  />
                </div>
              </div>

              <v-btn
                v-if="!record.isReset"
                outlined
                fab
                color="error"
                depressed
                x-small
                @click="removeRecord(index)"
                class="log-multi-add__record-remove ml-6 mt-5"
              >
                <v-icon color="error">remove</v-icon>
              </v-btn>
            </div>
          </template>

          <div class="text-center">
            <v-btn
              color="accent"
              outlined
              rounded
              @click="addRecord"
            >
              Add another row
              <v-icon
                right
                color="accent"
                class="v-icon--with-circle"
              >
                add
              </v-icon>
            </v-btn>
          </div>
          
          <div class="form__actions">
            <pill-alert
              type="danger"
              v-if="!areLogsValid"
              class="mt-5"
            >
              <v-icon color="white">info</v-icon> Based on the dates and observations entered, your cumulative tally could be off. Please review before submitting.
            </pill-alert>
            <v-btn 
              text
              large
              exact
              role="link"
              class="bare primary--text mb-3"
              @click="setToSingle"
            >
              Switch to single log entry
            </v-btn>
            <v-btn
              type="submit" 
              color="accent"
              depressed
              rounded
              large
              class="form__submit"
              :disabled="!this.form.length"
              :loading="loading"
            >
              Save Logs
            </v-btn>
            <v-btn 
              text
              large
              exact
              :to="{ name: 'gaugeDetail' }"
              class="bare primary--text">
              Cancel
            </v-btn>
          </div>
        </form>
      </div>
    </page-section>
  </v-container>
</template>

<script>
// import App from 'scripts/init'
import {
  NETWORK_ERROR,
  LOG_MULTI_ENTRY_COOKIE
} from '@/utils/constants'
import numbro from 'numbro'
import convert from 'convert-units'
import FormValidation from '@/mixins/FormValidation'
import GaugeConnectorMixin from '@/mixins/connectors/gauge'
// @todo probably dont need to whole notifications connector mixin, verify
import NotificationsConnectorMixin from '@/mixins/connectors/notifications'
// @todo this won't be needed if userId, helperId, helpName is set in store
import UserGetters from '@/mixins/vuex/UserGetters'
import GaugePermissions from '@/views/Gauges/mixins/GaugePermissions'
import GaugeListImage from '@/components/GaugeListImage/GaugeListImage'
import jscookie from 'js-cookie'
import scroll from 'zenscroll'
import shortid from 'shortid'
import { mapState } from 'vuex'
import { 
  dateToFull,
  dateToTime,
  dateToUTC,
  addSeconds,
  relativeTime,
  isSameOrAfter
} from '@/utils/dateUtils'

const LOG_RECORD = {
  logDateTime: '',
  image: '',
  reading: '',
  notes: '',
  isReset: false
}

export default {
  name: 'LogMultiAdd',
  
  components: {
    GaugeListImage
  },

  beforeRouteEnter(to, from, next) {
    const {
      params = {},
      query = {}
    } = to

    if (jscookie.get(LOG_MULTI_ENTRY_COOKIE)) {
      next()
    } else {
      next({
        name: 'addLog',
        params,
        query
      })
    }
  },

  mixins: [
    GaugeConnectorMixin,
    GaugePermissions,
    UserGetters,
    FormValidation,
    NotificationsConnectorMixin
  ],

  props: {
    startingCount: {
      type: Number,
      default: 5
    }
  },

  data () {
    return {
      startingLogs: this.$route.params.startingLogs || [],
      onlyRequiredFields: false,
      msg: '',
      error: false,
      // each object in this.form is a log entry
      // there is a new prop in multi form add called "reset"
      // that is only for display a checkbox next to "Reset Gauge" checkbox
      form: []
    }
  },

  mounted () {
    for (let i = 0; i < this.startingCount; i++) {
      this.addRecord(this.startingLogs[i])
    }
  },

  computed: {
    firstInputAsUTC () {
      return this.form[0] && this.form[0].logDateTime
        ? dateToUTC(`${this.form[0].logDateTime} ${dateToTime()}`, ['MM/DD/YYYY HH:mm:ss'])
        : ''
    },
    getPreviousLog () {
      const previousLog = this.firstInputAsUTC
        ? this.gaugeLogs.find((log) => {
            return isSameOrAfter(this.firstInputAsUTC, log.logDateTime)
          })
        : this.mostRecentGaugeLog

      return previousLog || {}
    },
    getNextLog () {
      const copyLogs = this.gaugeLogs.slice().reverse()
      const nextLog = this.firstInputAsUTC
        ? copyLogs.find((log) => {
            return isSameOrAfter(log.logDateTime, this.firstInputAsUTC)
          })
        : {}

      return nextLog || {}
    },
    getNextLogDate () {
      return dateToFull(this.getNextLog.logDateTime)
    },
    getNextRelativeDate () {
      return relativeTime(this.getNextLogDate, ['MMM D YYYY h:mm a'])
    },
    getPreviousLogDate () {
      return dateToFull(this.getPreviousLog.logDateTime)
    },
    getPreviousRelativeDate () {
      return relativeTime(this.getPreviousLogDate, ['MMM D YYYY h:mm a'])
    },
    readingHint () {
      return numbro(Number(this.getPreviousReading) + 0.13).format('0.00')
    },
    getPreviousReading () {
      const reading = this.getPreviousLog.reading || '0.00'

      return this.twoDecimalPadding(convert(reading).from('in').to(this.gaugeUnits))
    },
    getNextReading () {
      const reading = this.getNextLog.reading || '0.00'

      return this.twoDecimalPadding(convert(reading).from('in').to(this.gaugeUnits))
    },
    humanMeasurements () {
      const _dict = {
        in: 'inches',
        mm: 'millimeters'
      }

      return _dict[this.gaugeUnits]
    },
    areLogsValid () {
      const workingLogs = this.form
        .slice()
        .filter(l => (l.logDateTime && l.reading))
        .sort((a, b) => {
          const aDate = dateToUTC(`${a.logDateTime} ${dateToTime()}`, ['MM/DD/YYYY HH:mm:ss'])
          const bDate = dateToUTC(`${b.logDateTime} ${dateToTime()}`, ['MM/DD/YYYY HH:mm:ss'])
          if (aDate < bDate) { return -1 }
          if (aDate > bDate) { return 1 }
          // a must be equal to b
          return 0
        })
      let valid = true
      let tally = this.getPreviousLog.reading || 0
      
      for (let i = 0; i < workingLogs.length; i++) {
        const diff = workingLogs[i].reading - tally

        if (diff < 0 && !workingLogs[i].isReset) {
          valid = false
          break
        } else {
          tally = workingLogs[i].reading
          continue
        }
      }

      return valid
    },
    ...mapState({
      offline: state => state.offline.offline
    })
  },

  methods: {
    isRecordDirty (record) {
      const {
        logDateTime,
        reading,
        notes,
        image,
        isReset
      } = record

      return [!!logDateTime, !!reading, !!notes, !!image, !!isReset].includes(true)
    },
    addRecord (options = {}) {
      this.form.push({
        id: shortid.generate(),
        ...LOG_RECORD,
        ...options
      })
    },
    addResetRecord (index) {
      this.form.splice(index + 1, 0, {
        id: shortid.generate(),
        ...LOG_RECORD,
        isReset: true
      })
    },
    removeRecord (index) {
      if (this.form[index] && this.form[index].reset) {
        this.form.splice(index + 1, 1)  
      }

      this.form.splice(index, 1)
    },
    setToSingle () {
      jscookie.remove(LOG_MULTI_ENTRY_COOKIE)
      this.$router.push({
        name: 'addLog',
        params: {
          startingLogs: this.form
        }
      })
    },
    twoDecimalPadding (number) {
      return numbro(number).format('0.00')
    },
    async saveRecords () {
      const success = []
      const errors = []
      const filteredRecords = this.form.filter(f => this.isRecordDirty(f))

      if (!filteredRecords.length) {
        this.error = ''
        this.loading = false
        return
      }

      for (let i = 0; i < filteredRecords.length; i++) {
        try {
          let newLog = {
            // @todo this could be set at the vuex action
            userId: this.getUserId,
            ...(
              !this.owner
                ? {
                    helperUid: this.getUserId,
                    helperName: this.getUserFullName
                  }
                : {}
            ),
            gid: this.gid,
            logDateTime: dateToUTC(addSeconds(`${filteredRecords[i].logDateTime} ${dateToTime()}`, i), ['MM/DD/YYYY HH:mm:ss']),
            units: this.gaugeUnits,
            reading: numbro(convert(filteredRecords[i].reading).from(this.gaugeUnits).to('in')).format('0.000'),
            image: (Array.isArray(filteredRecords[i].image)) ? filteredRecords[i].image[0] : filteredRecords[i].image,
            notes: filteredRecords[i].notes,
            isReset: filteredRecords[i].isReset
          }

          const response = await this.addGaugeLog(newLog)
          success.push(response)
        } catch (e) {
          errors.push(e)
        }
      }

      // if any single record failed, attempt to roll back all successful records
      if (errors.length) {
        console.error(errors)
        
        this.loading = false
        scroll.to(this.$el)
        this.error = 'One more or records failed to save. All actions have been rolled back. Please refresh the page and try again.'

        try {
          for (let i = 0; i < success.length; i++) {
            const { lid, gid } = success[i]
            await this.deleteLog({ lid, gid })
            console.log('rolling back successful: ', i)
          }
        } catch (e) {
          // fail rollback silently
          console.error('Roll back failed: ', e)
        }

        throw new Error(this.error)
      }

      // clear notifications for this gauge (if any)
      try {
        if (this.getPendingNotificationByGid(this.gid)) {
          await this.markNotificationAsRead(this.gid)
        }
      } catch (e) {
        console.error('Failed to mark notification as read: ', e)
        throw e
      }

      // recalculate and resort logs after new log(s) insert
      await this.massageLogs(this.gid)

      this.error = ''
      this.$router.push({
        name: 'addLogSuccess',
        params: { logs: success },  // pass over an array of logs to display readings on success page
        query: { reset: true }
      })
      setTimeout(() => { this.loading = false }, 600)
    }
  }
}

</script>

<style lang="sass" src="./LogMultiAdd.sass"></style>

import { ROLE_OWNER, ROLE_EDITOR, ROLE_CONTRIBUTOR } from '@/utils/constants'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getGaugeRole']),
    role () {
      let {
        params: {
          gid
        } = {}
      } = this.$route

      return this.getGaugeRole(gid)
    },
    owner () {
      return this.role === ROLE_OWNER
    },
    editor () {
      return this.role === ROLE_EDITOR || this.owner
    },
    contributor () {
      return this.role === ROLE_CONTRIBUTOR || this.editor
    },
    getRoleWithArticle () {
      const articleLookup = {
        [ROLE_OWNER]: 'an',
        [ROLE_EDITOR]: 'an',
        [ROLE_CONTRIBUTOR]: 'a'
      }

      return `${articleLookup[this.role]} ${this.role}`
    }
  }
}

import authRequest from './utils/authRequest'


export default {
  getCognitoUser(email) {
    if (!email) throw new Error('[helpersApi] email parameter is required')

    return authRequest({
      url: `/username/email/${email}`,
      method: 'get'
    }).then(response => {
      let { data = {} } = response

      if (data.errorMessage) throw new Error(data.errorMessage)

      return data
    })
  }
}

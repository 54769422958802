<template>
  <div class="offline-bar" :class="classObject">
    <div class="offline-bar__bar">
      <div class="offline-bar__title" v-if="isOffline">Offline Mode</div>
      <div class="flexbox--center">
        <div class="offline-bar__sync">
          <span class="offline-bar__sync-num">{{ syncQueueCount }}</span>
          <span>Unsynced</span>
        </div>
        <v-btn
          depressed
          rounded
          v-if="syncQueueCount"
          :color="!isOffline ? 'error' : 'accent'"
          class="offline-bar__show-queue"
          @click.native="viewQueue = !viewQueue">
          {{ (viewQueue) ? 'Hide Queue' : 'Show Queue' }}
        </v-btn>

        <v-btn
          depressed
          rounded
          v-if="!isOffline && syncQueueCount"
          :color="!isOffline ? 'error' : 'accent'"
          class="offline-bar__show-queue"
          :loading="loading"
          @click.native="runSync">
          Run Sync
        </v-btn>
      </div>
    </div>
    <div class="offline-bar__queue" v-if="viewQueue && syncQueueCount">
      <transition-group name="basic-list-animation" tag="div">
        <div class="offline-bar__queue-item basic-list-animation-item" v-for="item in syncQueue" v-bind:key="item.queueId">
          <span><strong>Type:</strong> {{ item.data.label }}</span>
          <span><strong>Date:</strong> {{ item.data.date }}</span>
          <template v-for="meta in item.data.meta">
            <span><strong>{{ meta.label }}:</strong> {{ meta.value }}</span>
          </template>
        </div>
      </transition-group>
      <v-btn
        depressed
        rounded
        v-if="!isOffline && syncQueueCount"
        :color="!isOffline ? 'error' : 'accent'"
        class="offline-bar__show-queue"
        :loading="loading"
        @click.native="runSync">
        Run Sync
      </v-btn>
    </div>
  </div>
</template>

<script>
  let OfflineBar = {
    components: {},
    props: {
      isOffline: {
        type: Boolean,
        required: true
      },
      syncQueue: {
        type: Array,
        required: true,
        default: () => { return [] }
      }
    },
    data() {
      return {
        viewQueue: false,
        loading: false
      }
    },
    computed: {
      classObject() {
        return {
          'offline-bar--online': !this.isOffline
        }
      },
      syncQueueCount() {
        return this.syncQueue.length
      }
    },
    methods: {
      runSync() {
        this.loading = true

        this.$store.dispatch('runOfflineSync', this.syncQueue).then(response => {
          this.loading = false
        }).catch(error => { 
          console.log(error)
          this.loading = false 
        })
      },
      toggleMode() {
        this.$store.dispatch('updateOffline', !this.isOffline)
      }
    }
  }

  export default OfflineBar
</script>

<style lang="sass" src="./OfflineBar.sass"></style>

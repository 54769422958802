<template>
  <page-section centered class="date-range secondary">
    <div class="date-range__header mb-1">Set precipitation date range</div>
    <form novalidate>
      <div class="date-range__inner">
        <date-menu
          v-model="dateRangeForm.from"
          name="from"
          label="From"
          hide-actions
          dense
          :picker-props="{
            max: datePicker(new Date())
          }"
          :text-field-props="{
            hideDetails: true,
            outlined: true,
            singleLine: true,
            appendIcon: ''
          }"
        />
        <div class="date-range__in-between" />
        <date-menu
          v-model="dateRangeForm.to"
          name="to"
          label="To"
          hide-actions
          dense
          :menu-props="{
            nudgeLeft: $vuetify.breakpoint.smAndDown ? 70 : 0
          }"
          :picker-props="{
            max: datePicker(new Date())
          }"
          :text-field-props="{
            hideDetails: true,
            outlined: true,
            singleLine: true,
            appendIcon: ''
          }"
        />
        <v-btn
          color="error"
          type="submit"
          :disabled="!isDateRangeValid || !didRangeChange"
          depressed
          rounded
          class="mx-2"
          small
          :loading="loading"
          @click.prevent="handleNewDateRange"
        >
          <template v-if="didRangeChange || !success">
            {{
              ($vuetify.breakpoint.mdAndUp)
                ? 'Apply'
                : 'Go'
            }}
          </template>
          <template v-else-if="success && !loading">
            <v-icon color="white">check</v-icon>
          </template>
        </v-btn>
        <tooltip class="hidden-sm-and-down">
          Two years max range. <br ><br > Gauge stats such as cumulative values and observation count are re-calculated by date range.
        </tooltip>
      </div>
    </form>
  </page-section>
</template>

<script>
import {
  dateToDay,
  datePicker,
  isSameOrBefore,
  dateDifference,
  isSame,
  COMMON_DATE_FORMAT
} from '@/utils/dateUtils'
import { DateBus } from '@/buses'
import { mapGetters } from 'vuex'

const DateRange = {
  name: 'date-range',

  watch: {
    from: {
      handler(val) {
        this.dateRangeForm.from = dateToDay(this.from)
      },
      immediate: true
    },
    to: {
      handler(val) {
        this.dateRangeForm.to = dateToDay(this.to)
      },
      immediate: true
    }
  },

  props: {
    // dayRange is the MAX valid day range
    // you can pass in a float like 1.5
    yearRange: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      dateFormat: COMMON_DATE_FORMAT,
      datePicker,
      dateRangeForm: {
        msg: '',
        from: '',
        to: ''
      },
      loading: false,
      success: false
    }
  },

  computed: {
    ...mapGetters({
      from: 'from',
      to: 'to'
    }),
    isDateRangeValid () {
      const {
        dateRangeForm: {
          from,
          to
        } = {}
      } = this
      const withinYearRange = dateDifference(from, to, 'years', true) <= this.yearRange
      // From is not greater than To field
      const fromBeforeTo = isSameOrBefore(from, to)

      return withinYearRange && fromBeforeTo
    },
    didRangeChange () {
      return !isSame(this.dateRangeForm.from, this.from) ||
      !isSame(this.dateRangeForm.to, this.to)
    }
  },

  methods: {
    handleNewDateRange () {
      if (!this.isDateRangeValid) return

      this.loading = true

      this.$store.dispatch('updateDateRange', {
        from: this.dateRangeForm.from,
        to: this.dateRangeForm.to
      }).then(() => {
        this.$emit('submit')
        DateBus.$emit('new-date-range')
        setTimeout(() => {
          this.loading = false
          this.success = true
        }, 450)
      }).catch(() => {
        this.loading = false
        this.success = false
      })
    }
  }
}

export default DateRange
</script>

<style lang="sass" src="./DateRange.sass" />

<template>
  <div class="drawer">
    <div @click="drawerToggle(id)">
      <slot name="trigger"><v-icon class="drawer__trigger">menu</v-icon></slot>
    </div>
    <div class="drawer__backdrop" v-if="drawerOpen === id" v-close-drawer></div>
    <transition name="drawer__slide">
      <div v-if="drawerOpen === id" class="drawer__drawer">
        <div class="drawer__close" v-close-drawer>
          <v-icon color="grey lighten-5">close</v-icon>
        </div>
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
  let Drawer = {
    props: {
      id: {
        type: String,
        required: true
      }
    },
    methods: {
      drawerToggle(toggle) { return this.$store.dispatch('updateDrawerOpen', toggle) }
    },
    computed: {
      drawerOpen() { return this.$store.state.ui.drawerOpen }
    }
  }

  export default Drawer
</script>

<style lang="sass" src="./Drawer.sass"></style>
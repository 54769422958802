// false == desc
// true == asc

/**
  schema: array of objects
  {
    label: <display value>,
    pointer: <reference to key in this.data>
  }

  data: array of data objects (can literally be in any shape)
*/

export default {
  props: {
    schema: {
      type: Array,
      required: true,
      default: () => ([])
    },
    data: {
      type: Array,
      required: true,
      default: () => ([])
    },
    sortColumn: String,
    sortDirection: Boolean
  },
  watch: {
    data(newData) {
      this.tableData = newData
    }
  },
  data() {
    return {
      tableData: this.data,
      tableSchema: this.schema
    }
  },
  created() {
    this.tableSchema = this.tableSchema.map((item, index) => {
      item.asc = (item.pointer === this.sortColumn)
        ? this.sortDirection
        : null

      return item
    })
  },
  methods: {
    cellClickHandler(e) {
      this.$emit('cell-click', e)
    },
    sortByColumn(column) {
      let toggle = null

      this.tableSchema = this.tableSchema.map((item, index) => {
        if (item.pointer === column) {
          item.asc = toggle = !item.asc
        } else {
          item.asc = null
        }

        return item
      })

      this.tableData = this.data.sort((a, b) => {
        const aColumn = a[column]
        const bColumn = b[column]

        if (aColumn === bColumn) return 0

        return (aColumn > bColumn)
          ? (toggle) ? 1 : -1
          : (toggle) ? -1 : 1
      }).slice()
    },
    createHeaders(el) {
      const th = this.tableSchema.map(header => {
        // const thIcon = el('v-icon', 'swap_vert')
        
        return el('th', {
          on: { click: this.sortByColumn.bind(this, header.pointer) },
          class: { 
            'sortable-table__th': true,
            'sortable-table__th--asc': header.asc === true,
            'sortable-table__th--desc': header.asc === false
          }
        }, [header.label]) // TODO: put back an icon
      })

      return el('tr', [th])
    },
    createBody(el, row, schema) {
      const td = schema.map((schemaItem, index) => {
        // value can be a string or Vnode
        const value = (typeof schemaItem.format == "function")
          ? schemaItem.format(row[schemaItem.pointer], el) // passing createElement to format fn
          : row[schemaItem.pointer]

        return el('td', {
          on: { click: this.cellClickHandler.bind(this, {value, cell: schemaItem.pointer, data: row}) },
          class: {
            [`sortable-table__td__${schemaItem.pointer}`]: true,
            [`sortable-table__td__${schemaItem.pointer}--content`]: value
          }
        }, [value])
      })

      return el('tr', {
        class: {
          'sortable-table__tr': true
        }
      }, [td])
    }
  },
  render(el) {
    let children = []

    const tableBody = this.tableData.map(item => this.createBody(el, item, this.tableSchema))

    children.push(el('thead', [this.createHeaders(el)]))
    children.push(el('tbody', [tableBody]))

    return el('table', {
      class: {
        'sortable-table': true
      }
    }, children) 
  }
}
export const firstCharUpper = (word = '') => `${word.charAt(0).toUpperCase()}${word.substring(1)}`

export const normalizeAwsNameValuePair = (nameVals = []) => {
  return nameVals.reduce((outcome, nameVal = {}) => {
    let { Name, Value } = nameVal

    outcome[Name] = Value

    return outcome
  }, {})
}

export const convertDelimiterToPascal = (str, delim = '-') => {
  const strArray = str.split(delim)
  return strArray.map(s => {
    return `${s.substring(0, 1).toUpperCase()}${s.substring(1)}`
  }).join('')
}

export const saveAs = (blob, filename) => {
  if (typeof navigator.msSaveOrOpenBlob !== 'undefined') {
    return navigator.msSaveOrOpenBlob(blob, filename)
  } else if (typeof navigator.msSaveBlob !== 'undefined') {
    return navigator.msSaveBlob(blob, filename)
  } else {
    var elem = window.document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = filename
    elem.style = 'display:none;opacity:0;color:transparent;'
    
    ;(document.body || document.documentElement).appendChild(elem)
    if (typeof elem.click === 'function') {
      elem.click()
    } else {
      elem.target = '_blank'
      elem.dispatchEvent(new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      }))
    }
    URL.revokeObjectURL(elem.href)
  }
}

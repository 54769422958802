import { instance } from './utils/authRequest'
import { mapGauge } from '@/api/gauges'
import { mapGaugeLog } from '@/api/gaugeLogs'

const PUBLIC_PREFIX = '/public'

const noAuthRequest = instance()

export default {
  getSummary() {
    return noAuthRequest({ url: `${PUBLIC_PREFIX}/summary` }).then((response) => {
      let { data } = response

      return data
    })
  },

  getGauges() {
    return noAuthRequest({ url: `${PUBLIC_PREFIX}/gauges` }).then((response) => {
      let { data = [] } = response

      return data.map(mapGauge)
    })
  },

  getGaugeLogs({ uid, gid }) {
    if (!uid || !gid) throw new Error('[publicApi] getGaugeLogs missing required parameter')

    return noAuthRequest({ url: `${PUBLIC_PREFIX}/gauges/${uid}/${gid}` }).then((response) => {
      let { 
        data: {
          logs = []
        } = {}
      } = response

      return logs.map(mapGaugeLog)
    })
  },

  getReport(rid) {
    if (!rid) throw new Error('[publicApi] getReport: missing required parameter')

    return noAuthRequest({ url: `${PUBLIC_PREFIX}/reports/${rid}` }).then((response) => {
      let { data = {} } = response

      console.log('get public report: ', response)
      return data
    })
  }
}
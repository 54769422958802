import axios from 'axios'

let onLine = true
let lastOnLineStatus = true

function testConnection() {
  // if browser suppports window.navigator, use that to determine if online
  // TODO: determine if this use case is necessary considering the limitations of "online/offline" events
  // https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine/onLine
  if (typeof window.navigator === "object" && typeof window.navigator.onLine === "boolean") {
    return Promise.resolve(window.navigator.onLine)
  } else {
    // else, send an actual request to see if internet is accessable
    return axios.get(`/?bust=${Date()}`).then(response => true).catch(error => false)
  }
}

// manually trigger events
function triggerEvent(type) {
  var event = document.createEvent('HTMLEvents')
  event.initEvent(type, true, true);
  event.eventName = type;
  (document.body || window).dispatchEvent(event);
}

// every 5 seconds, check the status of online vs offline and kick off respective events
window.setInterval(function () {
  testConnection().then(status => {
    onLine = status
    if (onLine !== lastOnLineStatus) {
      triggerEvent(onLine ? 'online' : 'offline')
      lastOnLineStatus = onLine
    }
  })
}, 5000) // 5 seconds, made up - can't find docs to suggest interval time

export default testConnection



// let onLine = true
// let lastOnLineStatus = true

// function testConnection() {
//   if (typeof navigator === "object" && typeof navigator.online === "boolean") {
//     onLine = navigator.onLine
//   } else {
//     var i = new Image();
//     i.onerror = function(){ onLine = false};
//     i.onload = function(){ onLine = true};
//     i.src = 'https://ssl.gstatic.com/gb/images/v1_76783e20.png?' + new Date().getTime();
//   }

//   return onLine
// }

// function triggerEvent(type) {
//   var event = document.createEvent('HTMLEvents')
//   event.initEvent(type, true, true);
//   event.eventName = type;
//   (document.body || window).dispatchEvent(event);
// }

// // note: this doesn't allow us to define a getter in Safari
// // navigator.__defineGetter__("onLine", () => {})

// window.setInterval(function () {
//   testConnection()
//   if (onLine !== lastOnLineStatus) {
//     triggerEvent(onLine ? 'online' : 'offline')
//     lastOnLineStatus = onLine
//   }
// }, 5000) // 5 seconds, made up - can't find docs to suggest interval time

// export default testConnection


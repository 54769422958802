import {
  RECEIVE_LOGS,
  MASSAGE_LOGS,
  ADD_LOG_GAUGE,
  PUBLIC_SUMMARY_UPDATE,
  PUBLIC_GAUGES_UPDATE,
  UI_ADD_LOADING_STATE,
  UI_REMOVE_LOADING_STATE
} from '../mutation-types'
import publicDataApi from '@/api/publicData'
import { decimalDoublePad } from '@/utils/numUtils'

const state = {
  gauges: [],
  publicSummary: {}
}

const getters = {
  getPublicGaugeById: (state, getters) => (gid) => {
    let gauge = state.gauges.find(gauge => gauge.gid === gid)

    return gauge || {}
  },
  getPublicGaugeCount: (state) => {
    let { publicSummary: { gauges } } = state

    return gauges
  },
  getPublicLogCount: (state) => {
    let { publicSummary: { logs } } = state

    return logs
  },
  getPublicTotalInches: (state) => {
    let { publicSummary: { inches } } = state

    return decimalDoublePad(inches)
  },
  getPublicUsersWithGauges: (state) => {
    let { publicSummary: { users } } = state

    return users
  }
}

const actions = {
  async getPublicSummary({ commit, dispatch }) {
    let response = await publicDataApi.getSummary()

    commit(PUBLIC_SUMMARY_UPDATE, response)
  },
  async getPublicGauges({ commit, dispatch }) {
    commit(UI_ADD_LOADING_STATE, 'publicGauges')

    try {
      // if already fetched, return
      if (state.gauges.length) return state.gauges

      let response = await publicDataApi.getGauges()
      
      commit(PUBLIC_GAUGES_UPDATE, response)

      return response
    } finally {
      commit(UI_REMOVE_LOADING_STATE, 'publicGauges')
    }
  },
  async getPublicGaugeLogs ({ commit, dispatch, getters }, gid) {
    const { uid } = getters.getPublicGaugeById(gid)

    if (!uid) {
      throw new Error(`${gid} is not a public gauge`)
    }

    // TODO: implement solution that gets logs out of gaugeLogs store
    // before need to fetch
    // if (state.gauges.includes(gid)) {
    //   return state.all.filter(log => { return log.gid === gid })
    // }

    const response = await publicDataApi.getGaugeLogs({ uid, gid })

    commit(RECEIVE_LOGS, response)
    commit(MASSAGE_LOGS, gid)
    commit(ADD_LOG_GAUGE, gid)

    return response
  }
}

const mutations = {
  [PUBLIC_SUMMARY_UPDATE](state, payload) {
    state.publicSummary = payload
  },
  [PUBLIC_GAUGES_UPDATE](state, gauges) {
    state.gauges = gauges
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

// NOT SSR COMPATIBLE

import shortid from 'shortid'

const CLASS_NAME = 'lock-on-scroll'
let timeout = {}


function handleScroll(el, timeoutId) {
  let scrollDelay = 650 // miliseconds
  let ticking = false

  if (!ticking) {
    window.requestAnimationFrame(function() {
      window.clearTimeout(timeout[timeoutId])

      timeout[timeoutId] = setTimeout(() => {
        el.classList.remove(CLASS_NAME)
      }, scrollDelay)

      el.classList.add(CLASS_NAME)

      ticking = false
    });
  }

  ticking = true
}

function directive(el, binding) {
  let timeoutId = shortid.generate()

  window.addEventListener('scroll', handleScroll.bind(null, el, timeoutId))
}

let LockOnScroll = {
  bind: directive,
  unbind(el) {
    el.classList.remove(CLASS_NAME)
    window.removeEventListener('scroll', handleScroll.bind(null, el))
  }
}

export default LockOnScroll

<template>
  <div class="floating-quote">
    <p>
      <slot>
        This is default content. This will go away if the component has content inside the tag.
      </slot>
    </p>
    <footer class="floating-quote__footer">
      {{author}}
    </footer>
  </div>
</template>

<script>
  let FloatingQuote = {
    components: {},
    props: ['author'],
    data() {
      return {}
    }
  }

  export default FloatingQuote
</script>

<style lang="sass" src="./FloatingQuote.sass"></style>

// modules/offline
export const UPDATE_OFFLINE = 'UPDATE_OFFLINE'
export const UPDATE_SYNC_QUEUE = 'UPDATE_SYNC_QUEUE'
export const SYNC_RUNNING = 'SYNC_RUNNING'
export const LOCAL_SYNC_RUNNING = 'LOCAL_SYNC_RUNNING'
export const FETCH_SYNC_QUEUE = 'FETCH_SYNC_QUEUE'
export const DELETE_SYNC_QUEUE = 'DELETE_SYNC_QUEUE'


// modules/user
export const USER_REGISTER = 'USER_REGISTER'
export const USER_CONFIRM = 'USER_CONFIRM'
export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_EMAIL_CONF = 'USER_EMAIL_CONF'
export const USER_EMAIL_NOT_CONF = 'USER_EMAIL_NOT_CONF'

export const USER_SET_IDENTITYID = 'USER_SET_IDENTITYID'
export const USER_SET_USERID = 'USER_SET_USERID'
export const USER_SET_EMAIL = 'USER_SET_EMAIL'
export const USER_SET_NAME = 'USER_SET_NAME'

export const USER_SET_USER = 'USER_SET_USER'
export const USER_UPDATE_ATTRS = 'USER_UPDATE_ATTRS'


//modules/gauges
export const RECEIVE_PUBLIC_GAUGES = 'RECEIVE_PUBLIC_GAUGES'
export const RECEIVE_GAUGES = 'RECEIVE_GAUGES'
export const ADD_GAUGE = 'ADD_GAUGE'
export const UPDATE_GAUGE = 'UPDATE_GAUGE'
export const DELETE_GAUGE = 'DELETE_GAUGE'
export const GAUGES_LOADING = 'GAUGES_LOADING'
export const GAUGES_SORT = 'GAUGES_SORT'



//modules/gaugeLogs
export const RECEIVE_LOGS = 'RECEIVE_LOGS'
export const ADD_LOG = 'ADD_LOG'
export const UPDATE_LOG = 'UPDATE_LOG'
export const DELETE_LOG = 'DELETE_LOG'
export const MASSAGE_LOGS = 'MASSAGE_LOGS'
export const ADD_LOG_GAUGE = 'ADD_LOG_GAUGE'
export const ADD_LOG_FROM_QUEUE = 'ADD_LOG_FROM_QUEUE'
export const LOGS_LOADING = 'LOGS_LOADING'

// modules/publicData
export const PUBLIC_SUMMARY_UPDATE = 'PUBLIC_SUMMARY_UPDATE'
export const PUBLIC_GAUGES_UPDATE = 'PUBLIC_GAUGES_UPDATE'

//modules/ui
export const DRAWER_TOGGLE = 'DRAWER_TOGGLE'
export const MODAL_OPEN = 'MODAL_OPEN'
export const MODAL_CLOSE = 'MODAL_CLOSE'
export const TOGGLE_APP_WRAPPER = 'TOGGLE_APP_WRAPPER'
export const UPDATE_GLOBAL_LOADING = 'UPDATE_GLOBAL_LOADING'
export const UI_ADD_LOADING_STATE = 'UI_ADD_LOADING_STATE'
export const UI_REMOVE_LOADING_STATE = 'UI_REMOVE_LOADING_STATE'

export const DATE_RANGE_UPDATE = 'DATE_RANGE_UPDATE'

// helpers
export const HELPERS_MERGE = 'HELPERS_MERGE'
export const HELPERS_ADD = 'HELPERS_ADD'
export const HELPERS_UPDATE = 'HELPERS_UPDATE'
export const HELPERS_DELETE = 'HELPERS_DELETE'

// reports
export const REPORTS_SET = 'REPORTS_SET'
export const REPORTS_UPDATE = 'REPORTS_UPDATE'
export const REPORTS_DELETE = 'REPORTS_DELETE'
import store from '@/store'

function directive(el, binding) {
  el.onclick = () => {
    store.dispatch('updateDrawerOpen', false)
  }
}

let CloseDrawer = {
  bind: directive,
  inserted: directive,
  updated: directive
}

export default CloseDrawer

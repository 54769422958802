import Vue from 'vue'
import reportsApi from '@/api/reports'
import {
  REPORTS_SET,
  REPORTS_UPDATE,
  REPORTS_DELETE
} from '../mutation-types'
import uuid from 'uuid/v4'

const state = {
  items: []
}

const getters = {
  getReportById: (state) => (rid) => {
    return state.items.find(item => item.rid === rid)
  }
}

const actions = {
  async listReports({ commit, getters }) {
    if (!getters.getUserId) throw new Error('No user id')

    try {
      const response = await reportsApi.fetch(getters.getUserId)
      commit(REPORTS_SET, response)

      return response
    } catch(e) {
      console.error('[listReports] :', e)
      throw e
    }
  },

  async getReport({ commit, getters }, rid) {
    if (!getters.getUserId || !rid) throw new Error('No user id or report id')
    
    try {
      const response = await reportsApi.get({
        uid: getters.getUserId,
        rid
      })

      commit(REPORTS_UPDATE, response)

      return response
    } catch(e) {
      console.error('[getReport] :', e)
      throw e
    }
  },

  async createReport({ commit, getters }, payload) {
    try {
      const reportPayload = {
        rid: uuid(),
        uid: getters.getUserId,
        ...payload
      }

      // TODO: consolidate with updateReport
      for (let key in reportPayload) {
        if (reportPayload[key] === '') {
          reportPayload[key] = null
        }
      }
      
      const response = await reportsApi.create(reportPayload)
      commit(REPORTS_UPDATE, response)

      return response
    } catch(e) {
      console.error('[createReport] :', e)
      throw e
    }
  },

  async updateReport({ commit }, payload) {
    try {
      const reportPayload = { ...payload }

      for (let key in reportPayload) {
        if (reportPayload[key] === '') {
          reportPayload[key] = null
        }
      }
      
      const response = await reportsApi.update(reportPayload)
      commit(REPORTS_UPDATE, response)

      return response
    } catch(e) {
      console.error('[updateReport] :', e)
      throw e
    }
  },

  async deleteReport({ commit, getters }, rid) {  
    if (!getters.getUserId || !rid) throw new Error('No user id or report id')

    try {      
      await reportsApi.delete({
        uid: getters.getUserId,
        rid
      })

      commit(REPORTS_DELETE, rid)

      return 'SUCCESS'
    } catch(e) {
      console.error('[deleteReport] :', e)
      throw e
    }
  }
}

export const mutations = {
  [REPORTS_SET] (state, payload) {
    state.items = payload
  },
  [REPORTS_UPDATE] (state, payload) {
    const { rid } = payload
    const index = state.items.findIndex(item => item.rid === rid)

    if (index > -1) {
      Vue.set(state.items, index, {
        ...state.items[index],
        ...payload
      })
    } else {
      state.items.push(payload)
    }
  },
  [REPORTS_DELETE] (state, rid) {
    const index = state.items.findIndex(item => item.rid === rid)

    if (index > -1) {
      
      Vue.delete(state.items, index)
    }
  }
}

export default { state, getters, actions, mutations }

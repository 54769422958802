<template>
  <div id="log_list" class="log-list">
    <div class="log-list__wrapper">
      <div class="log-list__border"></div>
      <div class="log-list__item-wrapper">
        <template v-for="(log, index) in logList">
          <log-list-item 
            :open="index === 0"
            :log="log"
            :key="log.lid"
            :base-level="(index === logList.length -1) && log.isReset"
            :class="getLogItemClass(log.logDateTime)"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LogListItem from './LogListItem/LogListItem'
import { dateToDay, isBetween } from '@/utils/dateUtils'

export default {
  components: {
    LogListItem
  },
  name: 'log_list',
  props: {
    logs: {
      type: Array,
      default: () => { return [] },
      required: true
    },
    gauge: { 
      type: Object,
      default: () => { return {} }
    }
  },
  computed: {
    ...mapGetters([
      'from',
      'to',
      'get',
      'getGaugeById'
    ]),
    logList() {
      return this.logs.map((log) => {
        const gaugeName = this.gauge.title || this.getGaugeById(log.gid).title
        let newLog = Object.assign({ gaugeName }, log)

        return newLog
      })
    }
  },
  methods: {
    getLogItemClass(date) {
      return {
        'log-list__item': true,
        'log-list__item--disabled': !isBetween(date, this.from, this.to)
      }
    }
  }  
}
</script>

<style lang="sass" src="./LogList.sass"></style>

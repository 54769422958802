var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('page-section',{staticClass:"date-range secondary",attrs:{"centered":""}},[_c('div',{staticClass:"date-range__header mb-1"},[_vm._v("Set precipitation date range")]),_c('form',{attrs:{"novalidate":""}},[_c('div',{staticClass:"date-range__inner"},[_c('date-menu',{attrs:{"name":"from","label":"From","hide-actions":"","dense":"","picker-props":{
          max: _vm.datePicker(new Date())
        },"text-field-props":{
          hideDetails: true,
          outlined: true,
          singleLine: true,
          appendIcon: ''
        }},model:{value:(_vm.dateRangeForm.from),callback:function ($$v) {_vm.$set(_vm.dateRangeForm, "from", $$v)},expression:"dateRangeForm.from"}}),_c('div',{staticClass:"date-range__in-between"}),_c('date-menu',{attrs:{"name":"to","label":"To","hide-actions":"","dense":"","menu-props":{
          nudgeLeft: _vm.$vuetify.breakpoint.smAndDown ? 70 : 0
        },"picker-props":{
          max: _vm.datePicker(new Date())
        },"text-field-props":{
          hideDetails: true,
          outlined: true,
          singleLine: true,
          appendIcon: ''
        }},model:{value:(_vm.dateRangeForm.to),callback:function ($$v) {_vm.$set(_vm.dateRangeForm, "to", $$v)},expression:"dateRangeForm.to"}}),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"error","type":"submit","disabled":!_vm.isDateRangeValid || !_vm.didRangeChange,"depressed":"","rounded":"","small":"","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.handleNewDateRange.apply(null, arguments)}}},[(_vm.didRangeChange || !_vm.success)?[_vm._v(" "+_vm._s((_vm.$vuetify.breakpoint.mdAndUp) ? 'Apply' : 'Go')+" ")]:(_vm.success && !_vm.loading)?[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("check")])]:_vm._e()],2),_c('tooltip',{staticClass:"hidden-sm-and-down"},[_vm._v(" Two years max range. "),_c('br'),_c('br'),_vm._v(" Gauge stats such as cumulative values and observation count are re-calculated by date range. ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
import CalsLocalStore from './cals.storage.js'

const DO_LOGS = false

const LOCAL_KEY_GAUGES_SORT = 'mrl-gauges-sort'
const LOCAL_KEY_GAUGES = 'mrl-offline-gauges'
const LOCAL_KEY_GAUGELOGS = 'mrl-offline-gaugelogs'
const LOCAL_KEY_SYNC_QUEUE = 'mrl-offline-sync-queue'
const USER_POOL_ID_STORAGE_KEY = 'mrl-user-pool-id'
const USER_IDENTITY_STORAGE_KEY = 'mrl-user-identity-id'
const USER_AUTH_VALID_KEY = 'mrl-user-offline-validated'
const USER_ATTRS = 'mrl-user-attrs'
// export const USER_AUTH_TOKEN_KEY = 'mrl-auth-token'

// for testing
if (navigator.userAgent.indexOf('PhantomJS') > -1) {
  window.localStorage.clear()
}

export default {
    //   todos: JSON.parse(window.localStorage.getItem(STORAGE_KEY) || '[]'),
  user : {
    getUserIdentityId() {
        return CalsLocalStore.getFromStorage(USER_IDENTITY_STORAGE_KEY, false, DO_LOGS)
    },
    setUserIdentityId(identityId) {
        CalsLocalStore.saveToStorage(USER_IDENTITY_STORAGE_KEY, identityId, false, DO_LOGS)
    },
    getUserPoolId() {
        return CalsLocalStore.getFromStorage(USER_POOL_ID_STORAGE_KEY, false, DO_LOGS)
    },
    setUserPoolId(userId) {
        CalsLocalStore.saveToStorage(USER_POOL_ID_STORAGE_KEY, userId, false, DO_LOGS)
    },
    getUserAuthValid() {
        return CalsLocalStore.getFromStorage(USER_AUTH_VALID_KEY, false, DO_LOGS)
    },
    setUserAuthValid(bool) {
        CalsLocalStore.saveToStorage(USER_AUTH_VALID_KEY, bool, false, DO_LOGS)
    },
    getUserAttrs() {
        return CalsLocalStore.getFromStorage(USER_ATTRS, true, DO_LOGS)
    },
    setUserAttrs(attrs) {
        CalsLocalStore.saveToStorage(USER_ATTRS, attrs, true, DO_LOGS)
    }
  },

  gauges : {
    getSort (doLogs) {
      return CalsLocalStore.getFromStorage(LOCAL_KEY_GAUGES_SORT, true, doLogs || DO_LOGS)
    },
    setSort (value, doLogs) {
      CalsLocalStore.saveToStorage(LOCAL_KEY_GAUGES_SORT, value, true, doLogs || DO_LOGS)
      return CalsLocalStore.getFromStorage(LOCAL_KEY_GAUGES_SORT, true, doLogs || DO_LOGS)
    },
    get (doLogs) {
      return CalsLocalStore.getFromStorage(LOCAL_KEY_GAUGES, true, doLogs || DO_LOGS)
    },
    set (gauges, doLogs) {
      CalsLocalStore.saveToStorage(LOCAL_KEY_GAUGES, gauges, true, doLogs || DO_LOGS)
      console.log("[localstore] gauges data saved: ", gauges)
    },
    add (gauges, doLogs) {
      let current = this.get()
      // info("current: ",current)
      if (!current || typeof current.push !== 'function') {
          current = []
      }
      // info("compared to new: ",gauges)
      let checkForDup = function (item) {
          let found = -1
          for (let i = 0; i < current.length; i++){
              if (item.gid == current[i].gid) { //eslint-disable-line eqeqeq
                  found = i
                  break
              }
          }
          return found
      }
      for (let i = 0; i < gauges.length; i++) {
          let gauge = gauges[i],
              foundAt = checkForDup(gauge)
          if (foundAt >= 0) {
              console.log("[localstore] gauge found, replace at index: ", foundAt)
              current[foundAt] = gauge
          } else {
              // console.log("[localstore] log not found, push: ", gauge)
              current.push(gauge)
          }
      }
      this.set(current)
    }
  },

  logs: {
    get (doLogs) {
        return CalsLocalStore.getFromStorage(LOCAL_KEY_GAUGELOGS, true, doLogs || DO_LOGS)
    },
    set (logs, doLogs) {
        CalsLocalStore.saveToStorage(LOCAL_KEY_GAUGELOGS, logs, true, doLogs || DO_LOGS)
    },
    add (logs, doLogs) {
        let current = this.get()
        // info("current: ",current)
        if (!current || typeof current.push !== 'function') {
            current = []
        }
        // info("compared to new: ",logs)
        let checkForDup = function (item) {
            let found = -1
            for (let i = 0; i < current.length; i++){
                if (item.gid == current[i].gid && item.lid == current[i].lid) { //eslint-disable-line eqeqeq
                    found = i
                    break
                }
            }
            return found
        }
        for (let i = 0; i < logs.length; i++) {
            let log = logs[i],
                foundAt = checkForDup(log)
            if (foundAt >= 0) {
                console.log("[localstore] log found, replace at index: ", foundAt)
                current[foundAt] = log
            } else {
                // console.log("[localstore] log not found, push: ", log)
                current.push(log)
            }
        }
        this.set(current)
    },
    getByGauge (gid) {
        let current = this.get()
        if (current && gid) {
            return current.filter(log => { return log.gid === gid })
        } else {
            return []
        }
        
    }
  },

  syncQueue: {
    get(uid, doLogs) {
      return CalsLocalStore.getFromStorage(`${LOCAL_KEY_SYNC_QUEUE}-${uid}`, true, doLogs || DO_LOGS) || []
    },
    set(uid, items, doLogs) {
      CalsLocalStore.saveToStorage(`${LOCAL_KEY_SYNC_QUEUE}-${uid}`, items, true, doLogs || DO_LOGS)
      return this.get(uid)
    },
    post(uid, item) {
      let currentItems = this.get(uid)
      let exists = currentItems.find(i => i.queueId === item.queueId)

      if (!exists) {
        currentItems.push(item)
        this.set(uid, currentItems)
      }

      return item
    },
    delete(uid, queueId) {
      if (!queueId) throw new Error('The queue item does not have an id')

      let currentItems = this.get(uid)
      let itemIndex = currentItems.findIndex(i => i.queueId === queueId)

      // short hand for getting boolean from "findIndex" returning -1
      if (!!~itemIndex) {
        currentItems.splice(itemIndex, 1)
        this.set(uid, currentItems)
      }

      return currentItems
    }
  }

}

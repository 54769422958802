/**
 * using axios
 * https://github.com/mzabriskie/axios
 */
import AwsConfig from "./lib/aws/config"
import offlineApi from './localstorage'
import authRequest from './utils/authRequest'
import { uploadImage } from './imageUploader'

/** Serverless API
 * endpoints:
  GET - https://1zafopg125.execute-api.us-west-2.amazonaws.com/dev/log
 */

export function mapGaugeLog(item) {
  let image = (item.image && typeof item.image === "string") ? item.image : ""; // if there's a binary, just skip that map
  if (image && image.indexOf("user/") === 0) {
    image = AwsConfig.userImageBaseUrl + image
  } else if (image && image.indexOf("data:image/") === 0) {//base64
    console.info("should we have a base64 image? ...we do")
    // image = item.image //this is not needed... just for reference
  } 

  return {
    ...item,
    uid: item.uid || item.userId,
    image
  }
}


export default {
  getLogs (gid) {
    if (!gid) throw new Error('missing param in getLogs api call')

    return authRequest({ url: `/logs/${gid}`}).then(response => {
      // console.log("[api] GET logs resp:",response)

      let items = (Array.isArray(response.data)) ? response.data.map(mapGaugeLog) : []
      //offline - save to localstorage
      offlineApi.logs.add(items, false) //bool=doLogs

      return items
    }).catch(error => { 
      throw new Error("[api] GET logs ERROR:",error)
    })
  },

  addLog(data) {
    if (!data || !data.gid || !data.userId) throw new Error('missing param in addLog api call')
    // we're using the userId for the image path in s3 storage...
    let uid = data.uid || data.userId,
        imageFile = data.image

    let payload = mapGaugeLog(data)

    console.log("[api] addGauge imageFile:", imageFile)

    if (typeof imageFile === "object" && imageFile.type && imageFile.size) {
      payload.imageType = imageFile.type
      payload.imageSize = imageFile.size
    }

    console.log("[api] addLog", payload)

    let response = authRequest({ url: `/logs/${data.gid}`, method: 'post', data: payload }).then(response => {
        console.log("[api] addLog response", response)

        if (response.data.s3psk && typeof imageFile === "object" && imageFile.type && imageFile.size) {
          console.log("uploading the image...")
          return uploadImage({
            signed_request : response.data.s3psk,
            imageFile : imageFile,
            imageType : imageFile.type
          }).catch(err => {
            console.warn("Caught an error in uploadImage Promise")
          }).then(resp => {
            return response.data
          })
        } else {
          console.log("there's no image to add...")
          return response.data
        }
    }).catch(error => { 
      throw new Error(error) 
    })

    return response.then(response => {
      let mappedResponse = mapGaugeLog(response)
      offlineApi.logs.add([mappedResponse], false)

      return mappedResponse
    })
  },

  updateLog(data) {
    if (!data || !data.lid || !data.gid) throw new Error('missing param in updateLog api call')

    // make actual api call
    let lid = data.lid,
        gid = data.gid,
        uid = data.uid || data.usedId,
        imageFile = data.newImage
    // delete data.cb
    
    let payload = mapGaugeLog(data)

    if (typeof imageFile === "object" && imageFile.type && imageFile.size) {
      payload.imageType = imageFile.type
      payload.imageSize = imageFile.size
      console.log("[api] updateLog: adding new image...")
    }

    console.log("[api] updateLog payload: %o", payload)

    return authRequest({ url: `/logs/${gid}/${lid}`, method: 'put', data: payload }).then(function (response) {
      console.log("[api] updateLog response",response)

      if (response && response.status && response.status === 200) {
        //response will have ALL_NEW fields, not just the fields that changed

        if (response.data.s3psk && typeof imageFile === "object" && imageFile.type && imageFile.size) {
            console.log("uploading the image...")
            return uploadImage({
              signed_request : response.data.s3psk,
              imageFile : imageFile,
              imageType : imageFile.type

            }).catch(err => {
              console.warn("Caught an error in uploadImage Promise")
            }).then(resp => {
              // delete response.data.s3psk
              return mapGaugeLog(response.data.Attributes || response.data)
            })
          } else {
            console.log("there's no image to add...")
            return mapGaugeLog(response.data.Attributes || response.data)
          }

        return mapGaugeLog(response.data.Attributes || response.data)
      } else {
        console.warn("[api] PUT log Error: no response or status!200")
        throw new Error(response);
      }
      
    }).catch(function (error) {
      throw new Error(error)
    })
  },

  deleteLog(data) {
    if (!data || !data.lid || !data.gid) throw new Error('missing param in deleteLog api call')

    // make actual api call
    let lid = data.lid,
        gid = data.gid
    delete data.cb
    console.log("[api] deleteLog data: %o", data)
    
    return authRequest({ url: `/logs/${gid}/${lid}`, method: 'delete', data }).then(function (response) {
      console.log("[api] deleteLog response",response)

      if (response && response.status && response.status === 200) {
        //response will only have the fields that changed
        return response.data//mapGaugeItem(response.data)
      } else {
        throw new Error("[api] DELETE log Error: no response or status!200", response);
      }
      
    }).catch(function (error) {
      throw new Error(error)
    })
  }

}

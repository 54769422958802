<template>
  <page-section centered>
    <h2>Get Started</h2>
    <p>
      Tracking your precipitation observations to support better decisions is only a click away.
    </p>
    <template slot="actions">
      <v-btn depressed color="accent" rounded v-if="isAuthenticated" to="/dashboard" large>Go to my Gauges</v-btn>
      <v-btn depressed color="accent" rounded v-else :to="{ name: 'signup' }" large>Sign Up</v-btn>
    </template>
  </page-section>
</template>

<script>
  import { mapGetters } from 'vuex'

  let GetStarted = {
    components: {},
    data () {
      return {}
    },
    computed: {
      ...mapGetters(['isAuthenticated'])
    }
  }

  export default GetStarted
</script>

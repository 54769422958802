<template>
  <div class="dialog-manager">
    <component 
      v-for="dialog in dialogs"
      :key="dialog.type"
      :is="dialog.component"
      v-bind="dialog.props"
      @click:outside="" />
  </div>
</template>

<script>  
  import { uniq } from 'lodash'

  export default {
    name: 'dialog-manager',

    created() {
      this.$dialogBus.$on('open-dialog', this.openDialog)
      this.$dialogBus.$on('close-dialog', this.closeDialog)
    },

    data() {
      return {
        dialogs: []
      }
    },

    methods: {
      openDialog(payload) {
        const {
          type,
          props = {}
        } = payload || {}

        if (!type) throw new Error('openDialog listener: payload not correct shape')

        this.dialogs = uniq(this.dialogs.concat({
          type,
          component: () => import(`@/components/_dialogs/${type}/${type}`),
          props
        }))
      },
      closeDialog(dialog) {
        this.dialogs = this.dialogs.filter(v => v.type !== dialog)
      }
    }
  }
</script>

<style lang="sass">
  // .dialog
  //   .card__title
  //     padding-bottom: 0
</style>

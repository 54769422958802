<template>
  <v-container fluid grid-list-lg id="signup" class="signup pt-0">
    <page-section header>
      <h1>Welcome</h1>
      <p>You're on your way to tracking precip observations to support better decisions.</p>
    </page-section>

    <page-section centered theme="white">
      <div class="form__wrapper">
        <transition name="fade" mode="out-in">
          <div class="form__errors" v-if="error">
            {{ error }}
          </div>
        </transition>
        
        <form @submit.prevent="formSubmit(signup)" class="form" novalidate>
          <v-text-field
            v-model="form.name"
            v-validate="'required'"
            label="Name"
            required
            outlined
            single-line
            data-vv-name="name"
            name="name"
            :error-messages="errors.collect('name')">
          </v-text-field>
          <v-text-field
            v-model="form.email"
            v-validate="'required|email'"
            label="Email Address"
            required
            outlined
            single-line
            data-vv-name="email"
            name="email"
            data-vv-delay="700"
            :error-messages="errors.collect('email')">
          </v-text-field>
          <v-text-field
            v-model="form.password"
            :type="showPassword ? 'text' : 'password'"
            v-validate="'required|passwordPolicy'"
            label="Password"
            required
            outlined
            single-line
            :hint="passwordHint"
            data-vv-name="password"
            name="password"
            :append-icon="showPassword ? 'visibility_off' : 'visibility'"
            @click:append="showPassword = !showPassword"
            :error-messages="errors.collect('password')">
          </v-text-field>

          <div class="form__actions">
            <v-btn type="submit" color="accent" depressed rounded :loading="loading" large>Sign Up</v-btn>
            <span>Wait a minute! <router-link to="/login">I already have an account.</router-link></span>
            <span class="form__disclaimer">
              Read our <router-link to="/terms" class="">Terms</router-link> and <router-link to="/privacy" class="">Privacy Policy</router-link>.
            </span>
          </div>
        </form>
      </div>
      
      <p v-if="$route.query.redirect">
        You need to Sign Up first.
      </p>
    </page-section>
  </v-container>
</template>

<script>
  import FormValidation from '@/mixins/FormValidation'
  import { PASSWORD_POLICY } from '@/utils/constants'
  import { mapActions } from 'vuex'

  export default {
    name: 'signup',

    mixins: [FormValidation],

    data() {
      return {
        showPassword: false,
        form: {
          name: "",
          email: "",
          password: "",
          remember: false,
          hasAlerts: false,
          emailAlerts: false,
        },
        passwordHint: "8 characters, including uppercase, lowercase letters and numbers",
        passwordMatch: "",
        error: '',
        allowPhone: false,
        allowRemember: false,
        passPolicy: PASSWORD_POLICY
      }
    },
    
    methods: {
      ...mapActions(['signUp']),
      async signup() {
        try {
          var credentials = Object.assign({}, this.form, {
            email: this.form.email.toLowerCase().trim()
          })

          await this.signUp(credentials)
          
        } catch ({ message }) {
          console.error(message)

          this.loading = false
          this.error = message
          this.form.password = ''
          this.passwordMatch = ''
        }
      }
    }
  }
</script>

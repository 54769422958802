export const DEFAULT_DATE_RANGE = 364

export const ROLE_CONTRIBUTOR = 'contributor'
export const ROLE_EDITOR = 'editor'
export const ROLE_OWNER = 'owner'

export const ROLES = [ROLE_OWNER, ROLE_EDITOR, ROLE_CONTRIBUTOR]

export const ERROR_GAUGE_404 = "Sorry, the gauge you tried to access doesn't exist or has been classified as \"Totally Top Secret\"."
export const ERROR_GAUGE_INSUFFICIENT_PERMISSIONS = "Sorry, the owner of this gauge didn't give you the proper clearances for the feature you tried to access."

export const PASSWORD_POLICY = "Passwords must contain at least 8 characters, including uppercase, lowercase letters and numbers (special characters are recommended)"
export const NETWORK_ERROR_SMALL = "Uh oh! Technical difficulties. Please refresh and try again."
export const NETWORK_ERROR = "Oh no! Something went wrong on our end. Please refresh the page and try again."

export const NOTIFICATION_ALERT_COOKIE = 'mrl-notification-alert'
export const NOTIFICATION_BASE_THRESHOLD = 0.01

export const LOG_MULTI_ENTRY_COOKIE= 'mrl-log-multi-entry'

export const UNITS = [
  { value: 'in', text: 'Inches' },
  { value: 'mm', text: 'Millimeters' }
]

export const DEFAULT_ADDITIONAL_PUBLIC_PROPS = {
  gauges: ['description', 'image'],
  gaugeLogs: ['notes', 'image']
}

export const PAGE_TITLE_SUFFIX = "myRAINge Log :: University of Arizona"
export const PAGE_TITLES = {
  about: `About :: ${PAGE_TITLE_SUFFIX}`,
  account: `My Profile :: ${PAGE_TITLE_SUFFIX}`,
  addLog: `Add Observation :: ${PAGE_TITLE_SUFFIX}`,
  addLogSuccess: `Observation Added Successfully :: ${PAGE_TITLE_SUFFIX}`,
  addGauge: `Add a New Gauge :: ${PAGE_TITLE_SUFFIX}`,
  changePassword: `Change my password :: ${PAGE_TITLE_SUFFIX}`,
  contact: `Conact Us :: ${PAGE_TITLE_SUFFIX}`,
  dashboard: `My Dashboard :: ${PAGE_TITLE_SUFFIX}`,
  editGauge: `Edit Gauge :: ${PAGE_TITLE_SUFFIX}`,
  forgotPassword: `Forgot my password :: ${PAGE_TITLE_SUFFIX}`,
  gaugeDetail: `Gauge :: ${PAGE_TITLE_SUFFIX}`,
  gaugeEmpty: `Empty Gauge :: ${PAGE_TITLE_SUFFIX}`,
  gaugeReport: `Gauge Report :: ${PAGE_TITLE_SUFFIX}`,
  help: `Help :: ${PAGE_TITLE_SUFFIX}`,
  helpers: `My Helpers :: ${PAGE_TITLE_SUFFIX}`,
  helpersAdd: `Add Helper :: ${PAGE_TITLE_SUFFIX}`,
  home: `Track Precipitation :: ${PAGE_TITLE_SUFFIX}`,
  login: `Login :: ${PAGE_TITLE_SUFFIX}`,
  privacy: `Privacy :: ${PAGE_TITLE_SUFFIX}`,
  publicGaugeReport: `Public Gauge Report :: ${PAGE_TITLE_SUFFIX}`,
  publicMap: `Public Map and Real Results :: ${PAGE_TITLE_SUFFIX}`,
  solutions: `Solutions :: ${PAGE_TITLE_SUFFIX}`,
  support: `Support :: ${PAGE_TITLE_SUFFIX}`,
  signup: `Create an Account :: ${PAGE_TITLE_SUFFIX}`,
  terms: `Terms and Conditions :: ${PAGE_TITLE_SUFFIX}`,
}

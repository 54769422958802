import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      gaugeSort: state => state.gauges.sort
    }),
    ...mapGetters([
      'getAllGauges',
      'getMyGauges',
      'getSharedGauges',
      'getArchivedGauges',
      'getSharedUsers',
      'getGaugeById',
      'getAnyGaugeById',
      'getPrevNextGaugeGid',
      'getLastLogDateByGid',
      'getLogList'
    ])
  },

  methods: {
    ...mapActions([
      'getUserGaugeData',
      'exportGaugeData'
    ])
  }
}
<script>
import { Line } from 'vue-chartjs'

// if (window && window.Chart) {
//   window.Chart.plugins.register({
//     // need to manipulate tooltip visibility before its drawn (but after update)
//     beforeDraw (chartInstance, easing) {
//       // check and see if the plugin is active (its active if the option exists)
//       // console.log(chartInstance.tooltip._active)
//       // console.log(chartInstance)
//       const {
//         tooltip: {
//           _active = []
//         } = {},
//         config: {
//           options: {
//             tooltips: {
//               hideFor
//             } = {}
//           } = {},
//           data: {
//             datasets = []
//           } = {}
//         } = {}
//       } = chartInstance

//       if (hideFor && _active.length > 0) {
//         // only manipulate the tooltip if its just about to be drawn
//         const {
//           _datasetIndex
//         } = _active[0]

//         const {
//           label
//         } = datasets[_datasetIndex]

//         if (hideFor.indexOf(label) !== -1) {
//           chartInstance.tooltip._model.opacity = 0
//         }
//       }
//     }
//   })
// }

export default {
  extends: Line,

  props: {
    options: {
      type: Object,
      default: null
    },
    chartData: {
      type: Object,
      default: null
    }
  },

  mounted () {
    this.render()
  },

  methods: {
    render () {
      console.log('main rendering of chart...')
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>

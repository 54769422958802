<template>
  <header class="header">
    <ua-banner/>
    <sticky :disabled="showDateRange">
      <div class="header__nav-wrapper">
        <top-nav :isLoggedIn="isAuthenticated"/>
      </div>
    </sticky>
  </header>
</template>

<script>
  import DisplayManagerMixin from '@/mixins/DisplayManagerMixin'
  import UABanner from '@/components/UABanner/UABanner'
  import TopNav from '@/components/TopNav/TopNav'
  import { mapGetters } from 'vuex'

  let Header = {
    mixins: [DisplayManagerMixin],

    components: {
      'ua-banner': UABanner,
      'top-nav': TopNav
    },
    
    data() {
      return {}
    },
    
    computed: {
      ...mapGetters(['isAuthenticated'])
    }
  };

  export default Header;
</script>

<style lang="sass">
  .header
    position: relative

  .header__nav-wrapper
    background-color: $color-gray-lightest
</style>

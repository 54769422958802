<template>
  <div class="empty-gauge">
    <p>
      Choose this option if you emptied your rain gauge to a <strong><em>lower level</em></strong>. This new base level level will be used to calculate accumulation.
    </p>

    <v-row class="align-center">
      <v-col
        cols="12"
        sm="7"
      >
        <strong>Date of Reset</strong>
      </v-col>

      <v-col
        cols="12"
        sm="4"
        offset-sm="1"
      >
        <!-- TODO: if you select a date, it sets seconds and hour to midnight -->
        <date-menu
          v-model="innerDate"
          v-validate="'validDate'"
          :error-messages="errors.collect('date')"
          data-vv-name="date"
          name="date"
          label="Date"
          hide-actions
          :text-field-props="{
            hideDetails: true,
            appendIcon: 'event',
            outline: true,
            singleLine: true
          }"
        />
      </v-col>

      <v-col
        cols="12"
        sm="7"
      >
        <strong>Reset base level (in {{ humanMeasurements }})</strong>
      </v-col>

      <v-col
        cols="12"
        sm="4"
        offset-sm="1"
      >
        <v-text-field
          v-model="newBaseline"
          label="New Baseline"
          type="number"
          data-vv-name="newBaseLine"
          name="newBaseLine"
          hide-details
          outlined
          single-line
          @blur="onMeasurementBlur"
        />
      </v-col>
    </v-row>

    <div
      class="empty-gauge__actions"
      cols="12"
    >
      <v-btn
        depressed
        rounded
        large
        color="accent"
        :loading="loading"
        @click.prevent="formSubmit(saveEmptyRecord)"
      >
        {{ btnText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import numbro from 'numbro'
import {
  dateToUTC,
  dateToDay,
  dateToTime,
  addSeconds
} from '@/utils/dateUtils'
import convert from 'convert-units'
import FormValidation from '@/mixins/FormValidation'
import GaugePermissions from '@/views/Gauges/mixins/GaugePermissions'
import { mapGetters } from 'vuex'

export default {
  name: 'EmptyGauge',

  mixins: [GaugePermissions, FormValidation],

  props: {
    gid: {
      type: String,
      default: '',
      requried: true
    },
    date: {
      type: [String, Date],
      default: ''
    },
    measurement: {
      type: String,
      default: 'in'
    },
    btnText: {
      type: String,
      default: 'Confirm Reset'
    }
  },

  data () {
    return {
      dateToDay,
      innerDate: this.date,
      units: this.measurement,
      newBaseline: 0.00
    }
  },

  computed: {
    ...mapGetters(['getUserId', 'getUserFullName']),
    humanMeasurements () {
      const _dict = {
        in: 'inches',
        mm: 'millimeters'
      }

      return _dict[this.units]
    }
  },

  methods: {
    onMeasurementBlur (e) {
      var val = e.target.value || 0

      this.newBaseline = numbro(val).format('0.00')
    },
    saveEmptyRecord () {
      const resetLog = {
        userId: this.getUserId,
        gid: this.gid,
        logDateTime: dateToUTC(addSeconds(`${this.innerDate} ${dateToTime(this.date)}`, 1), ['MM/DD/YYYY HH:mm:ss']),
        units: this.units,
        reading: numbro(convert(this.newBaseline).from(this.units).to('in')).format('0.00'),
        isReset: true
      }

      if (!this.owner) {
        Object.assign(resetLog, {
          helperUid: this.getUserId,
          helperName: this.getUserFullName
        })
      }

      this.$store.dispatch('addGaugeLog', resetLog).then(response => {
        this.$store.dispatch('massageLogs', resetLog.gid)
        setTimeout(() => { this.loading = false }, 600)

        this.$router.push({ name: 'gaugeDetail', params: { gid: this.gid }})
      }).catch(error => {
        this.loading = false
        console.log(error)
      })
    }
  }
}
</script>

<style lang="sass" src="./EmptyGauge.sass"></style>

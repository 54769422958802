import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

import router from '@/router'

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_RAVEN_URL,
  environment: process.env.NODE_ENV,
  logErrors: true,
  // enabled: process.env.NODE_ENV !== 'development',
  enabled: true,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', /^\//] // not active yet
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

<template>
  <v-container fluid grid-list-lg id="confirm_email" class="confirm_email pt-0">
    <page-section header>
      <h1>Confirm your Email</h1>
      <p>Please enter the Verification Code we sent to your Email.</p>
    </page-section>
    <page-section centered>
      <div class="form__wrapper">
        <div class="form__success" v-if="success">{{success}}</div>
        <div class="form__errors" v-if="error">{{error}}</div>
        <form @submit.prevent="formSubmit(handleSubmit)" class="form" autocomplete="off" novalidate>
          <!-- disable autocomplete - chrome inserts into first password input & preceeding input -->
          <input style="width: 0;height: 0; visibility: hidden;position:absolute;left:0;top:0;" type="text" name="fakepasswordremembered"/>
          <input style="width: 0;height: 0; visibility: hidden;position:absolute;left:0;top:0;" type="password" name="fakepasswordremembered"/>

          <v-row>
            <v-col cols="12">
              <v-text-field
                v-if="confirmEmail"
                :value="confirmEmail"
                outlined
                single-line
                label="New Email" 
                name="confirmEmail"
                disabled
              />

              <v-text-field
                v-model="emailCode" 
                label="Email verification code" 
                name="emailCode"
                v-validate="'required'"
                data-vv-as="verification code"
                required
                outlined
                single-line
                aria-required="true"
                :value="confirmEmail"
                autocomplete="false"
                :error-messages="errors.collect('emailCode')"
              />

              <v-text-field
                type="password"
                v-model="password"
                v-validate="'required'"
                label="Password"
                required
                outlined
                single-line
                aria-required="true"
                name="password"
                hint="This action requires you to authenticate your account"
                :error-messages="errors.collect('password')"
              />
            </v-col>
          </v-row>

          <div class="form__actions">
            <v-btn type="submit" color="accent" depressed rounded :loading="loading" large>Verify</v-btn>
            <v-btn
              text
              large
              @click.native.prevent="resendCode"
              class="bare primary--text">
              RESEND CODE
            </v-btn>
          </div>
        </form>
      </div>
    </page-section>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormValidation from '@/mixins/FormValidation'

let Confirm = {
  name: 'confirm_email',

  props: {
    confirmId: String,
    confirmEmail: String, 
    confirmCode: String
  },

  mixins: [FormValidation],

  data () {
    return {
      emailCode: this.confirmCode,
      password: '',
      error: '',
      success: '',
      loading: false
    }
  },

  created() {
    if(!this.confirmId) {
      this.$router.replace('/login')
    }
  },

  computed: {
    ...mapGetters(['isAuthenticated'])
  },

  methods: {
    ...mapActions([
      'confirmUserEmail',
      'resendEmailConfirmationCode'
    ]),
    async handleSubmit() {
      try {
        let payload = {
          code: this.emailCode,
          userId: this.confirmId,
          password: this.password
        }

        await this.confirmUserEmail(payload)

        const nextPath = (this.isAuthenticated)
          ? {
              name: 'account', 
              params: { success: 'Email verification success!' }
            }
          : {
              name: 'login', 
              params: { success: 'Email verification success!' }
            }

        this.$router.push(nextPath)
      } catch(error) {
        console.error(error.message || error)
        this.error = error.message || error
        this.loading = false
      }
    },
    
    async resendCode() {
      try {
        this.error = ''
        await this.resendEmailConfirmationCode({ userId: this.confirmId, password: this.password })

        this.success = 'New verification code sent. Please enter the code and click "Verify".'
      } catch (error) {
        console.error(error.message || error)
        this.error = error.message || error
      }
    }
  }
}

export default Confirm
</script>

/* 
 * CALS PaWD Session / Local Storage JS Logic
 * 
 * No jQuery dependency...
 */
/* eslint-disable require-jsdoc, no-lonely-if */
/* global globalStorage, DOMException */
// console.log('Loaded CALS CCT MWD Session / Local Storage JS Logic.');

var CALSSTOR = {
    utils : {
        // countProperties : function (obj) {
        //     var prop;
        //     var propCount = 0;
            
        //     for (prop in obj) {
        //         propCount++;
        //     }
        //     return propCount;
        // },
        
        // getObjectSize : function  (obj) {
        //     // Get the size of an object
        //     // usage: var size = getObjectSize(myArray);
        //     var size = 0, key;
        //     for (key in obj) {
        //         if (obj.hasOwnProperty(key)) {size++;}
        //     }
        //     return size;
        // },
        
        // JSONPtoJSON : function (s) {
        //     var sRet = JSON.stringify(s);
        //     sRet = sRet.replace("jsonp(", "");
        //     //console.log(sRet);
        //     return sRet;
        // },
        
        prepareJSONforStorage : function (s) {
            
            var sRet = JSON.stringify(s);
            //sRet = sRet.replace("jsonp(", "");
            /*if (sRet.indexOf('\\') > 0) {
                //todo: why is this crashing? sRet = CALSSTOR.utils.removeJSONbackslashes(sRet);
            }*/
            //console.log(sRet);
            return sRet;
        },
        
        getJSONfromStorage : function (s) {
            var sRet = JSON.parse(s);
            return sRet;
        },
        
        // removeJSONbackslashes : function (str) {
        //     return String(str)
        //             .replace(/\\n/g, '')
        //             .replace(/\\/g, '');
        // },
        
        isJsonString : function (str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }
    }
}; 

function isLocalStorageSupported() {
  var testKey = 'localStorageEnabled', storage = window.sessionStorage;
  try { // Try and catch quota exceeded errors 
      if (storage.getItem(testKey) === '1') {
          // we've already been here, done that
          return true;
      } else {
          storage.setItem(testKey, '1');
          return true;
      } 
        //storage.setItem(testKey, '1');
        //storage.removeItem(testKey);
        //return true;
  } catch (error) {
      var msg = "";
    if (error.code === DOMException.QUOTA_EXCEEDED_ERR && storage.length === 0) {
        //alert('Hello, private browser.')
        msg = 'Your HTML5 LocalStorage is disabled, and this web app requires storing a small amount of data on your device. \n\nIf you are viewing this site in a Private browsing window, please re-open this site in a browser window without Private browsing turned on, otherwise this site will not work.';
        console.error('ERROR: ',msg);
        window.alert('Sorry, ' + msg);// eslint-disable-line no-alert
    } else { 
        msg = 'HTML5 SessionStorage is not accessible, and this web app requires storing a small amount of data on your device. \n\nIf you are viewing this site in a Private browsing window, please re-open this site in a browser window without Private browsing turned on, otherwise this site will not work.';
        console.error('ERROR: ',msg);
        window.alert('Sorry, ' + msg);// eslint-disable-line no-alert
        throw error; 
    }
    return false;
  }
}

function localStorageIfExists() {
    /*
     * ref: http://www.javascriptkit.com/javatutors/domstorage.shtml
     * 
     * In FF, support for the localStorage object was only added starting in FF3.5. 
     * In FF3.0 browsers, there exist the non standard globalStorage[location.hostname] 
     * equivalent object you can use to maximize browser compatibility.
     */
    var ecolocalstorage = window.localStorage || (window.globalStorage ? globalStorage[location.hostname] : null);
    
    if (!isLocalStorageSupported()) {
        console.error('ERROR: Local/Session Storage is not available - please re-try in a browser window with Private browsing turned OFF.');
        window.alert('Sorry, for improved user experience we decided to save some data to your device (see the Help page for what we save). \n\nWould you please check if Private Browsing is turned ON, otherwise you may have to try in a different browser (maybe Google Chrome?).');// eslint-disable-line no-alert
    }
    
    return ecolocalstorage;
}

CALSSTOR.saveToStorage = function (key, value, bIsObjOrArray, doLog) {

    doLog = doLog || false;
    
    if (bIsObjOrArray) {
        //log('** saveToStorage > IsObjOrArray : ' + key);
        //log(value);
        value = CALSSTOR.utils.prepareJSONforStorage(value);
        //log(value);
    } 

    var html5localstorage = localStorageIfExists();
    if (html5localstorage) {
        if (html5localstorage[key]) {
            if (doLog) { log('** saveToStorage -> localstorage[' + key + '] : Exists | current value = %o', (bIsObjOrArray ? CALSSTOR.utils.getJSONfromStorage(html5localstorage[key]) : html5localstorage[key])); }
            html5localstorage[key] = value;
            if (doLog) { log('** saveToStorage -> localstorage[' + key + '] : Updated to value = %o',(bIsObjOrArray ? CALSSTOR.utils.getJSONfromStorage(html5localstorage[key]) : html5localstorage[key])); }
        } else {
            html5localstorage[key] = value;
            if (doLog) { log('** saveToStorage -> localstorage[' + key + '] : Created with value = %o',(bIsObjOrArray ? CALSSTOR.utils.getJSONfromStorage(html5localstorage[key]) : html5localstorage[key])); }
        }
    } else if (window.sessionStorage){
        if (window.sessionStorage.getItem(key)){ 
            if (doLog) { log('** saveToStorage -> sessionStorage[' + key + '] : Exists | current value = %o',(bIsObjOrArray ? CALSSTOR.utils.getJSONfromStorage(window.sessionStorage.getItem(key)) : CALSSTOR.utils.getJSONfromStorage(window.sessionStorage.getItem(key)))); }
            window.sessionStorage.setItem(key, value);
            if (doLog) { log('** saveToStorage -> sessionStorage[' + key + '] : Updated to value = %o',(bIsObjOrArray ? CALSSTOR.utils.getJSONfromStorage(window.sessionStorage.getItem(key)) : CALSSTOR.utils.getJSONfromStorage(window.sessionStorage.getItem(key)))); }
        } else {
            window.sessionStorage.setItem(key, value);
            if (doLog) { log('** saveToStorage -> sessionStorage[' + key + '] : Created with value = %o',(bIsObjOrArray ? CALSSTOR.utils.getJSONfromStorage(window.sessionStorage.getItem(key)) : CALSSTOR.utils.getJSONfromStorage(window.sessionStorage.getItem(key)))); }
        }
    } else {
        // sessionStorage not available, use Win Vars
        var winvar = "Session" + key;
        window[winvar] = value;
        if (doLog) { log('** saveToStorage -> localStorage & sessionStorage not available - created window["' + winvar + '"] = %o', window[winvar]); }
    }
};

CALSSTOR.getFromStorage = function (key, bIsObjOrArray, doLog) {
    
    doLog = doLog || false;
    
    var sRet = "";
    
    var html5localstorage = localStorageIfExists();
    if (html5localstorage) {
        if (html5localstorage[key]) {
            if (doLog) { log('** getFromStorage -> localstorage[' + key + '] : Exists | current value = %o',(bIsObjOrArray ? CALSSTOR.utils.getJSONfromStorage(html5localstorage[key]) : html5localstorage[key])); }
            sRet = html5localstorage[key];
        } else {
            if (doLog) { log('** getFromStorage -> localstorage[' + key + '] : Does Not Exist'); }
        }
    } else if (window.sessionStorage){
        if (window.sessionStorage.getItem(key)){ 
            if (doLog) { log('** getFromStorage -> sessionStorage[' + key + '] : Exists | current value = %o',(bIsObjOrArray ? CALSSTOR.utils.getJSONfromStorage(window.sessionStorage.getItem(key)) : CALSSTOR.utils.getJSONfromStorage(window.sessionStorage.getItem(key)))); }
            sRet = window.sessionStorage.getItem(key);
        } else {
            if (doLog) { log('** getFromStorage -> sessionStorage[' + key + '] : Does Not Exist'); }
        }
    } else {
        // sessionStorage not available
        var winvar = "Session" + key;
        if (window[winvar]) {
            if (doLog) { log('** getFromStorage -> window["' + winvar + '"] : Exists | current value = ' + window[winvar]); }
            sRet = window[winvar];
        } else {
            if (doLog) { log('** getFromStorage -> window["' + winvar + '"] : Does Not Exist'); }
        }
        
    }

    if (bIsObjOrArray && sRet.length > 0) {
        //log('** getFromStorage > IsObjOrArray : ' + key);
        //log(sRet);
        sRet = CALSSTOR.utils.getJSONfromStorage(sRet);
        //log(sRet);
    } 

    return sRet;
};

CALSSTOR.logLocalStorageSize = function() {
    var grp = 'logLocalStorageSize',
        t = 0,
        thisLocalstorage = window.localStorage || (window.globalStorage ? globalStorage[location.hostname] : null);
    var computeSize = function(key) {
        var s = (((thisLocalstorage[key].length * 2)));
        t += s;
        console.log("localStorage: " + key + " = " + s / 1024 + " KB"); 
    };
    console.group(grp);
    for (var key in thisLocalstorage){ 
        //http://eslint.org/docs/rules/guard-for-in
        if (Object.prototype.hasOwnProperty.call(thisLocalstorage, key)) {
            computeSize(key);
        }
        // if ({}.hasOwnProperty.call(thisLocalstorage, key)) {
        //     computeSize(key);
        // }
    } 
    console.log("localStorage total: " + t / 1024 + " KB");
    console.groupEnd(grp);
};

export default CALSSTOR;

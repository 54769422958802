<template>
  <div class="uabanner">
    <a href="http://www.arizona.edu/" target="_blank" rel="noopener" title="University of Arizona Homepage">
      <img src="./ua-wordmark.svg" alt="The University of Arizona" class="uabanner__logo"/>
    </a>
  </div>
</template>

<script>
  let UABanner = {
    components: {},
    data() {
      return {}
    }
  };

  export default UABanner;
</script>

<style lang="sass" src="./UABanner.sass"></style>

import Vue from 'vue'
import api from '@/api/gauges'
import offlineApi from '@/api/localstorage'
import {
  RECEIVE_PUBLIC_GAUGES,
  RECEIVE_GAUGES,
  ADD_GAUGE,
  ADD_LOG_GAUGE,
  UPDATE_GAUGE,
  DELETE_GAUGE,
  USER_LOGOUT,
  GAUGES_SORT
} from '@/store/mutation-types'
import {
  ROLE_OWNER,
  ROLE_CONTRIBUTOR
} from '@/utils/constants'
import { dateToUTC } from '@/utils/dateUtils'
import store from '@/store'
import Leaflet from 'leaflet'
import { uniqBy, isEmpty } from 'lodash'
import JSZip from 'jszip'
import { saveAs } from '@/utils/generalUtils'

/**
  gauge shape:

  {
    created: "2017-11-27T18:30:10Z",
    gid: "002a0a40-d3a1-11e7-b8b3-a9c12ad0237f",
    isPublic: false,
    latitude: "33.53778188661789",
    longitude: "-110.92959880828859",
    publicProps: false,
    title: "Summit v2",
    uid: "43c37db0-0505-11e7-97cd-c9e80e5624a3",
    units: "mm",
    helpers: [{}]
  }
*/

// initial state
const state = {
  all: [],
  sort: 'title'
}

// getters
const getters = {
  getAllGauges(state, getters) {
    return getters.getMyGauges.concat(getters.getSharedGauges)
  },
  getMyGauges(state, getters) {
    return state.all.filter(gauge => (gauge.uid === getters.getUserId) && !gauge.archived) || []
  },
  getSharedGauges(state, getters) {
    return state.all.filter(gauge => (gauge.uid !== getters.getUserId) && (gauge.sharedByUid) && !gauge.archived) || []
  },
  getArchivedGauges(state, getters) {
    return state.all.filter(gauge => (gauge.uid === getters.getUserId) && gauge.archived) || []
  },
  getSharedUsers(state, getters) {
    return uniqBy(getters.getSharedGauges, 'sharedByUid')
  },
  // this searches across account gauges and gauges shared with account
  getGaugeById: (state) => (gid) => {
    return state.all.find(gauge => gauge.gid === gid) || {}
  },
  // this searches publicGauges if not found
  getAnyGaugeById: (state, getters) => (gid) => {
    let gauge = getters.getGaugeById(gid)

    if (isEmpty(gauge)) {
      gauge = store.state.publicData.gauges.find(gauge => gauge.gid === gid)
    }

    return gauge || {}
  },
  gaugeIndex: (state, getters) => (gid) => {
    // return index of gauge with gid
    return getters.getAllGauges.findIndex(gauge => gauge.gid === gid)
  },
  getPrevNextGaugeGid: (state, getters) => (gid, direction) => {
    const currentGaugeIndex = getters.gaugeIndex(gid)
    const indexMap = {
      next: 1,
      previous: -1
    }

    return (getters.getAllGauges[currentGaugeIndex + indexMap[direction]])
      ? getters.getAllGauges[currentGaugeIndex + indexMap[direction]].gid
      : ''
  },
  // formatting for ACIS
  getGaugeLngLatString: (state, getters) => (gid) => {
    let gauge = getters.getAnyGaugeById(gid)

    return `${gauge.longitude}, ${gauge.latitude}`
  },
  getGaugeRole: (state, getters) => (gid) => {
    let { 
      uid,
      shareLevel = ROLE_CONTRIBUTOR
    } = getters.getGaugeById(gid)

    return (uid === getters.getUserId)
      ? ROLE_OWNER
      : shareLevel
  },
  getLastLogDateByGid: (state, getters) => (gid) => {
    const logs = getters.getLogList(gid)

    return (logs.length)
      ? logs[0].logDateTime
      : ''
  }
}

// actions
const actions = {
  getUserGaugeData({ commit, dispatch }, payload) {
    function getOfflineGauges(payload) {
      return new Promise((resolve, reject) => {
        let response = offlineApi.gauges.get()
        resolve(response)
      })
    }

    // if gauges have already been fetched, return
    if (state.all.length > 0) {
      console.log("getUserGaugeData: using store cache...")

      return
    }

    const response = (store.getters.isOffline)
      ? getOfflineGauges(payload)
      : api.getGauges(payload).then(response => {
        offlineApi.logs.set([])
        return response
      })

    return response.then(gauges => {
      commit(RECEIVE_GAUGES, { gauges })
      dispatch('fetchAllLogs')
        .then(() => {
          // after all data is fetched, sort
          dispatch('sortGauges', offlineApi.gauges.getSort() || 'title')
        })

      return gauges
    })
  },

  async addGauge ({ commit, dispatch }, payload) {
    log("addGauge: inspect payload", payload)

    const gauge = await api.addGauge(payload)
    commit(ADD_GAUGE, gauge)
    commit(ADD_LOG_GAUGE, gauge.gid)

    return gauge
  },

  async updateGauge ({ commit, dispatch }, payload) {
    log('updateGauge: inspect payload', payload)
    // TODO: figure out a more elegant way besides just assuming "newImage" is an array
    const { gid, image } = payload

    const response = await api.updateGauge({ ...payload })
    const putData = { ...response, gid, image }
    commit(UPDATE_GAUGE, putData)

    return putData
  },

  async archiveGauge ({ commit, dispatch, getters }, gaugeId) {
    const gauge = {
      ...getters.getGaugeById(gaugeId),
      archived: dateToUTC()
    }

    await dispatch('updateGauge', gauge)

    return gauge
  },

  async unarchiveGauge ({ commit, dispatch, getters }, gaugeId) {
    const gauge = {
      ...getters.getGaugeById(gaugeId),
      archived: false
    }

    await dispatch('updateGauge', gauge)

    return gauge
  },

  async deleteGauge ({ commit, dispatch, getters }, payload) {
    const { uid, gid } = payload
    if (getters.getUserId !== uid) return

    const response = await api.deleteGauge({ uid, gid })
    try {
      await dispatch('deleteNotification', gid)
    } catch {
      // do nothing
    }
    // TODO: can cause issues after delete and gauge is changing routes - state in the component change
    // before route changes
    commit(DELETE_GAUGE, gid)

    return response
  },

  sortGauges ({ commit, getters }, payload) {
    const gauges = getters.getAllGauges.slice()

    switch (payload) {
      case 'created':
        gauges.sort((a, b) => {
          if (a.created > b.created) {
            return -1
          } else if (a.created < b.created) {
            return 1
          } else {
            return 0
          }
        })
        break
      case 'title':
        gauges.sort((a, b) => {
          if (a.title < b.title) {
            return -1
          } else if (a.title > b.title) {
            return 1
          } else {
            return 0
          }
        })
        break
      case 'recentObservation':
        gauges.sort((a, b) => {
          const first = getters.getLastLogDateByGid(a.gid) && getters.getLogList(a.gid).length > 1
            ? new Date(getters.getLastLogDateByGid(a.gid))
            : 0
          const second = getters.getLastLogDateByGid(b.gid) && getters.getLogList(b.gid).length > 1
            ? new Date(getters.getLastLogDateByGid(b.gid))
            : 0
          if (first > second) {
            return -1
          } else if (first < second) {
            return 1
          } else {
            return 0
          }
        })
        break
      default:
        // do nothing
        break
    }

    commit(RECEIVE_GAUGES, { gauges })
    offlineApi.gauges.setSort(payload)
    commit(GAUGES_SORT, payload)
  },

  /**
   * @name exportGaugeData
   * @description loops through config.gauges and gets csv strings from gaugeLogs.action.convertToCsv
   * zips the result is config.gauges.length > 1
   * @param {Object} store 
   * @param {Object} config see Export data.form for options
   * @returns 
   */
  async exportGaugeData ({ dispatch, getters }, config) {
    const {
      download = true,
      gauges = []
    } = config
    const data = []
    const zipFile = new JSZip()

    if (gauges.length === 1) {
      const gauge = getters.getGaugeById(gauges[0])
      const csv = await dispatch('convertToCsv', { gid: gauges[0], config })
      const blob = new Blob([csv], { type: 'text/csv' })

      if (download) {
        saveAs(blob, `${gauge.title}.csv`)
      }

      data.push(csv)
    } else {
      for (let i = 0; i < gauges.length; i++) {
        const gauge = getters.getGaugeById(gauges[i])  
        const csv = await dispatch('convertToCsv', { gid: gauges[i], config })
        data.push(csv)
        zipFile.file(`${gauge.title}.csv`, csv)
      }
  
      if (download) {
        // Generate the zip file asynchronously
        const content = await zipFile.generateAsync({ type: 'blob' })
        saveAs(content, 'gauges_csv.zip')
      }
    }

    return data
  }
}

// mutations
const mutations = {
  [RECEIVE_PUBLIC_GAUGES] (state, { gauges }) {
    state.public = gauges
  },

  [RECEIVE_GAUGES] (state, { gauges }) {
    state.all = gauges
  },

  [ADD_GAUGE] (state, gauge) {
    state.all.unshift(Object.assign({}, gauge))
  },

  [UPDATE_GAUGE] (state, newGauge) {
    // find gauge by gid/index and update it with newGauge
    const index = state.all.findIndex(gauge => gauge.gid === newGauge.gid)

    // use spread operator to create copy of object to avoid mutability
    const _newGauge = Object.assign({}, state.all[index], newGauge)

    Vue.set(state.all, index, _newGauge)
  },

  [DELETE_GAUGE] (state, gid) {
    // make copy of all gauge and get the index using gid
    const gauges = state.all.slice()
    const index = gauges.findIndex(gauge => gauge.gid === gid)

    // remove gauge
    gauges.splice(index, 1)
    state.all = gauges
  },

  [GAUGES_SORT] (state, value) {
    state.sort = value
  },

  [USER_LOGOUT] (state, payload) {
    state.all = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

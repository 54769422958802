<template>
  <div class="radio__wrapper" :class="classObject">
    <label class="radio__label-wrapper" :for="name" @click.prevent="updateInput">
      <div class="radio__mock">
        <transition name="bounce">
          <div class="radio__mock-circle" v-if="checked"/>
        </transition>
      </div>
      <span :class="getLabelClasses" v-html="label" />
    </label>

    <input
      class="radio"
      type="radio"
      :checked="checked"
      :value="value"
      :ref="name"
      :id="name" 
      :name="name"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  name: 'radio',
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    value: String,
    modelValue: [String, Boolean],
    disabled: Boolean,
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ""
    },
    hideLabel: Boolean
  },
  methods: {
    updateInput() {
      this.$emit('change', this.value)
    }
  },
  computed: {
    checked() {
      return this.modelValue === this.value
    },
    classObject() {
      return {
        'radio__wrapper--checked': this.checked,
        'radio__wrapper--disabled': this.disabled
      }
    },
    getLabelClasses() {
      return {
        'radio__label': true,
        'radio__label--hide': this.hideLabel
      }
    }
  }
}
</script>

<style lang="sass" src="./Radio.sass"></style>

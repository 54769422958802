import { 
  UPDATE_OFFLINE,
  FETCH_SYNC_QUEUE,
  UPDATE_SYNC_QUEUE,
  DELETE_SYNC_QUEUE,
  LOCAL_SYNC_RUNNING,
  SYNC_RUNNING } from '../mutation-types'
import offlineApi from '@/api/localstorage'
import shortId from 'shortid'
import store from '../index.js'

// initial state
const state = {
  offline: false,
  syncRunning: false,
  localSyncRunning: false,
  syncQueue: []
}

// getters
const getters = {
  isOffline: state => state.offline,
  showOfflineBar: state => state.offline || state.syncQueue.length,
  localSyncRunning: state => state.localSyncRunning,
  getSyncItem: (state) => (queueId) => state.syncQueue.find(item => item.queueId === queueId)
}

// actions creator
const actions = {
  updateOffline({commit}, payload) {
    commit(UPDATE_OFFLINE, payload)
  },
  fetchSyncQueue({commit, dispatch, getters}, payload) {
    const uid = getters.getUserId
    let response = offlineApi.syncQueue.get(uid)

    commit(FETCH_SYNC_QUEUE, response)
    dispatch('updateLocalSyncRunning', true)
    dispatch('runOfflineSync').then(response => {
      dispatch('updateLocalSyncRunning', false)
    })
  },
  getSetQueueItem({commit, dispatch}, payload) {
    return (typeof payload === 'string')
      ? dispatch('getQueueItem', payload)
      : dispatch('setQueueItem', payload)
  },
  getQueueItem({commit}, payload) {
    return store.getters.getSyncItem(payload)
  },
  setQueueItem({commit, getters}, payload) {
    const uid = getters.getUserId
    const queueItem = {...payload, queueId: shortId.generate()}
    offlineApi.syncQueue.post(uid, queueItem)

    commit(UPDATE_SYNC_QUEUE, queueItem)
    return queueItem
  },
  updateSyncRunning({commit}, payload) {
    commit(SYNC_RUNNING, payload)
  },
  updateLocalSyncRunning({commit}, payload) {
    commit(LOCAL_SYNC_RUNNING, payload)
  },
  deleteSyncQueueItem({commit, getters}, queueId) {
    const uid = getters.getUserId
    offlineApi.syncQueue.delete(uid, queueId)
    commit(DELETE_SYNC_QUEUE, queueId)
  },
  runOfflineSync({commit, dispatch}, queue) {
    dispatch('updateSyncRunning', true)

    const queueToSync = queue || state.syncQueue
    let failedQueueItems = []

    // run all asnychronous commands before mutating anything
    // TODO: show sync loader during this whole process
    return Promise.all(
      queueToSync.map((queueItem, index) => {
        return new Promise((resolve, reject) => {
          try {
            dispatch(queueItem.type, {...queueItem.payload, queueId: queueItem.queueId}).then(() => {
              console.log("queue item successful: ", queueItem.payload)
              !state.localSyncRunning && dispatch('deleteSyncQueueItem', queueItem.queueId)
              resolve()
            })
          } catch(error) {
            console.log(error)
            reject(queueItem)
          }
        })
      })
    ).then(values => {
      // do something successfull
      dispatch('updateSyncRunning', false)
      return values
    }).catch(item => {
      // if an item fails to sync/put/update/post then add it to an array and put it back in the queue
      console.log("queue item failed: ", item)
      failedQueueItems.push(item)
      commit(UPDATE_SYNC_QUEUE, failedQueueItems)
      dispatch('updateSyncRunning', false)
    })
  }
}

// mutations (reducer)
const mutations = {
  [UPDATE_OFFLINE](state, payload) {
    state.offline = payload
  },
  [FETCH_SYNC_QUEUE](state, payload) {
    state.syncQueue = payload
  },
  [SYNC_RUNNING](state, payload) {
    state.syncRunning = payload
  },
  [LOCAL_SYNC_RUNNING](state, payload) {
    state.localSyncRunning = payload
  },
  [UPDATE_SYNC_QUEUE](state, payload) {
    let currentQueue = state.syncQueue.slice()
    state.syncQueue = currentQueue.concat(payload)
  },
  [DELETE_SYNC_QUEUE](state, queueId) {
    let currentQueue = state.syncQueue.slice()
    let itemIndex = currentQueue.findIndex(i => i.queueId === queueId)

    // short hand for getting boolean from "findIndex" returning -1
    if (!!~itemIndex) {
      currentQueue.splice(itemIndex, 1)
    }

    state.syncQueue = currentQueue
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}

<template>
  <section class="units-preference text-left">
    <h3>Units</h3>
    <p>Default for <strong><em>this</em></strong> gauge.</p>

    <v-select 
      outlined
      class="white"
      single-line
      hide-details
      v-model="mx_value"
      :disabled="disabled"
      :items="items"/>
  </section>
</template>

<script>
  import ExternalBind from '@/mixins/ExternalBind'
  import { UNITS } from '@/utils/constants'

  export default {
    name: 'units-preference',

    mixins: [ExternalBind],

    props: {
      disabled: Boolean
    },

    data() {
      return {
        items: UNITS.slice()
      }
    }
  }
</script>

<style lang="sass">
  .units-preference
    .v-select
      max-width: 120px
</style>
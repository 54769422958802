import numbro from 'numbro'

export function decimalDoublePad(num) {
  let format = numbro(num).format('0.00')
  return (format !== 'NaN') ? format : ''
}

export function thousandSeparated (num, mantissa = 0) {
  return numbro(num).format({
    thousandSeparated: true,
    mantissa
  })
}

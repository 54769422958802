<template>
  <div class="gauge-report-loader">
    <template v-if="loadingHistorical">
      <tooltip>Historical data is being downloaded in the background. You can navigate to other pages, but closing the browser will terminate the connection.</tooltip>
      <v-btn loading depressed rounded color="accent">View Report</v-btn>
    </template>

    <template v-else-if="errorHistorical">
      <tooltip>If this error continues, please see the Help section for troubleshooting options.</tooltip>
      <v-btn :small="small" depressed rounded color="error" @click="dispatchHistoricalData({ gid })">Report failed, Try Again?</v-btn>
    </template>

    <v-btn :small="small" depressed rounded color="accent" @click="innerClickCallback" v-else class="ma-0">View Report</v-btn>
  </div>
</template>

<script>
import RccDataMixin from '@/mixins/RccDataMixin'
import DisplayManagerMixin from '@/mixins/DisplayManagerMixin'

export default {
  name: 'GaugeReportLoader',

  mixins: [RccDataMixin, DisplayManagerMixin],

  props: {
    gid: {
      type: String,
      required: true
    },
    small: Boolean,
    clickCallback: {
      type: Function,
      default: null
    }
  },

  data () {
    return {
      innerClickCallback: this.clickCallback ? this.clickCallback : this.defaultClickCallback,
      loadingHistorical: true,
      errorHistorical: false
    }
  },

  watch: {
    gid (newGid) {
      this.fetchHistorical(newGid)
    }
  },

  created () {
    this.fetchHistorical(this.gid)
  },

  methods: {
    async fetchHistorical (gid) {
      try {
        this.errorHistorical = false
        this.loadingHistorical = true
        await this.dispatchHistoricalData({ gid })
      } catch (e) {
        this.errorHistorical = true
        // do something
      } finally {
        this.loadingHistorical = false
      }
    },
    defaultClickCallback () {
      this.$router.push({
        name: 'reportsBuilder',
        query: {
          gids: this.gid,
          prebuilt: true
        }
      })
    }
  }
}
</script>

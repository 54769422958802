<template>
  <v-container fluid grid-list-lg id="support" class="support pt-0">
    <page-section header>
      <h1>Full Array of Support</h1>
      <p class="page-section__sub-header">
        Below is quick access to popular topics and guides. 
        <br /><br/>
        Find an growing list of helpful articles, practical guides, and training videos on our knowledge base Help Site.</p>

        <template slot="actions">
          <v-btn href="//info.myraingelog.arizona.edu"
            target="_blank"
            rel="noopener"
            depressed
            rounded
            large
            color="accent">
            Go To Help Site
          </v-btn>
        </template>
    </page-section>

    <page-section slim centered>
      <h2>PRECIPITATION MONITORING BEST PRACTICES GUIDE</h2>

      <p>This guide will discuss some of the basics of thinking through a precipitation monitoring plan in support of rangeland management, like: </p>

      <p>How many gauges do I need and where should I put them?</p>
      
      <p>How often do I need to make observations?</p>

      <p>How should I manage and interpret my precipitation data?</p>

      <template slot="actions">
        <v-btn href="https://extension.arizona.edu/sites/extension.arizona.edu/files/pubs/az1751-2017.pdf"
          @click.native="trackPDFDownload('best_practices_az1751-2017.pdf')"
          target="mrl-pdf"
          rel="noopener"
          depressed
          rounded
          large
          color="accent">
          Download PDF
        </v-btn>
      </template>
    </page-section>

    <page-section slim>
      <h2>DO-IT-YOURSELF CONSTRUCTION GUIDE: RUGGED ACCUMULATION PRECIPITATION GAUGE FOR REMOTE MONITORING</h2>

      <p>This guide shows you how to construct a rugged rain gauge out of clear PVC pipe, ideal for long-term unattended precipitation monitoring at remote sites. This type of accumulation gauge where precipitation depth increases over time until the gauge is emptied works seamlessly with the data entry and visualization tools within MyRAINge Log.</p>

      <p>The guide also discusses strategies to locate and install gauges, protect them from vandalism and maintain for them many years of uninterrupted use.</p>

      <template slot="actions">
        <v-btn href="https://extension.arizona.edu/sites/extension.arizona.edu/files/pubs/az1747-2017_0.pdf"
          @click.native="trackPDFDownload('gauge_construction_guide_az1747-2017_0.pdf')"
          target="mrl-pdf"
          rel="noopener"
          depressed
          rounded
          large
          color="accent">
          Download PDF
        </v-btn>
      </template>
    </page-section>

    <get-started/>
  </v-container>
</template>

<script>
import GetStarted from '@/components/GetStarted/GetStarted'

export default {
  name: 'support',
  components: {
    GetStarted
  },
  data () {
    return {
      title: "Tips for better results",
      tagline: "",
      msg: '',
      who: [
        {
          name: "Mike Crimmins",
          title: "Title",
          img: "[pic]"
        },
        {
          name: "Mitch McClaran",
          title: "Title",
          img: "[pic]"
        },
        {
          name: "Julie Brugger",
          title: "Title",
          img: "[pic]"
        },
        {
          name: "Doug Tolleson",
          title: "Title",
          img: "[pic]"
        }
      ]
    }
  },
  methods: {
    trackPDFDownload(value) {
      this.$gtm.trackEvent({
        event: 'GAEvent-v4',
        category: 'Download PDF',
        label: value,
        action: 'click'
      })
    }
  }
}
</script>

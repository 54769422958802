<template>
  <form
    v-if="booted"
    class="rain-notifications-settings-form form"
    novalidate
    @submit.prevent="formSubmit(submit)"
  >
    <template
      v-for="(data, index) in joinedData"
    >
      <v-row
        :key="`header-${index}`"
        align="center"
        class="mt-8"
        dense
      >
        <v-col sm="6">
          <h4 class="text-uppercase pa-0 ma-0">
            {{
              index === 0
                ? 'My Gauges'
                : 'Gauges Shared with me'
            }}
          </h4>
        </v-col>

        <v-col
          sm="3"
          class="text-center"
        >
          Receive email
        </v-col>
        <v-col
          sm="3"
          class="text-center"
        >
          Threshold
        </v-col>
      </v-row>

      <v-row
        v-if="index === 0"
        :key="`apply-all-${index}`"
        align="center"
      >
        <v-col sm="6" />

        <v-col
          sm="3"
          class="text-center"
        >
          <checkbox
            v-model="applyToAll"
            name="sendEmail"
            class="mt-2"
          />
        </v-col>
        <v-col
          sm="3"
          class="text-center"
        />
      </v-row>

      <v-row
        v-for="gauge in Object.values(data)"
        :key="gauge.gid"
        align="center"
        class="rain-notifications-settings-form__row"
      >
        <v-col sm="6">
          {{ gauge.title }}
        </v-col>

        <v-col
          sm="3"
          class="text-center"
        >
          <checkbox
            v-model="data[gauge.gid].sendEmail"
            :name="`${data[gauge.gid]}-sendEmail`"
            class="mt-2"
          />
        </v-col>
        <v-col
          sm="3"
          class="text-center"
        >
          <v-text-field
            v-model="data[gauge.gid].threshold"
            v-validate="'required|decimal:3'"
            class="input-white"
            required
            outlined
            dense
            single-line
            hide-details
            :data-vv-name="`${gauge.gid}-threshold`"
            :name="`${gauge.gid}-threshold`"
            type="number"
            :error-messages="errors.collect(`${gauge.gid}-threshold`)"
          />
        </v-col>
      </v-row>
    </template>

    <div class="form__actions mt-12">
      <v-btn
        type="submit"
        color="accent"
        :loading="loading"
        depressed
        rounded
        large
      >
        Update Notification Settings
      </v-btn>
    </div>
  </form>
</template>

<script>
import FormValidation from '@/mixins/FormValidation'

export default {
  name: 'RainNotificationSettingsForm',

  mixins: [FormValidation],

  props: {
    gauges: {
      type: Array,
      default: () => ([])
    },
    sharedGauges: {
      type: Array,
      default: () => ([])
    },
    notifications: {
      type: Array,
      default: () => ([])
    },
    onSubmit: {
      type: Function,
      default: () => {}
    }
  },

  data () {
    return {
      booted: false,
      applyToAll: false,
      form: {},
      formShared: {}
    }
  },

  computed: {
    joinedData () {
      return [
        ...(this.gauges.length ? [this.form] : []),
        ...(this.sharedGauges.length ? [this.formShared] : [])
      ]
    }
  },

  watch: {
    applyToAll (val) {
      if (this.booted) {
        Object
          .values(this.form)
          .concat(Object.values(this.formShared))
          .forEach(i => {
            i.sendEmail = val
          })
      }
    }
  },

  mounted () {
    this.form = this.processGaugeNotifications(this.gauges)
    this.formShared = this.processGaugeNotifications(this.sharedGauges)
    this.applyToAll = this.checkEmailSetting()
    this.booted = true
  },

  methods: {
    async submit () {
      this.loading = true
      const newNotifications = [
        ...Object.values(this.form),
        ...Object.values(this.formShared)
      ]
      const updates = this.notifications.reduce((outcome, existingNotification) => {
        const compare = newNotifications.find(v => v.gid === existingNotification.gid)

        if (
          compare &&
          (
            Number(compare.threshold) !== Number(existingNotification.threshold) ||
            compare.sendEmail !== existingNotification.sendEmail
          )
        ) {
          outcome.push(compare)
        }

        return outcome
      }, [])

      await this.onSubmit(updates)
      this.loading = false
    },

    processGaugeNotifications (data) {
      return data.reduce((outcome, gauge) => {
        const notification = this.notifications.find(n => n.gid === gauge.gid)

        if (notification) {
          outcome[gauge.gid] = {
            title: gauge.title,
            ...notification
          }
        }

        return outcome
      }, {})
    },

    checkEmailSetting () {
      let returnValue = true
      Object
        .values(this.form)
        .concat(Object.values(this.formShared))
        .forEach(v => {
          if (!v.sendEmail) {
            returnValue = false
          }
        })
      return returnValue
    }
  }
}
</script>

<style lang="sass" src="./RainNotificationSettingsForm.sass" />

<!--
  PageSection:
  ======
  PROPS:
  theme: a color which will add a css modifier
  header: converts the look to the top section of a page
  slim: add much larger left/right padding, already has media query to smaller screens
  centered: uh... it centers it

  SLOTS:
  actions: groups buttons and centers them with proper margin, always at the bottom

  see PageSection.styl for more formatting
-->


<template>
  <!-- <v-container fluid grid-list-md tag="section" class="page-section" :class="getClasses"> -->
    <section row tag="section" class="page-section" :class="getClasses">
      <template v-if="loading">
        <div class="page-section__spinner">
          <spinner :size="100"/>
        </div>
      </template>

      <template v-else-if="!overlayLoading">
        <div class="page-section__header-icon" v-if="headerImageIcon">
          <v-icon>{{ headerImageIcon }}</v-icon>
        </div>
        <slot></slot>
        <div class="page-section__actions" v-if="this.$slots['actions']">
          <slot name="actions"></slot>
        </div>
        <div class="page-section__top-right-actions" v-if="this.$slots['top-right-actions']">
          <slot name="top-right-actions"></slot>
        </div>
      </template>

      <transition name="fade">
        <div class="page-section__overlay-loading" v-if="overlayLoading">
          <spinner :size="100"/>
        </div>
      </transition>
    </section>
  <!-- </v-container> -->
</template>

<script>
  let PageSection = {
    props: {
      theme: {default: 'white'}, // add validator to show diff options
      header: Boolean,
      headerImage: Boolean,
      headerImageIcon: String,
      centered: Boolean,
      slim: Boolean,
      loading: Boolean,
      overlayLoading: Boolean
    },
    data() {
      return {}
    },
    computed: {
      getClasses() {
        return {
          'page-section--centered': this.centered,
          'page-section--slim': this.slim,
          'page-section--header': this.header,
          'page-section--header-image': this.headerImage,
          [`page-section--${this.theme}`]: this.theme && !this.header && !this.headerImage,
          'page-section--overlay-loading': this.overlayLoading
        }
      },
    }
  }

  export default PageSection
</script>

<style lang="sass" src="./PageSection.sass"></style>

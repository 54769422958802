/*!
 *
 *  MyRAINge Log App.js
 * 
 * // implementation refs: 
 * https://github.com/vuejs/vue-hackernews-2.0
 * https://auth0.com/blog/build-an-app-with-vuejs/
 * https://github.com/skyronic/vue-spa
 *
 */
/* eslint-env browser */

import appConfig from './config'
import Vue from 'vue'
import VeeValidate from 'vee-validate'
import './api/lib/aws/amplify'
import App from './App.vue'
import Directives from './directives/_index'
import Components from './components/_index'
import store from './store'
import router from './router'
import { sync } from 'vuex-router-sync'
import * as filters from './filters'
import './plugins/dialog'
import './plugins/mappKit'
import './plugins/sentry'
import vuetify from './plugins/vuetify'
import './sass/main.sass'
import './sass/print.sass'
import './plugins/gtm'
import './registerServiceWorker'
import { isValidDate } from './utils/dateUtils'

// sync the router with the vuex store.
// this registers `store.state.route`
sync(store, router)

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.use(VeeValidate)

// this forEach globally regsiters all the component within components/_ui/
// nothing in components/_ui/ needs to be imported into other components
Object.keys(Components).forEach(key => {
  Vue.component(`${key}`, Components[key])
})

Object.keys(Directives).forEach(key => {
  Vue.directive(`${key}`, Directives[key])
})

VeeValidate.Validator.extend('passwordPolicy', {
  getMessage: () => 'Password requirements are not met.',
  validate: (value) => {
    const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/g;
    return !!value.match(regex)
  }
})

VeeValidate.Validator.extend('validDate', {
  getMessage: () => 'Not valid date',
  validate: (date) => {
    return isValidDate(date)
  }
})

VeeValidate.Validator.extend('matchField', {
  getMessage: (field, args) => {
    return (args[0] && args[0].field)
      ? `This field must match ${args[0].field}`
      : "Field does not match other field"
  },
  validate: (value, args) => {
    if (!args[0] && !args[0].value) {
      // must provide a value to match it to for this validation to resolve
      return false
    }
    
    return args[0].value === value
  }
})

VeeValidate.Validator.extend('month', {
    getMessage: field => 'Must be between 1 and 12',
    validate: value => value >= 1 && value <=12 
});

VeeValidate.Validator.extend('day', {
    getMessage: field => 'Must be between 1 and 31',
    validate: value => value >= 1 && value <= 31
});

VeeValidate.Validator.extend('year', {
    getMessage: field => 'Year format must be YYYY',
    validate: value => value >= 0 && value.length === 4
});


Vue.config.productionTip = false;

new Vue({
  APP: appConfig,
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app');

export { router, store }

<!-- 
TODO: add Login via FB & G+
https://aws.amazon.com/blogs/developer/authentication-in-the-browser-with-amazon-cognito-and-public-identity-providers/
https://developers.facebook.com/docs/facebook-login/web

-->

<template>
  <v-container fluid grid-list-lg id="login" class="login pt-0">
    <page-section header>
      <h1>Nice to see you</h1>
    </page-section>
    <page-section centered>
      <div class="form__wrapper">
        <transition name="fade" mode="out-in">
          <div class="form__errors" v-if="error">
            {{ error }}
          </div>
        </transition>

        <transition name="fade" mode="out-in">
          <div class="form__success" v-if="$route.params.success">
            {{ $route.params.success }}
          </div>
        </transition>

        <transition name="fade" mode="out-in">
          <div class="form__errors" v-if="$route.query.redirect">
            Please login to continue.
          </div>
        </transition>
        
        <form @submit.prevent="formSubmit(handleLogin)" class="form" novalidate>
          <v-text-field
            v-model="form.username"
            v-validate="'required'"
            label="Email"
            required
            data-vv-name="email"
            name="email"
            outlined
            single-line
            :error-messages="errors.collect('email')"
          />
          <v-text-field
            v-model="form.password"
            v-validate="'required'"
            type="password"
            label="Password"
            required
            outlined
            single-line
            data-vv-name="password"
            name="password"
            :error-messages="errors.collect('password')"
          />
          <div class="form__helpers">
            <router-link to="/forgot-password">Dang! I forgot my password.</router-link>
          </div>

          <div class="form__actions">
            <v-btn type="submit" color="accent" depressed rounded :loading="loading" large>Log in</v-btn>
            <router-link to="/signup">Whoa! I don’t have an account.</router-link>
          </div>
        </form>
      </div>
    </page-section>
  </v-container>
</template>

<script>
import FormValidation from '@/mixins/FormValidation'
import { mapActions } from 'vuex'

export default {
  name: 'login',
  
  components: {},
  
  props: {
    user: String
  },
  
  data () {
    return {
      error: '',
      allowPhone: false,
      form: {
        username: null,
        password: null,
        rememberMe: true // for now, all devices will be remembered
      }
    }
  },
  
  mixins: [FormValidation],
  
  mounted() {
    if (this.user) {
      // passed as prop from route - used to reset the account (send userId)
      this.form.username = this.user
    }
  },

  methods: {
    ...mapActions(['login']),
    async handleLogin() {
      try {
        var credentials = Object.assign({}, this.form, {
          username: this.form.username.toLowerCase().trim()
        })

        await this.login(credentials)

        this.success()
      } catch ({ message }) {
        this.loading = false
        this.error = message
        this.form.password = ''
      }
    },
    success() { 
      this.$router.replace(this.$route.query.redirect || '/dashboard')
      setTimeout(() => { this.loading = false }, 600)
    }
  }  
}
</script>

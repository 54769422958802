/**
 * using axios
 * https://github.com/mzabriskie/axios
 */
import AwsConfig from "./lib/aws/config"
import axios from 'axios'

//https://github.com/mzabriskie/axios
const instance = axios.create({
  baseURL: AwsConfig.ApiGatewayBaseURL,
  // `timeout` specifies the number of milliseconds before the request times out.
  // If the request takes longer than `timeout`, the request will be aborted.
  timeout: 5000,
  // `headers` are custom headers to be sent
  // ALLOWED HEADERS: 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token'
  headers: {
    // 'Access-Control-Allow-Origin': 'XMLHttpRequest'
  },
});


export default {
  // "type" parameter determines which service in AWS to use, "sns" or "ses"
  sendMessage (email, message, subject, type = 'ses') {
    if (!email || !message ) throw new Error('missing param in sendMessage api call')

    let payload = {
        message: message.replace(/</g, "&lt;").replace(/>/g, "&gt;"), // remove potentially harmful script injection
        email,
        subject,
        type
    };
 
    console.info("sendMessage payload:",payload)

    return instance.post('/sendmessage', payload)
        .then(function (response) {
            // console.log("[api] POST sendMessage resp:",response);
            if (response && response.status && response.status === 200) {
                return {success:true}
            } else {
                let err = "no response or status!200";
                console.warn("[api] POST sendMessage ERROR:",err, response);
                return {error: err}
            }
            
        })
        .catch(function (error) {
            console.warn("[api] POST sendMessage ERROR:",error);
            return {error}
        })
    

    
  },

  notifyNewUserSignup (email, subject, message, oldEmail) {
    if (!email ) {
        console.log("Missing param in notify/activity api");
        return false
    }

    let br = "\n";//"\r\n";// "%0D%0A"
    let msg = "";
    
    if (message) {
        msg += message.replace(/</g, "&lt;").replace(/>/g, "&gt;") + br + br; // remove potentially harmful script injection
    } else {
        msg += "MyRAINge Log User Activity Notification:" + br + br
    }
    if (oldEmail) {
        msg += "New Email: " + email + br;
        msg += "Old Email: " + oldEmail;
    } else {
        msg += "Email: " + email;
    }
    msg += br + br + "Auto-generated notification from https://MyRAINgeLog.arizona.edu";


    let payload = {
        msg, 
        email,
        subject: subject || "MyRAINge Log User Activity Notification"
    };

    // console.info("sendMessage payload:",payload)

    return instance.post('/notify/useractivity', payload)
        .then(function (response) {
            // console.log("[api] POST sendMessage resp:",response);
            if (response && response.status && response.status === 200) {
                return {success:true}
            } else {
                let err = "no response or status!200";
                console.log("[api] POST notify/activity ERROR:",err, response);
                return {error: err}
            }
            
        })
        .catch(function (error) {
            console.log("[api] POST notify/activity ERROR:",error);
            return {error}
        })
    

    
  }

}
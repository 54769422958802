import GaugeMutations from '../vuex/GaugeMutations'
import { mapGetters } from 'vuex'

export default {
  mixins: [GaugeMutations],
  
  computed: {
    ...mapGetters([
      'getGaugeById',
      'getLogList'
    ]),
    gid () {
      const {
        params: {
          gid
        } = {}
      } = this.$route

      return gid
    },
    gauge () {
      return this.getGaugeById(this.gid)
    },
    gaugeLogs () {
      return this.getLogList(this.gid)
    },
    gaugeUnits () {
      const { units = 'in' } = this.gauge

      return units
    },
    mostRecentGaugeLog () {
      return (this.gaugeLogs.length) ? this.gaugeLogs[0] : {}
    }
  }
}
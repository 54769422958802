/*
 *  App Config
 *  @author: jd@cals
 */
/* eslint max-len: 0 */

import './scripts/helpers/polyfills' // start with these in place

// namespace
var appConfig = {

    VERSION: process.env.VERSION,//"0.4#201702",
    RAVEN_URL: process.env.RAVEN_URL,
    credits: "UA CALS CCT WebDev",

    features: {
        enableServiceWorker: true,
        enableGaugeTags: false
    },
    
    config: {}
}; 

// Avoid `console` errors in browsers that lack a console.
if (typeof window !== 'undefined') {

    (function() {
        var method;
        var noop = function () {};
        var methods = [
            'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
            'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
            'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
            'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
        ];
        var length = methods.length;
        var console = (window.console = window.console || {});

        while (length--) {
            method = methods[length];

            // Only stub undefined methods.
            if (!console[method]) {
                console[method] = noop;
            }
        }
    })();

    window.log = console.log.bind(console);
    window.info = console.info.bind(console);
    window.warn = console.warn.bind(console);
    window.error = console.error.bind(console);
    window.group = console.group.bind(console);
    window.groupCollapsed = console.groupCollapsed.bind(console);
    window.groupEnd = console.groupEnd.bind(console);
    window.time = console.time.bind(console);
    window.timeEnd = console.timeEnd.bind(console);



    console.log("%cWelcome to MyRAINge Log! %c(v%s) %cCreated by UA CALS CCT","color:blue;font-size:140%;font-weight:bold;","color:blue;font-weight:normal;font-size:100%;",appConfig.VERSION,"color:green;font-size:100%;");
    console.log("%cWe hope you enjoy using the site, let us know if you have any feedback or recommendations - cheers!","color:#8C7A64;font-size:100%;");
    if (process.env.NODE_ENV !== 'production') {
        console.log("NODE_ENV: ",process.env.NODE_ENV);
    }

}

export default appConfig;

<template>
  <div :class="getCollapseClasses">
    <div @click.prevent="clickHandler" class="collapse__trigger">
      <slot name="trigger"></slot>
    </div>
    <div class="collapse__content-wrapper" ref="contentWrapper" :style="contentWrapperStyles" @click="captureClicks">
      <div ref="content" class="collapse__content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import ExternalBind from '@/mixins/ExternalBind'
  import shortid from 'shortid'

  let Collapse = {
    mixins: [ExternalBind],

    data() {
      return {
        open: false,
        shortId: shortid.generate()
      }
    },

    mounted() {
      // if default state is "open", then force technique to open to full height of refs.content
      if (this.mx_value) {
        this.$nextTick(() => {
          this.shortId = shortid.generate()
          // TODO: doesnt fully open with image
          this.$refs.contentWrapper.click()
        })
      }
    },

    methods: {
      clickHandler(e) {
        this.mx_value = !this.mx_value
      },
      captureClicks(e) {
        // anytime the body gets clicked on, it will generate a new shortid
        this.$nextTick(() => {
          this.shortId = shortid.generate()
        })
      }
    },

    computed: {
      contentHeight() {
        let {
          $refs: {
            content: {
              offsetHeight
            } = {}
          } = {}
        } = this
        // when this.shortId changes, it will re-evaluate content.offsetHeight
        return `${offsetHeight}px-${this.shortId}`
      },
      getCollapseClasses() {
        return {
          'collapse': true,
          'collapse--open': this.mx_value
        }
      },
      contentWrapperStyles() {
        return {
          height: (this.mx_value) ? this.contentHeight.split('-')[0] : '0px'
        }
      }
    }
  }

  export default Collapse
</script>

<style lang="sass" src="./Collapse.sass"></style>
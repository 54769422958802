import AwsConfig from "@/api/lib/aws/config"
import { join } from 'lodash'

// lngLatString same format as getters.getGaugeLngLatString
export function convertLngLatToId(lngLatString) {
  if (lngLatString.split(', ').length !== 2) throw new Error('lngLatString in wrong format')

  return `${join(lngLatString.split(', '), '-')}`
}

export function getHistoricalUrl(lngLatString) {
  return `${AwsConfig.historicalDataUrl}${convertLngLatToId(lngLatString)}.json`
}

<template>
  <div :class="getClasses" role="alert">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'pill-alert',

    props: {
      type: {
        type: String,
        validator(val) {
          return [
            'danger',
            'success',
            ''
          ].includes(val);
        }
      }
    },

    computed: {
      getClasses() {
        return {
          'pill-alert': true,
          [`pill-alert--${this.type}`]: this.type
        }
      }
    }
  }
</script>

<style lang="scss">
  /** Pill **/
  .pill-alert {
    text-align: center;
    width: 100%;
    padding: 15px 25px;
    border-radius: 40px;
    background-color: $color-lightest-blue;

    a {
      font-weight: bold;
      color: #fff !important;
      text-decoration: underline;
    }
  }

  .pill-alert--success {
    @extend .pill-alert;
    color: $color-green-dark;
    font-weight: bold;
    background-color: $color-green-light;
  }

  .pill-alert--danger {
    @extend .pill-alert;
    margin-bottom: 15px;
    color: #fff;
    font-weight: bold;
    background-color: $color-orange;

    * {
      color: #fff;
    }
  }
</style>
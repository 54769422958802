<template>
  <v-container fluid grid-list-lg id="log_add" class="log-add pt-0">
    <page-section header-image>
      <gauge-list-image :src="gauge.image"/>
      <h1>{{ gauge.title }}</h1>
    </page-section>

    <page-section centered>
      <div class="log-add__wrapper">
        <pill-alert type="danger" v-if="error">{{ error }}</pill-alert>

        <h2>Add Observation</h2>

        <pill-alert
          class="mb-3"
          :type="readingInvalid ? 'danger': ''"
        >
          <template
            v-if="errors.first('date')"
          >
            <div class="error--text"><strong>Please enter a valid date.</strong></div>
          </template>
          <template v-if="getPreviousLog.reading">
            <div class="d-flex justify-space-between">
              <div class="flex-grow-1 stacked">
                <div class="stacked__top font-weight-bold">
                  Previous Observation
                  <template v-if="getPreviousLog.isReset">(reset)</template>
                </div>
                <div class="stacked__bottom">
                  {{ getPreviousLogDate }} ({{ getPreviousRelativeDate }})
                </div>
              </div>
              <div shrink class="flex-shrink-1 stacked log-add__last-reading--right">
                <div class="stacked__top">{{ getPreviousReading }}</div>
                <div class="stacked__bottom font-weight-bold">{{ humanMeasurements }}</div>
              </div>
            </div>
          </template>
          <template v-if="getNextLog.reading">
            <div class="d-flex justify-space-between">
              <div class="flex-grow-1 stacked">
                <div class="stacked__top font-weight-bold">
                  Next Observation
                  <template v-if="getNextLog.isReset">(reset)</template>
                </div>
                <div class="stacked__bottom">
                  {{ getNextLogDate }} ({{ getNextRelativeDate }})
                </div>
              </div>
              <div shrink class="flex-shrink-1 stacked log-add__last-reading--right">
                <div class="stacked__top">{{ getNextReading }}</div>
                <div class="stacked__bottom font-weight-bold">{{ humanMeasurements }}</div>
              </div>
            </div>
          </template>

          <div
            v-if="readingInvalid"
            class="mt-3"
          >
            Your observed precipitation level is lower than your previous observation
            <template v-if="getNextLog.reading && !getNextLog.isReset">
              or larger than your subsequent observation.
            </template>
          </div>
        </pill-alert>

        <form @submit.prevent="formSubmit(saveLog)" class="log-add__form form" novalidate>
          <v-row>
            <v-col
              sm="7"
              class="pt-3"
            >
              <strong>Date of Observation</strong>
            </v-col>

            <v-col sm="4" offset-sm="1">
              <date-menu
                v-model="form.logDateTime"
                v-validate="'validDate'"
                :error-messages="errors.collect('date')"
                data-vv-name="date"
                name="date"
                hide-actions
                label="Date of Reading"
                :text-field-props="{
                  hideDetails: true,
                  outlined: true,
                  singleLine: true
                }"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="7">
              <template v-if="!gaugeLogs.length">
                <strong>Congrats on setting up your gauge!</strong> Enter the starting level on your gauge to create your first observation.
              </template>
              <template v-else>
                <strong>Observed precipitation level ({{ humanMeasurements }})</strong>
              </template>
            </v-col>

            <v-col sm="4" offset-sm="1">
              <v-text-field
                v-model="form.reading"
                v-validate="
                  `required|decimal:3|min_value:${getPreviousLog.reading || 0}|${!getNextLog.reading || getNextLog.isReset ? '' : `max_value:${getNextLog.reading}`}`
                "
                label="Reading"
                required
                dense
                type="number"
                aria-required="true"
                data-vv-name="reading"
                name="reading"
                ref="reading"
                outlined
                single-line
                :hint="`(for example: ${readingHint})`"
                :error-messages="errors.first('reading')"
                v-input-no-scroll="'reading'"
                aria-autocomplete="off"
                autocomplete="off"
              />
            </v-col>

            <v-col
              cols="12"
              class="log-add__notes mt-n6"
            >
              <p
                id="notes_label"
                class="mb-2"
              >
                <strong>Supporting notes</strong>
              </p>
              <v-textarea
                v-model="form.notes"
                name="notes"
                outlined
                single-line
                hide-details
                aria-labelledby="notes_label"
                placeholder="Describe the vegetation, drinking water, and other site conditions."
                rows="4"
              />

              <div class="mt-3">
                <image-input
                  v-if="!offline"
                  v-model="form.image"
                  name="addImage"
                  class="mb-3"
                />
                <div v-else>Can't uploading images in Offline mode</div>
              </div>
            </v-col>
          </v-row>

          <v-row class="log-add__reset">
            <v-col sm="7">
              <checkbox
                v-model="reset"
                name="reset"
                label="Gauge was reset"
              />
            </v-col>
            <v-col sm="4" offset-sm="1">
              <v-text-field
                v-model="resetValue"
                v-validate="
                  `max_value:${form.reading}`
                "
                label="New base level"
                required
                dense
                type="number"
                data-vv-name="resetValue"
                name="resetValue"
                outlined
                single-line
                :hint="`New base level (${humanMeasurements})`"
                :disabled="!reset"
                :error-messages="errors.first('resetValue')"
                v-input-no-scroll="'resetValue'"
                ref="resetValue"
                aria-autocomplete="off"
                autocomplete="off"
              />
            </v-col>
          </v-row>


          <div class="form__actions">
            <v-btn 
              text
              large
              exact
              class="bare primary--text mb-3"
              role="link"
              @click="setToMulti"
            >
              Switch to Multi-log entry
            </v-btn>
            <v-btn
              type="submit" 
              color="accent"
              depressed
              rounded
              large
              class="form__submit"
              :loading="loading"
            >
              Save Log
            </v-btn>
            <v-btn 
              text
              large
              exact
              :to="{ name: 'gaugeDetail' }"
              class="bare primary--text">
              Cancel
            </v-btn>
          </div>
        </form>
      </div>
    </page-section>
  </v-container>
</template>

<script>
// import App from 'scripts/init'
import {
  NETWORK_ERROR,
  LOG_MULTI_ENTRY_COOKIE
} from '@/utils/constants'
import numbro from 'numbro'
import convert from 'convert-units'
import FormValidation from '@/mixins/FormValidation'
import GaugeConnectorMixin from '@/mixins/connectors/gauge'
// @todo this won't be needed if userId, helperId, helpName is set in store
import UserGetters from '@/mixins/vuex/UserGetters'
import NotificationsConnectorMixin from '@/mixins/connectors/notifications'
import GaugePermissions from '@/views/Gauges/mixins/GaugePermissions'
import GaugeListImage from '@/components/GaugeListImage/GaugeListImage'
import jscookie from 'js-cookie'
import scroll from 'zenscroll'
import { mapState } from 'vuex'
import { 
  dateToFull,
  dateToDay,
  dateToTime,
  dateToUTC,
  isSameOrAfter,
  relativeTime,
  addSeconds
} from '@/utils/dateUtils'

export default {
  name: 'add_log',
  components: {
    GaugeListImage
  },
  beforeRouteEnter(to, from, next) {
    const {
      params = {},
      query = {}
    } = to

    if (jscookie.get(LOG_MULTI_ENTRY_COOKIE)) {
      next({
        name: 'addMultiLog',
        params,
        query
      })
    } else {
      next()
    }
  },
  mixins: [
    GaugeConnectorMixin,
    GaugePermissions,
    UserGetters,
    FormValidation,
    NotificationsConnectorMixin
  ],
  data () {
    return {
      // primary used for multi-add to pass logs back to single-add
      startingLogs: this.$route.params.startingLogs || [],
      msg: '',
      error: false,
      image: '',
      reset: false,
      resetValue: 0,
      form: {
        logDateTime: dateToDay(),
        image: '',
        reading: '',
        notes: ''
      }
    }
  },
  computed: {
    inputDateAsUTC () {
      return dateToUTC(`${this.form.logDateTime} ${dateToTime()}`, ['MM/DD/YYYY HH:mm:ss'])
    },
    /**
     * Loop through logs desc by date for first observation
     * that input observation is greater than by date
     */
    getPreviousLog () {
      const previousLog = this.gaugeLogs.find((log) => {
        return isSameOrAfter(this.inputDateAsUTC, log.logDateTime)
      })

      return previousLog || {}
    },
    /**
     * Loop through logs asc by date for first observation
     * that is greater (by date) than input observation
     */
    getNextLog () {
      const copyLogs = this.gaugeLogs.slice().reverse()
      const nextLog = copyLogs.find((log) => {
        return isSameOrAfter(log.logDateTime, this.inputDateAsUTC)
      })

      return nextLog || {}
    },
    getNextLogDate () {
      return dateToFull(this.getNextLog.logDateTime)
    },
    getNextRelativeDate () {
      return relativeTime(this.getNextLogDate, ['MMM D YYYY h:mm a'])
    },
    getPreviousLogDate () {
      return dateToFull(this.getPreviousLog.logDateTime)
    },
    getPreviousRelativeDate () {
      return relativeTime(this.getPreviousLogDate, ['MMM D YYYY h:mm a'])
    },
    readingHint () {
      return numbro(Number(this.getPreviousReading) + 0.13).format('0.00')
    },
    getPreviousReading () {
      const reading = this.getPreviousLog.reading || '0.00'

      return this.twoDecimalPadding(convert(reading).from('in').to(this.gaugeUnits))
    },
    getNextReading () {
      const reading = this.getNextLog.reading || '0.00'

      return this.twoDecimalPadding(convert(reading).from('in').to(this.gaugeUnits))
    },
    readingInvalid () {
      const {
        items = []
      } = this.errors

      const invalid = items.find((item) => {
        return item.rule === 'min_value' || item.rule === 'max_value'
      })

      return invalid
    },
    humanMeasurements () {
      const _dict = {
        in: 'inches',
        mm: 'millimeters'
      }

      return _dict[this.gaugeUnits]
    },
    ...mapState({
      offline: state => state.offline.offline
    })
  },

  mounted () {
    // copy over starting logs from mult-add page if exist
    if (
      this.startingLogs.length &&
      this.startingLogs[0].logDateTime &&
      this.startingLogs[0].reading &&
      !this.startingLogs[0].isReset
    ) {
      this.form = {
        ...this.startingLogs[0]
      }

      if (this.startingLogs[1].isReset && this.startingLogs[1].reading) {
        this.reset = true
        this.resetValue = this.startingLogs[1].reading
      }
    }
  },

  methods: {
    setToMulti () {
      jscookie.set(LOG_MULTI_ENTRY_COOKIE, true)
      // switch over to multi log with cookie to alway redirect
      // if form has reading/reset, pass those through to mult-log
      this.$router.push({
        name: 'addMultiLog',
        params: {
          startingLogs: [
            ...(
              this.form.reading
                ? [{
                  ...this.form,
                  ...(
                    this.reset && this.form.logDateTime
                      ? { reset: true }
                      : {}
                  )
                }]
                : []
            ),
            ...(
              this.form.reading && this.reset && this.form.logDateTime
                ? [{
                    logDateTime: dateToUTC(addSeconds(`${this.form.logDateTime} ${dateToTime()}`, 1), ['MM/DD/YYYY HH:mm:ss']),
                    reading: numbro(convert(this.resetValue).from(this.gaugeUnits).to('in')).format('0.00'),
                    isReset: true
                  }]
                : []
            )
          ]
        }
      })
    },
    twoDecimalPadding (number) {
      return numbro(number).format('0.00')
    },
    async saveLog () {
      let newLog = {
        // @todo this could be set at the vuex action
        userId: this.getUserId,
        gid: this.gid,
        logDateTime: this.inputDateAsUTC,
        units: this.gaugeUnits,
        reading: numbro(convert(this.form.reading).from(this.gaugeUnits).to('in')).format('0.000'),
        image: (Array.isArray(this.form.image)) ? this.form.image[0] : this.form.image,
        notes: this.form.notes,
        isReset: !this.gaugeLogs.length
      }
      let resetLog

      if (!this.owner) {
        Object.assign(newLog, {
          helperUid: this.getUserId,
          helperName: this.getUserFullName
        })
      }

      try {
        await this.addGaugeLog(newLog)

        try {
          if (this.getPendingNotificationByGid(this.gid)) {
            await this.markNotificationAsRead(this.gid)
          }
        } catch (e) {
          console.log('Failed to mark notification as read: ', e)
        }

        if (this.reset) {
          try {
            resetLog = await this.resetGauge(this.form.logDateTime)
          } catch (e) {
            console.log('Failed to reset gauge: ', e)
          }
        }

        // recalculate and resort logs after new log(s) insert
        await this.massageLogs(newLog.gid)
        this.error = ''
        this.$router.push({
          name: 'addLogSuccess',
          params: {
            logs: [
              newLog,
              ...(resetLog ? [resetLog] : [])
            ]
          },
          // this hides "reset gauge" option on success page
          ...(this.reset ? { query: { reset: true } } : {})
        })
        setTimeout(() => { this.loading = false }, 600)
      } catch (e) {
        // TODO: how do we want to handle error handling messages?
        this.loading = false
        this.error = NETWORK_ERROR
        scroll.to(this.$el)
        console.error(e)
      }
    },
    async resetGauge (date) {
      const resetLog = {
        // @todo this could be set at the vuex action
        userId: this.getUserId,
        gid: this.gid,
        logDateTime: dateToUTC(addSeconds(`${date} ${dateToTime()}`, 1), ['MM/DD/YYYY HH:mm:ss']),
        units: this.gaugeUnits,
        reading: numbro(convert(this.resetValue).from(this.gaugeUnits).to('in')).format('0.00'),
        isReset: true
      }

      // @todo this could be set at the vuex action
      if (!this.owner) {
        Object.assign(resetLog, {
          helperUid: this.getUserId,
          helperName: this.getUserFullName
        })
      }

      return await this.addGaugeLog(resetLog)
    }
  }
}

</script>

<style lang="sass" src="./LogAdd.sass"></style>

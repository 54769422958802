<template>
  <collapse class="activity-log" ref="collapse" :value="true">
    <div slot="trigger" class="activity-log__trigger">
      <span class="activity-log__trigger-title">Activity Log</span>
      <v-icon class="activity-log__trigger-icon" size="28">keyboard_arrow_down</v-icon>
    </div>

    <div slot="content">
      <checkbox v-model="helpersOnly" name="helpersOnly" label="Helpers Only" @click.native.stop="() => {}"/>
      <activity-log-table :logs="activityLogs"/>
    </div>
  </collapse>
</template>

<script>
  import ActivityLogTable from './ActivityLogTable'
  import { mapGetters } from 'vuex'
  import { dateBeautify } from '@/utils/dateUtils'
  import { decimalDoublePad } from '@/utils/numUtils'

  let ActivityLog = {
    components: {
      ActivityLogTable
    },

    watch: {
      helpersOnly() {
        this.$refs.collapse.$refs.contentWrapper.click()
      }
    },

    data() {
      return {
        helpersOnly: false
      }
    },

    computed: {
      ...mapGetters([
        'getLogListByDateRange',
        'getGaugeById',
        'getUserId'
      ]),
      activityLogs() {
        let activity = this.getLogListByDateRange().map(log => {
          let { helperName = 'Me (The Owner)', helperUid, record = 0, gid, created, toBeSynced } = log
          const { title: gaugeName, uid: gidUid } = this.getGaugeById(gid)

          return {
            ...log,
            gidUid,
            helperName,
            helperUid,
            gaugeName: (toBeSynced) ? `${gaugeName} (sync)` : gaugeName,
            created: dateBeautify(created),
            record: `${decimalDoublePad(record)}"`
          }
        })

        // will only return MY GAUGES
        // this.helpersOnly additionally filters by logs with a "helperUid" attached
        return this.helpersOnly
          ? activity.filter(log => (log.helperUid) && this.getUserId === log.gidUid)
          : activity.filter(log => this.getUserId === log.gidUid)
      }
    },
  }

  export default ActivityLog
</script>

<template>
  <div class="spinner">
    <v-progress-circular
      :size="size"
      :width="width"
      :color="color"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
  let Spinner = {
    components: {},
    props: {
      size: {
        type: [String, Number],
        default: 28
      },
      width: {
        type: [String, Number],
        default: 5
      },
      color: {
        default: 'white'
      },
      single: Boolean
    }
  }

  export default Spinner
</script>

<style lang="sass">
  .spinner
    opacity: 0.6
</style>

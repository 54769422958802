<template>
  <div class="gauge-detail-map">
    <map-controller
      private
      :gauges="gauges"
      :public-gauges="publicGauges">
    </map-controller>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { isEmpty } from 'lodash'

  let GaugeDetailMap = {
    name: 'gauge-detail-map',

    props: {
      activeGauge: {
        type: Object,
        required: true,
        default: () => { return {} }
      }
    },

    computed: { 
      ...mapState({
        publicGauges: state => state.publicData.gauges
      }),
      // return empty array if activeGauge is empty
      gauges() {
        return isEmpty(this.activeGauge)
          ? []
          : [this.activeGauge]
      }
    }
  }

  export default GaugeDetailMap
</script>

<style lang="sass">
  .gauge-detail-map
    position: relative
    z-index: 1
    height: 350px
</style>

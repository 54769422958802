import Auth from '@aws-amplify/auth'
// import Api from '@aws-amplify/api'
import config from './config'

const {
  region,
  IdentityPoolId: identityPoolId,
  UserPoolId: userPoolId,
  AppClientId: userPoolWebClientId
} = config

Auth.configure({
  identityPoolId,
  region,
  userPoolId,
  userPoolWebClientId
})

// Api.configure({
//   endpoints: [
//     {
//       name: "mrlApi",
//       endpoint: "https://m5o5uxkby2.execute-api.us-west-2.amazonaws.com/dev"
//     }
//   ]
// })

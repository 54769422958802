/** 
 * image uploader
 */
import axios from 'axios'

/** AwsConfig.
 *  userImageBaseUrl: 'https://s3-us-west-2.amazonaws.com/data.myraingelog.arizona.edu/public/',
    dataBucket: 'data.myraingelog.arizona.edu.s3.amazonaws.com',
    dataBucketPublicFolder: 'public/',
    userImageBucketFolder: 'user/',
 */

// do this on the backend... just for ref of what's happening
// function getS3bucketKey (uid, gid, fileType, filename) {
//     console.log('getS3bucketKey fileType',fileType);
//     let name = gid//(filename) ? utils.changeSpacesToDashes(utils.removeSpecialChars(filename)) : "gauge";
//     let fileExtension = fileType.substr(fileType.lastIndexOf('/') + 1);
//     filename = name + "." + (fileExtension || "jpg");
//     return  AwsConfig.userImageBucketFolder + uid + "/" + filename//gid + "/" + filename;
// }

export function uploadImage(data) {
    console.log("uploadImage",data);
    return new Promise((resolve, reject) => {
        /**
         * data = {
                signed_request,
                imageFile,
                imageType
         * }
         */
        if (data && data.signed_request && data.imageType && typeof data.imageFile === "object") {
            delete axios.defaults.headers.common['Authorization'];
            //no need for instance with authorization header, plus it's to a diff endpoint
            // return s3Instance.put(response.data.signed_request, data.imageFile) 
            axios({
                method: 'put',
                url: data.signed_request,
                data: data.imageFile,
                timeout: 10000,
                headers: {
                    'x-amz-acl':'public-read',
                    'Content-Type': data.imageType
                },
            }).then(resp => {
                console.log("image uploaded", resp)
                resolve(resp)
            }).catch(err => {
                reject("UploadImage error caught: " + err)
            })
        } else {
            reject("UploadImage params not valid.")
        }
    })
}


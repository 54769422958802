<template>
  <v-container fluid grid-list-lg id="log_add__success" class="log-add__success pt-0">    
    <page-section headerImage>
      <gauge-list-image :src="gauge.image"/>
      <h1>{{ gauge.title }}</h1>
    </page-section>

    <page-section centered class="log-add__success">
      <h2 class="mb-4">Observation successfully added!</h2>

      <div class="log-add__success-wrapper pill mb-4 pa-8 text-left">
        <div>
          <strong>
            {{
              submittedLogs.length > 1
                ? 'New Observations:'
                : 'New Observation:'
            }}
          </strong>
        </div>

        <div
          class="d-flex justify-space-between"
          v-for="(log, index) in submittedLogs"
          :key="index"
        >
          <div>
            {{ dateBeautify(log.logDateTime) }} {{ log.isReset ? '(reset)' : '' }}
          </div>

          <div>{{ formatReading(log.reading) }} {{ gaugeUnits }}</div>
        </div>
      </div>

      <template slot="actions">
        <v-btn
          class="my-3"
          depressed
          rounded
          large
          color="accent"
          :to="{ name: 'addLog', params: { gid } }"
          title="Add Another Observation">
          Add Another Observation
          <v-icon right size="20" class="v-icon--with-circle">add</v-icon>
        </v-btn>

        <v-btn
          v-if="!$route.query.reset"
          text
          rounded
          :to="{ name: 'gaugeEmpty', params: { gid, date: submittedLogs[0].logDateTime } }"
          title="Reset Gauge"
          class="primary--text mb-0">
          Reset Gauge
        </v-btn>

        <v-btn
          text
          rounded
          exact
          title="Back to Gauge"
          :to="{ name: 'gaugeDetail', params: { gid } }"
          class="primary--text"
        >
          Back to Gauge
        </v-btn>
      </template>
    </page-section>
  </v-container>
</template>

<script>
import GaugeConnectorMixin from '@/mixins/connectors/gauge'
import GaugeListImage from '@/components/GaugeListImage/GaugeListImage'
import convert from 'convert-units'
import { decimalDoublePad } from '@/utils/numUtils'
import { dateBeautify } from '@/utils/dateUtils'

export default {
  name: 'log-add-success',

  mixins: [GaugeConnectorMixin],

  components: {
    GaugeListImage
  },

  beforeRouteEnter(to, from, next) {
    if (!to.params.log && !to.params.logs) {
      next({ name: 'dashboard' })

      return
    }

    next()
  },

  computed: {
    submittedLogs () {
      const logs = (this.$route.params.logs || []).slice().sort((a, b) => {
        const aDate = a.logDateTime
        const bDate = b.logDateTime

        if (aDate < bDate) {
          return -1
        }

        if (aDate > bDate) {
          return 1
        }
        // a must be equal to b
        return 0
      })

      return logs
    }
  },

  methods: {
    dateBeautify,
    formatReading (reading) {
      return decimalDoublePad(convert(reading).from('in').to(this.gaugeUnits))
    },
  }
}
</script>

<style lang="sass" src="./LogAddSuccess.sass"></style>

<template>
  <div class="public-map">
    <map-controller
      :gauges="[]"
      :public-gauges="publicGauges">
    </map-controller>
  </div>
</template>

<script>
import DisplayManagerMixin from '@/mixins/DisplayManagerMixin'
import { mapState } from 'vuex'

let PublicMap = {
  name: 'public-map',

  mixins: [DisplayManagerMixin],

  computed: { 
    ...mapState({
      publicGauges: state => state.publicData.gauges
    })
  }
}

export default PublicMap
</script>

<style lang="sass">
  .public-map
    position: relative
    z-index: 1
    height: 450px
</style>


<template>
  <v-container fluid grid-list-lg id="results" class="results pt-0">
    <page-section theme="blue" centered :loading="publicGaugesLoading">
      <h1>Results that reveal</h1>
      <p>Public rainfall records logged by our community. Interact with the map to learn more!</p>
      <br />
      <public-map v-lock-on-scroll/>
    </page-section>

    <the-numbers/>

    <get-started/>
  </v-container>
</template>

<script>
  import TheNumbers from '@/components/TheNumbers/TheNumbers'
  import GetStarted from '@/components/GetStarted/GetStarted'
  import PublicMap from '@/components/PublicMap/PublicMap'
  import DisplayManagerMixin from '@/mixins/DisplayManagerMixin'
  import { mapMutations } from 'vuex'

  let PublicMapPage = {
    components: {
      GetStarted,
      PublicMap,
      TheNumbers
    },

    mixins: [DisplayManagerMixin],

    data () {
      return {
        title: "Results that reveal",
        tagline: "Public rainfall records displayed by the numbers and on a map.",
        msg: ''
      }
    }
  }

  export default PublicMapPage
</script>

import authRequest from './utils/authRequest'

const ENDPOINT = '/reports'

const reportsApi = {
  fetch(uid) {
    if (!uid) throw new Error('[reportsApi] fetch: uid parameter is required')

    return authRequest({
      url: `${ENDPOINT}/${uid}`,
      method: 'get'
    }).then(result => {
      let { data = {} } = result

      return data
    })
  },

  get({ uid, rid }) {
    if (!uid || !rid) throw new Error('[reportsApi] get: missing required parameter')

    return authRequest({
      url: `${ENDPOINT}/${uid}/${rid}`,
      method: 'get'
    }).then(result => {
      let { data = {} } = result

      return data
    })
  },

  create({ uid, ...payload }) {
    if (!uid) throw new Error('[reportsApi] post: uid parameter is required')

    return authRequest({
      url: `${ENDPOINT}/${uid}`,
      method: 'post',
      data: payload
    }).then(result => {
      let { data = {} } = result

      return data
    })
  },

  update({ uid, rid, ...payload }) {
    if (!uid || !rid) throw new Error('[reportsApi] put: missing required parameter')

    return authRequest({
      url: `${ENDPOINT}/${uid}/${rid}`,
      method: 'put',
      data: payload
    }).then(result => {
      let { data = {} } = result

      return data
    })
  },

  delete({ uid, rid }) {
    if (!uid || !rid) throw new Error('[reportsApi] delete: missing required parameter')

    return authRequest({
      url: `${ENDPOINT}/${uid}/${rid}`,
      method: 'delete'
    }).then(result => {
      let { data = {} } = result

      return data
    })
  }
}

export default reportsApi
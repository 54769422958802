<template>
  <v-container fluid class="top-nav">
    <router-link :to="{ name: 'home' }" class="top-nav__logo" title="myRAINge Log Home Page">
      <img src="/img/mrl-logo.svg" alt="myRAINge Log Logo"/>
    </router-link>

    <drawer
      id="primaryNav"
      class="top-nav__drawer"
    >
      <img
        src="/img/mrl-logo-white.svg" 
        class="top-nav__user-drawer-nav-logo"
        alt="myRAINge Log Logo"
      >
      <template slot="trigger"><v-icon color="primary" class="drawer__trigger" :size="menuIconSize">menu</v-icon></template>
      <nav class="top-nav__user-drawer-nav">
        <template v-if="!isLoggedIn">
          <router-link to="/login" class="top-nav__user-drawer-nav__login" v-close-drawer>
            <v-icon color="white" class="ma-0">{{ $vuetify.icons.values.mrl_login }}</v-icon>
            Log In
          </router-link>
          <router-link to="/signup" v-close-drawer>Sign Up</router-link>
        </template>
        <template v-else>
          <a class="top-nav__user-drawer-nav__login" style="cursor: default; pointer-events: none;">
            <v-icon color="white" class="ma-0">{{ $vuetify.icons.values.mrl_login }}</v-icon>
            {{ getUserFullName || userEmailName }}
          </a>
          <router-link to="/dashboard" class="primary--text text--lighten-1" v-close-drawer>Gauges</router-link>
          <router-link :to="{ name: 'reports' }" class="primary--text text--lighten-1" v-close-drawer>Reports</router-link>
          <router-link :to="{ name: 'helpers' }" class="primary--text text--lighten-1" v-close-drawer>Helpers</router-link>
          <router-link :to="{ name: 'export' }" class="primary--text text--lighten-1" v-close-drawer>Export</router-link>
          <router-link :to="{ name: 'account' }" class="primary--text text--lighten-1" v-close-drawer>Account</router-link>
          <router-link to="//info.myraingelog.arizona.edu" target="_blank" class="primary--text text--lighten-1" v-close-drawer>Help</router-link>
          <router-link to="/logout" class="primary--text text--lighten-1" v-close-drawer>Logout</router-link>
        </template>
      </nav>
      <nav class="top-nav__main-drawer-nav">
        <router-link to="/public/map" class="primary--text text--lighten-1" v-close-drawer>Public Map</router-link>
        <router-link to="/support" class="primary--text text--lighten-1" v-close-drawer>Support</router-link>
        <router-link to="/about" class="primary--text text--lighten-1" v-close-drawer>About</router-link>
        <router-link to="/home" class="primary--text text--lighten-1" v-close-drawer>Home</router-link>
      </nav>
    </drawer>

    <nav class="top-nav__main-nav">
      <router-link to="/public/map" class="top-nav__link">Public Map</router-link>
      <router-link to="/support" class="top-nav__link">Support</router-link>
      <router-link to="/about" class="top-nav__link">About</router-link>
    </nav>

    <nav class="top-nav__secondary-nav">
      <template v-if="isLoggedIn">
        <router-link :to="{ name: 'dashboard' }" class="top-nav__link">Gauges</router-link>
        <router-link :to="{ name: 'reports' }" class="top-nav__link">Reports</router-link>
        <v-menu
          content-class="top-nav__secondary-nav__menu"
          min-width="100"
          dark
          left
          bottom
          offset-y
        >
          <template v-slot:activator="{ on }">
            <div
              class="top-nav__link pl-2"
              v-on="on"
            >
              <v-icon color="primary">
                {{ $vuetify.icons.values.mrl_login }}
              </v-icon>
            </div>
          </template>

          <v-list dense>
            <v-subheader class="text-uppercase">
              {{ getUserFullName || userEmailName }}
            </v-subheader>
            <v-divider />
            <v-list-item :to="{ name: 'account' }">
              <v-list-item-title>Account</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'export' }">
              <v-list-item-title>Export</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'helpers' }">
              <v-list-item-title>Helpers</v-list-item-title>
            </v-list-item>
            <v-list-item to="//info.myraingelog.arizona.edu" target="_blank">
              <v-list-item-title>Help</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'logout' }">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-else>
        <router-link to="/signup" class="top-nav__link">Sign Up</router-link>
        <span class="top-nav__link-in-between">Or</span>
        <router-link to="/login" class="top-nav__link top-nav__link--login mr-2">
          Log In
          <v-icon color="primary">
            {{ $vuetify.icons.values.mrl_login }}
          </v-icon>
        </router-link>
      </template>
    </nav>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

const TopNav = {
  props: {
    isLoggedIn: Boolean
  },
  data () {
    return {
      menuIconSize: 30,
      menuTarget: 'userMenu'
    }
  },
  computed: {
    ...mapGetters([
      'getUserEmail',
      'getUserFullName'
    ]),
    userFirstName () {
      return this.getUserFullName.split(' ')[0]
    },
    userEmailName () {
      return this.getUserEmail.split('@')[0]
    }
  }
}

export default TopNav
</script>

<style lang="sass" src="./TopNav.sass"></style>

<template>
  <div
    :class="[
      'image-input',
      compact && 'image-input--compact'
    ]"
  >
    <div class="image-input__mock" v-if="!imageSrc">
      <div class="image-input__button" @click="triggerFileInput">
        <slot name="button">
          <v-btn
            v-if="compact"
            outlined
            x-small
            fab
            color="accent"
            @click.prevent
            class="ma-0"
          >
            <v-icon>
              photo_camera
            </v-icon>
          </v-btn>

          <v-btn
            v-else
            outlined
            :small="small"
            rounded
            text
            color="accent"
            @click.prevent
            class="ma-0"
          >
            Add Photo 
            <v-icon
              right
              size="18"
              class="ml-1"
            >
              photo_camera
            </v-icon>
          </v-btn>
        </slot>
      </div>
    </div>

    <div class="image-input__preview-wrapper" v-else>
      <div class="image-input__preview">
        <slot name="preview" :src="imageSrc">
          <img :src="imageSrc" v-if="imageSrc" class="image-input__preview-img"/>
        </slot>

        <div
          v-if="!compact"
          class="image-input__edit"
          role="button"
          @click="triggerFileInput"
        >
          Change Photo
        </div>
      </div>

      <v-btn v-if="!noRemove"
        color="accent" 
        icon
        @click.stop="removeImage"
        small
        class="image-input__remove">
        <v-icon color="white">close</v-icon>
      </v-btn>
    </div>

    <input
      type="file" 
      :id="name" 
      :ref="name"
      :name="name"
      class="image-input__input"
      accept="image/*"
      @change="onFileChange"
      tabindex="-1"
    />

    <div class="image-input__error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
  export default {
    name: 'image-input',
    props: {
      name: {
        type: String,
        required: true
      },
      maxFileSizeKb: {
        type: [String, Number],
        default: 5000 // 3Mb
      },
      extensions: {
        type: Array,
        default: () => { return [".jpg", ".jpeg", ".png", ".gif"]}
      },
      small: Boolean,
      compact: Boolean,
      src: [String, Number],
      noRemove: Boolean
    },
    data() {
      return {
        image: '',
        imageSrc: '',
        error: '',
        iconSize: (this.small) ? 25 : 30
      }
    },
    mounted() {
      this.$nextTick(function () {
        this.imageSrc = this.src
      })
    },
    computed: {
      maxFileSizeBytes () { return this.maxFileSizeKb * 1024 },
    },
    methods: {
      triggerFileInput(e) {
        this.$refs[this.name] && this.$refs[this.name].click()
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        this.image = files[0] || {}
        let {name, size, type} = this.image

        if (!files.length || !name.length) return false

        if (!this.isValidExtension(name, this.extensions)) {
          this.error = "Sorry, " + name + " is invalid - allowed extensions are: " + this.extensions.join(", ")
          e.target.value = ''

          return false
        }

        if (size > this.maxFileSizeBytes) {
          this.error = "Photo file size must be less than " + this.maxFileSizeKb + " kB - your file is " + Math.round(size / 1024) + " kB"
          e.target.value = ''
          return false
        }

        this.error = ''
        this.createImage(this.image)
      },
      createImage(file) {
        let reader = new window.FileReader()
        let vm = this

        reader.onload = (e) => {
          vm.imageSrc = e.target.result
          vm.$emit('input', vm.image)
          vm.$emit('src-change', vm.imageSrc)
        }

        reader.readAsDataURL(file)
      },
      removeImage: function (e) {
        this.error = ''
        this.image = ''
        this.imageSrc = ''
        this.$refs[this.name].value = ''
        this.$emit('input', this.image)
        this.$emit('src-change', this.imageSrc)
      },
      isValidExtension(name, extensions = []) {
        let imageExtension = name.toLowerCase().split('.')[1]
        return !!extensions.toString().toLowerCase().includes(imageExtension)
      }
    }
  }
</script>

<style lang="sass" src="./ImageInput.sass"></style>

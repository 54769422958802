var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"empty-gauge"},[_vm._m(0),_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('strong',[_vm._v("Date of Reset")])]),_c('v-col',{attrs:{"cols":"12","sm":"4","offset-sm":"1"}},[_c('date-menu',{directives:[{name:"validate",rawName:"v-validate",value:('validDate'),expression:"'validDate'"}],attrs:{"error-messages":_vm.errors.collect('date'),"data-vv-name":"date","name":"date","label":"Date","hide-actions":"","text-field-props":{
          hideDetails: true,
          appendIcon: 'event',
          outline: true,
          singleLine: true
        }},model:{value:(_vm.innerDate),callback:function ($$v) {_vm.innerDate=$$v},expression:"innerDate"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('strong',[_vm._v("Reset base level (in "+_vm._s(_vm.humanMeasurements)+")")])]),_c('v-col',{attrs:{"cols":"12","sm":"4","offset-sm":"1"}},[_c('v-text-field',{attrs:{"label":"New Baseline","type":"number","data-vv-name":"newBaseLine","name":"newBaseLine","hide-details":"","outlined":"","single-line":""},on:{"blur":_vm.onMeasurementBlur},model:{value:(_vm.newBaseline),callback:function ($$v) {_vm.newBaseline=$$v},expression:"newBaseline"}})],1)],1),_c('div',{staticClass:"empty-gauge__actions",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"depressed":"","rounded":"","large":"","color":"accent","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.formSubmit(_vm.saveEmptyRecord)}}},[_vm._v(" "+_vm._s(_vm.btnText)+" ")])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Choose this option if you emptied your rain gauge to a "),_c('strong',[_c('em',[_vm._v("lower level")])]),_vm._v(". This new base level level will be used to calculate accumulation. ")])
}]

export { render, staticRenderFns }
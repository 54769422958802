<template>
  <l-feature-group 
    :order="2"
    layer-name="public-gauges">
    <l-marker v-for="gauge in gauges"
      :events="['click']"
      v-on:click="handleClick"
      :key="gauge.id"
      :latlng="getLatLng(gauge)"
      :options="getOptions(gauge)"/>
  </l-feature-group>
</template>

<script>
  import { 
    publicGaugeIcon
  } from '@/utils/leafletUtils'

  import Leaflet from 'leaflet'
  import { mapActions } from 'vuex'

  export default {
    name: 'public-gauges-layer',

    created() {
      this.$emit('loading', true)

      this.getPublicGauges()
        .then(() => {
          this.$emit('loading', false)
        })
    },

    props: {
      gauges: {
        type: Array,
        default: () => ([])
      }
    },

    methods: {
      ...mapActions(['getPublicGauges']),
      getLatLng(gauge) {
        try {
          const {
            latitude,
            longitude
          } = gauge

          const latLng = Leaflet.latLng(latitude, longitude)

          return latLng
        } catch(e) {
          console.error(e)

          return []
        }
      },
      getOptions(gauge) {
        let {
          latitude,
          longitude,
          ...rest
        } = gauge

        return {
          public: true,
          icon: publicGaugeIcon(),
          ...rest
        }
      },
      handleClick(gauge) {
        this.$emit('gauge-click', gauge)
      }
    }
  }
</script>

import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)
export const COMMON_DATE_FORMAT = 'MM/DD/YYYY'
export const ACCEPTED_FORMATS = [
  'MM/DD/YYYY',
  'DD/MM/YYYY',
  'MM-DD-YYYY',
  'DD-MM-YYYY',
  'MMDDYYYY',
  'DDMMMYYYY',
  'YYYY-MM-DD',
  'YYYY-MM-DDTHH:mm:ssZZ'
]

export function isValidDate (date) {
  return moment(date, ACCEPTED_FORMATS, true).isValid()
}

export function dateToUTC (date, rules = []) {
  if (!date) return moment().utc().format()

  return (Array.isArray(rules) && rules.length)
    ? moment(date, rules).utc().format()
    : moment(date).utc().format()
}

export function dateToLocal (date, rules = []) {
  return (Array.isArray(rules) && rules.length)
    ? moment(date, rules).format()
    : moment(date).format()
}

export function dateToTime (date) {
  return moment(date).format('HH:mm:ss')
}

export function dateToMonth (date) {
  return moment(date).format('MMM D')
}

export function dateToYear (date) {
  return moment(date).format('YYYY')
}

export function dateToDay (date, rules = []) {
  return (Array.isArray(rules) && rules.length)
    ? moment(date, rules).format(COMMON_DATE_FORMAT)
    : moment(date).format(COMMON_DATE_FORMAT)
}

export const datePicker = (date = new Date(), rules = []) => {
  return (rules.length)
    ? moment(date, rules).format('YYYY-MM-DD')
    : moment(date).format('YYYY-MM-DD')
}

export function dateToDayShort (date, rules = []) {
  return (Array.isArray(rules) && rules.length)
    ? moment(date, rules).format(COMMON_DATE_FORMAT)
    : moment(date).format(COMMON_DATE_FORMAT)
}

export function dateBeautify (date, rules) {
  return (Array.isArray(rules) && rules.length)
    ? moment(date, rules).format('MMM DD YYYY')
    : moment(date).format('MMM DD YYYY')
}

export function rccDateFormat (date) {
  return moment(date).format('YYYY-MM-DD')
}

export function dateToFull (date) {
  return moment(date).format('MMM D, YYYY h:mm a')
}

export function isSame (date, compared, by = 'day') {
  let _date = moment(date).format()
  let _compared = moment(compared).format()

  return moment(_date).isSame(_compared, by)
}

export function isSameOrAfter (date, compared) {
  let _date = moment(date).format()
  let _compared = moment(compared).format()

  return moment(_date).isSameOrAfter(_compared)
}

export function isSameOrBefore (date, compared) {
  let _date = moment(date).format()
  let _compared = moment(compared).format()

  return moment(_date).isSameOrBefore(_compared)
}

export function isBetween (date, from, to) {
  try {
    return moment(date).isSameOrAfter(from) && moment(date).isSameOrBefore(to)
  } catch(e) {
    let errorMessage = `Chances are your dates aren't formatted correcty: ${e}`
    throw new Error(errorMessage)
  }
}

export function fromToDateArray (from, to, format='') {
  // output an array of moment date objects based on a start and end date
  // https://github.com/gf3/moment-range
  try {
    let range = moment.range(from, to)
    let acc = Array.from(range.by('day'))

    return acc.map((m) => m.format(format))
  } catch(e) {
    let errorMessage = `Issue create Date array. Make sure your from and to dates are formatted correctly: ${e}`
    throw new Error(errorMessage)
  }
}

export function dateDifference (from, to, by = 'days', float = false) {
  var a = moment(dateToDay(from))
  var b = moment(dateToDay(to))

  return b.diff(a, by, float)
}

export function startOfDay (count = 1) {
  return moment().add(count, 'd').startOf('day').toDate()
}

export function subtractDays (date, days) {
  return moment(date).subtract(days, 'days')
}

export function addDays (date, days) {
  return moment(date).add(days, 'days')
}

export function addSeconds (date, seconds) {
  return moment(date).add(seconds, 'seconds')
}

export function endOfDay (date) {
  return moment(date).endOf('day')
}

export function relativeTime (date, rules) {
  return (Array.isArray(rules) && rules.length)
    ? moment(date, rules).fromNow()
    : moment(date).fromNow()
}

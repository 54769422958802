<template>
  <v-menu
    ref="menu"
    v-model="menu"
    v-bind="defaultMenuProps"
    :class="getClasses"
    content-class="date-menu__content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="input"
        v-model="dateInput"
        class="white date-menu"
        outlined
        single-line
        dense
        v-bind="{ ...defaultInputProps, ...attrs }"
        v-on="on"
        @keydown.tab="menu = false"
        @focus="handleFocus"
        @blur="handleBlur"
        @click:append="defaultInputProps.appendIcon ? handleIconClick() : () => {}"
      />
    </template>
    <v-date-picker
      v-model="datePickerValue"
      v-bind="defaultPickerProps"
      no-title
      @input="menu = false"
    />
  </v-menu>
</template>

<script>
import {
  ACCEPTED_FORMATS,
  isValidDate,
  dateToDay,
  datePicker
} from '@/utils/dateUtils'

export default {
  name: 'DateMenu',
  props: {
    dense: Boolean,
    menuProps: {
      type: Object,
      default: () => ({})
    },
    disableMenu: Boolean,
    errorMessages: {
      type: Array,
      default: () => ([])
    },
    label: String,
    name: String,
    value: null,
    clearable: Boolean,
    // formats the date for date.sync
    // and formats the date for $emit('save')
    returnFormat: {
      type: Function,
      default: (val) => {
        return dateToDay(val)
      }
    },
    // how to display the date in the input field
    displayFormat: {
      type: Function,
      default: (val) => {
        return val ? dateToDay(val, ACCEPTED_FORMATS) : null
      }
    },
    disabled: Boolean,
    // pass in VTextProps
    // https://vuetifyjs.com/en/components/text-fields
    textFieldProps: {
      type: Object,
      default: () => ({})
    },
    // pass in VDatePicker props
    // https://vuetifyjs.com/en/components/date-pickers
    pickerProps: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      // this.datePickerValue is bound to the date pick
      // Vuetify datePicker requires format to be YYYY-MM-DD
      datePickerValue: null,
      dateInput: null,
      isFocus: false,
      // boolean to hide/show dialog window
      menu: false,
      innerErrorMessages: []
    }
  },

  computed: {
    defaultPickerProps () {
      return {
        color: 'secondary',
        width: this.dense ? 240 : 290,
        ...this.pickerProps
      }
    },
    defaultMenuProps () {
      return {
        closeOnContentClick: false,
        transition: 'scale-transition',
        offsetY: true,
        attach: false,
        maxWidth: this.dense ? '240px' : '290px',
        maxHeight: this.dense ? '290px' : 'auto',
        minWidth: 'auto',
        color: 'secondary',
        openOnFocus: false,
        ...(this.disableMenu ? {
          value: false,
          openOnClick: false,
          openOnFocus: false,
          openOnHover: false
        } : {}),
        ...this.menuProps
      }
    },
    isValidDate () {
      return this.dateInput ? isValidDate(this.dateInput) : true
    },
    defaultInputProps () {
      return {
        label: this.label,
        name: this.name,
        disabled: this.disabled,
        appendIcon: 'event',
        errorMessages: [
          ...this.errorMessages,
          ...(this.isValidDate ? [] : ['Invalid Date'])
        ],
        ...this.textFieldProps
      }
    },
    getClasses () {
      return {
        'date-menu': true,
        'date-menu--dense': this.dense
      }
    }
  },

  watch: {
    // "date" is the value syncing from the parent
    value (val) {
      if (!this.isFocus) {
        this.dateInput = (val) ? this.displayFormat(val) : null
        this.datePickerValue = datePicker(this.dateInput)
      }
    },
    dateInput (val) {
      this.$emit('input', this.returnFormat(val))
    },
    datePickerValue (val) {
      this.dateInput = (val) ? this.displayFormat(val) : null
    }
  },

  mounted () {
    this.dateInput = (this.value) ? this.displayFormat(this.value) : null
    this.datePickerValue = (this.value) ? datePicker(this.value, ACCEPTED_FORMATS, true) : null
  },

  methods: {
    clear () {
      this.datePickerValue = null
    },
    handleFocus () {
      this.isFocus = true
    },
    handleIconClick () {
      const {
        input: {
          $refs: {
            input
          } = {}
        } = {}
      } = this.$refs

      input.click()
      input.focus()
    },
    handleBlur () {
      this.isFocus = false
      if (this.isValidDate && this.dateInput) {
        this.dateInput = this.displayFormat(this.dateInput)
        this.datePickerValue = datePicker(this.dateInput)
      }
    }
  }
}
</script>

<style lang="sass">
  .date-picker
    .v-text-field
      .v-icon
        color: $theme-accent

  .date-picker--disabled
    .v-input__slot
      border-style: dashed !important
    .v-dialog__activator
      pointer-events: none

  .date-picker__clear
    position: absolute
    right: -8px
    top: 8px

  .date-menu--dense
    .v-date-picker-header
      padding-left: 5px
      padding-right: 5px
    .date-menu__content
      .v-date-picker-table table
        width: unset
      .v-date-picker-table--date td
        width: 42px
      .v-date-picker-table--date .v-btn
        width: 27px
        height: 27px
</style>

<template>
  <v-container
    id="helpers-edit"
    fluid
    class="helpers-edit pt-0"
  >
    <page-section
      header-image
      :header-image-icon="$vuetify.icons.values.mrl_add_user"
    >
      <h1>Edit Helper</h1>
    </page-section>

    <page-section
      centered
      slim
      :overlay-loading="loadingHelpers && !gaugesReady"
    >
      <helpers-form
        v-if="!loadingHelpers && gaugesReady"
        edit
        :helper="helper"
        :gauges="gauges"
      />
    </page-section>
  </v-container>
</template>

<script>
import HelpersForm from '@/components/HelpersForm/HelpersForm'
import DisplayManager from '@/mixins/DisplayManagerMixin'
import GaugesConnectorMixin from '@/mixins/connectors/gauges'
import { mapGetters } from 'vuex'

const HelpersEdit = {
  components: {
    HelpersForm
  },

  mixins: [
    DisplayManager,
    GaugesConnectorMixin
  ],

  computed: {
    ...mapGetters(['getHelperByUid']),
    helper () {
      return this.getHelperByUid(this.$route.params.id)
    }
  }
}

export default HelpersEdit
</script>

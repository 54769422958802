<template>
  <div class="gauge-list-image">
    <div 
      class="gauge-list-image__bg" 
      :style="{
        backgroundImage: `url(${src})`
      }"
      v-if="src"></div>
      <v-icon color="white" v-else>{{ $vuetify.icons.values.mrl_gauge_placeholder }}</v-icon>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'gauge-list-image',
    props: {
      src: [String, Boolean]
    }
  }
</script>

<style lang="sass" src="./GaugeListImage.sass"></style>
<template>
  <v-container
    id="home"
    class="pt-0"
    fluid
  >
    <page-section header>
      <h1>Local precipitation records support better management</h1>
      <p>An easy-to-use tool to help you track your actual rainfall so you can better manage your land.</p>

      <!-- use router-link component for navigation. -->
      <!-- specify the link by passing the `to` prop. -->
      <!-- <router-link> will be rendered as an `<a>` tag by default -->
      <template slot="actions">
        <v-btn
          v-if="isAuthenticated"
          :to="{ name: 'dashboard' }"
          depressed
          rounded
          large
          color="accent"
        >
          Go to my Dashboard
        </v-btn>
        <v-btn
          v-else
          :to="{ name: 'signup' }"
          depressed
          rounded
          large
          color="accent"
        >
          Get Started
        </v-btn>
      </template>
    </page-section>

    <page-section centered>
      <h2 class="mb-5">Clear Benefits</h2>
      <v-row>
        <v-col cols="12" sm="6" md="4" class="benefit">
          <div class="benefit__icon"><v-icon color="white" size="100">{{ $vuetify.icons.values.mrl_local_observation }}</v-icon></div>
          <h3 class="benefit__head">Local Observations</h3>
          <p class="benefit__text">Site-specific is more accurate than an estimate from other locations</p>
        </v-col>

        <v-col cols="12" sm="6" md="4" class="benefit">
          <div class="benefit__icon"><v-icon color="white" size="100">{{ $vuetify.icons.values.mrl_better_management }}</v-icon></div>
          <h3 class="benefit__head">Better Management</h3>
          <p class="benefit__text">Make better decisions based on site-specific precipitation records</p>
        </v-col>
        
        <v-col cols="12" sm="6" md="4" class="benefit">
          <div class="benefit__icon"><v-icon color="white" size="100">{{ $vuetify.icons.values.mrl_precipitation_report }}</v-icon></div>
          <h3 class="benefit__head">Precipitation Report</h3>
          <p class="benefit__text">Generate custom report of your observations</p>
        </v-col>

        <v-col cols="12" sm="6" md="4" class="benefit">
          <div class="benefit__icon"><v-icon color="white" size="100">{{ $vuetify.icons.values.mrl_full_control }}</v-icon></div>
          <h3 class="benefit__head">Full Control</h3>
          <p class="benefit__text">Share only what you want with whom you want</p>
        </v-col>
        
        <v-col cols="12" sm="6" md="4" class="benefit">
          <div class="benefit__icon"><v-icon color="white" size="100">{{ $vuetify.icons.values.mrl_save_time }}</v-icon></div>
          <h3 class="benefit__head">Save Time</h3>
          <p class="benefit__text">No messy spreadsheets, view all your records on any device in an instant</p>
        </v-col>
        
        <v-col cols="12" sm="6" md="4" class="benefit">
          <div class="benefit__icon"><v-icon color="white" size="100">{{ $vuetify.icons.values.mrl_free_easy }}</v-icon></div>
          <h3 class="benefit__head">Free and Easy</h3>
          <p class="benefit__text">Simple to use and no cost</p>
        </v-col>
      </v-row>
    </page-section>

    <the-numbers title="Real Results">
      <template slot="actions">
        <v-btn
          :to="{ name: 'publicMap' }"
          depressed
          rounded
          large
          color="accent">
          See our public map
        </v-btn>
      </template>
    </the-numbers>

    <page-section slim centered>
      <h2>About this tool</h2>
      <p>Ranchers and land managers in central Arizona expressed a need for an online tool specifically designed around the type of infrequent, cumulative precipitation observations often collected at remote, rangeland sites. MyRAINge Log delivers useful data in a visual way to support better land management. This tool was conceived by extension scientists and built by web developers and designers in the College of Agriculture and Life Sciences at the University of Arizona in partnership with and under the guidance of ranchers and land managers across central Arizona.</p>
      <template slot="actions">
        <v-btn
          :to="{ name: 'about' }"
          depressed
          rounded
          large
          color="accent">
          More Background
        </v-btn>
      </template>
    </page-section>

    <page-section slim centered>
      <h2>Helpful Tips</h2>
      <p>Find helpful articles, practical guides, and training videos on our knowledge base website.</p>
      <template slot="actions">
        <v-btn
          href="//info.myraingelog.arizona.edu"
          target="_blank"
          rel="noopener"
          depressed
          rounded
          large
          color="accent">
          Get Some Help
        </v-btn>
      </template>
    </page-section>

    <get-started/>
  </v-container>
</template>

<script>
import TheNumbers from '@/components/TheNumbers/TheNumbers'
import GetStarted from '@/components/GetStarted/GetStarted'
import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: {
    GetStarted,
    TheNumbers
  },
  mounted () {
    
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" src="./Home.sass"></style>

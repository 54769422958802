import Vue from 'vue'
import NotificationsMutations from '../vuex/NotificationsMutations'
import NotificationsGetters from '../vuex/NotificationsGetters'

const privateStore = Vue.observable({
  loading: false,
  fetched: false
})

export default {
  mixins: [
    NotificationsMutations,
    NotificationsGetters
  ],

  created () {
    this.fetchNotifications()
  },

  computed: {
    notificationsLoading: {
      get () {
        return privateStore.loading
      },
      set (val) {
        privateStore.loading = val
      }
    },
    notificationsFetched: {
      get () {
        return privateStore.fetched
      },
      set (val) {
        privateStore.fetched = val
      }
    },
    notificationsReady () {
      return !this.notificationsLoading && this.notificationsFetched
    },
    notificationSettings () {
      const {
        $route: {
          params: {
            gid: routeGid
          } = {}
        } = {},
        gauge: {
          gid: gaugeGid
        } = {}
      } = this

      const gid = routeGid || gaugeGid
      return this.getNotificationByGid(gid)
    }
  },

  methods: {
    async fetchNotifications () {
      if (!this.notificationsLoading && !this.notificationsFetched) {
        this.notificationsLoading = true
        await this.listNotifications()
        this.notificationsLoading = false
        this.notificationsFetched = true
      }
    }
  }
}

<template>
  <div id="gauge">
    <transition :name="transitionName" mode="out-in">
      <router-view :key="$route.params.gid"></router-view>
    </transition>
  </div>
</template>

<script>
import DisplayManagerMixin from '@/mixins/DisplayManagerMixin'
import GaugesConnectorMixin from '@/mixins/connectors/gauges'
import {
  ERROR_GAUGE_404
} from '@/utils/constants'
import { isEmpty } from 'lodash'

const Gauge = {
  name: 'gauge',

  mixins: [DisplayManagerMixin, GaugesConnectorMixin],

  watch: {
    '$route.params.gid': {
      immediate: true,
      handler () {
        if (isEmpty(this.gauge)) {
          this.$router.replace({ name: 'dashboard', params: { error: ERROR_GAUGE_404 }})

          throw new Error(ERROR_GAUGE_404)
        }
      }
    },
    '$route' (to, from) {
      this.transitionName = to.params.transition || 'fade'
    }
  },

  data () {
    return {
      transitionName: 'fade'
    }
  }
}

export default Gauge
</script>

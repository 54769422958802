<template>
  <v-container fluid grid-list-lg id="solutions" class="solutions pt-0">
    <page-section header>
      <h1>Site-specific precip tracking</h1>
      <p>Support management decisions with this free and easy tool.</p>
    </page-section>
    <page-section centered>
      <h2>Rain Gauge Profile</h2>
      <!-- TODO - update screenshots -->
      <v-row class="align-center">
        <v-col cols="12" sm="6">
          <phone-image>
            <img src="./images/Rain-Gauge-Profile.png" alt="" slot="image">
          </phone-image>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row class="min-list align-center" tag="ul">
            <v-col cols="12" sm="6" class="min-list__item" tag="li">identify each rain gauge</v-col>
            <v-col cols="12" sm="6" class="min-list__item" tag="li">capture photo of site</v-col>
            <v-col cols="12" sm="6" class="min-list__item" tag="li">describe area, terrain, conditions</v-col>
            <v-col cols="12" sm="6" class="min-list__item" tag="li">enter rainfall amount and date</v-col>
          </v-row>
        </v-col>
      </v-row>
    </page-section>
    <page-section centered>
      <h2>GAUGE MAPPING</h2>
      
      <v-row class="align-center">
        <v-col cols="12" sm="6">
          <phone-image>
            <img src="./images/Gauge-Mapping.png" alt="" slot="image">
          </phone-image>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row class="min-list align-center" tag="ul">
            <v-col cols="12" sm="6" class="min-list__item" tag="li">SEE ALL OF YOUR GAUGES IN ONE PLACE</v-col>
            <v-col cols="12" sm="6" class="min-list__item" tag="li">GET LAST RAINFALL RECORD AND TOTAL ACCUMULATION</v-col>
            <v-col cols="12" sm="6" class="min-list__item" tag="li">SMART MAP LOCATES YOU AND YOUR NEAREST RAIN GAUGES</v-col>
          </v-row>
        </v-col>
      </v-row>
    </page-section>

    <page-section centered>
      <h2>CHART RAINFALL</h2>

      <v-row class="align-center">
        <v-col cols="12" sm="6">
          <phone-image>
            <img src="./images/Chart-Rainfall.png" alt="" slot="image">
          </phone-image>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row class="min-list align-center" tag="ul">
            <v-col cols="12" sm="6" class="min-list__item" tag="li">TRACK EACH RAIN GAUGE</v-col>
            <v-col cols="12" sm="6" class="min-list__item" tag="li">COMPARE TO REAL-TIME ESTIMATES</v-col>
            <v-col cols="12" sm="6" class="min-list__item" tag="li">COMPARE TO HISTORICAL VALUES</v-col>
            <v-col cols="12" sm="6" class="min-list__item" tag="li">IDENTIFY POTENTIAL DROUGHT CONDITIONS</v-col>
          </v-row>  
        </v-col>
      </v-row>
    </page-section>

    <page-section centered>
      <h2>RAINFALL REPORT</h2>
      
      <v-row class="align-center">
        <v-col cols="12" sm="6">
          <phone-image>
            <img src="./images/Rainfall-Report.png" alt="" slot="image">
          </phone-image>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row class="min-list align-center" tag="ul">
            <v-col cols="12" sm="6" class="min-list__item" tag="li">VIEW RAINFALL HISTORY INCLUDING TOTAL ACCUMULATION</v-col>
            <v-col cols="12" sm="6" class="min-list__item" tag="li">COMPARE YOUR RAIN GAUGES</v-col>
            <v-col cols="12" sm="6" class="min-list__item" tag="li">DOWNLOAD CSV, SAVE AS PDF, OR PRINT</v-col>
          </v-row>  
        </v-col>
      </v-row>
    </page-section>  
    <get-started/>
  </v-container>
</template>

<script>
import GetStarted from '@/components/GetStarted/GetStarted'
import PhoneImage from '@/components/PhoneImage/PhoneImage'

const Solutions = {
  name: 'Solutions',
  components: {
    GetStarted,
    PhoneImage
  }
}

export default Solutions
</script>

<style lang="sass" src="./Solutions.sass"></style>

<template>
  <div
    class="checkbox__wrapper"
    :class="classObject"
  >
    <label
      class="checkbox__label-wrapper"
      :for="name"
      @click.prevent="updateInput"
    >
      <div class="checkbox__mock">
        <transition name="bounce">
          <v-icon class="checkbox__mock--icon pa-0 ma-0" size="22" v-if="checked">check</v-icon>
        </transition>
      </div>
      <span :class="getLabelClasses" v-html="label"></span>
    </label>

    <input
      class="checkbox"
      type="checkbox"
      @input="updateInput"
      @focus="onInputFocus"
      @blur="onInputBlur"
      :checked="checked"
      :ref="name"
      :id="name" 
      :name="name"
      :disabled="disabled"
    />
  </div>
</template>

<script>
  export default {
    name: 'checkbox',
    model: {
      prop: 'modelValue',
      event: 'change'
    },
    props: {
      value: [Boolean, String],
      modelValue: {
        default: false
      },
      disabled: Boolean,
      name: {
        type: String,
        required: true
      },
      label: {
        type: String,
        default: ""
      },
      hideLabel: Boolean,
      trueValue: {
        default: true
      },
      falseValue: {
        default: false
      }
    },
    data () {
      return {
        focused: false
      }
    },
    methods: {
      onInputFocus () {
        this.focused = true
      },
      onInputBlur () {
        this.focused = false
      },
      updateInput() {
        let isChecked = !this.checked

        if (this.modelValue instanceof Array) {
          let newValue = [...this.modelValue]

          isChecked
            ? newValue.push(this.value)
            : newValue.splice(newValue.indexOf(this.value), 1)

          this.$emit('change', newValue)
        } else {
          this.$emit('change', isChecked ? this.trueValue : this.falseValue)
        }
      }
    },
    computed: {
      checked() {
        return this.modelValue instanceof Array 
          ? this.modelValue.includes(this.value) 
          : this.modelValue === this.trueValue
      },
      classObject() {
        return {
          'checkbox__wrapper--checked': this.checked,
          'checkbox__wrapper--disabled': this.disabled,
          'checkbox__wrapper--focus': this.focused
        }
      },
      getLabelClasses() {
        return {
          'checkbox__label': true,
          'checkbox__label--hide': this.hideLabel
        }
      }
    }
  }
</script>

<style lang="sass" src="./Checkbox.sass"></style>

import { 
  DRAWER_TOGGLE,
  MODAL_OPEN,
  MODAL_CLOSE,
  TOGGLE_APP_WRAPPER,
  UPDATE_GLOBAL_LOADING,
  UI_ADD_LOADING_STATE,
  UI_REMOVE_LOADING_STATE
} from '../mutation-types'
import Vue from 'vue'
import { union, indexOf } from 'lodash'


const state = {
  drawerOpen: null,
  modalOpen: null,
  modalData: {},
  showAppWrapper: true,
  reportDataTableLoading: false,
  loadingStates: ['publicGauges'],
  completedStates: []
}

// getters
const getters = {
  isLoading: (state) => (value) => {
    return state.loadingStates.includes(value)
  },
  isCompleted: (state, getters) => (value) => {
    return getters.isLoading(value)
  },
  getStatus: (state, getters) => (value) => {
    return getters.isLoading(value)
  }
}

// actions creator
const actions = {
  updateDrawerOpen({commit}, payload) {
    commit(DRAWER_TOGGLE, payload)
  },
  modalOpen({commit}, payload) {
    const {id, data} = payload
    commit(MODAL_OPEN, {id, data})
  },
  modalClose({commit}) {
    commit(MODAL_CLOSE)
  },
  toggleAppWrapper({ commit }, payload) {
    commit(TOGGLE_APP_WRAPPER, payload)

    return
  },
  updateLoadingState({ commit }, {type, value}) {
    if (!type) throw new Error('updateLoadingState action creator was not passed correct params')
    commit(UPDATE_GLOBAL_LOADING, {type, value})
  },
}

// mutations (reducer)
const mutations = {
  [DRAWER_TOGGLE] (state, payload) {
    state.drawerOpen = payload
  },
  [MODAL_OPEN] (state, payload) {
    // make clone of existing state and spread payload into new state
    const {id, data} = payload
    state = Object.assign(state, {modalOpen: id, modalData: data || {}})
  },
  [MODAL_CLOSE] (state) {
    state.modalOpen = null
  },
  [TOGGLE_APP_WRAPPER](state, payload) {
    state.showAppWrapper = payload
  },
  [UI_ADD_LOADING_STATE](state, value) {
    state.loadingStates = union(state.loadingStates, [value])
  },
  [UI_REMOVE_LOADING_STATE](state, value) {
    let workingCopy = state.loadingStates.slice()
    let index = indexOf(workingCopy, value)

    if (index !== -1) {
      workingCopy.splice(index, 1)
      state.loadingStates = workingCopy
    }
  },
  [UPDATE_GLOBAL_LOADING](state, {type, value = false}) {
    Vue.set(state, type, value)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
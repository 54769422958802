<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <div v-if="checkingAuth || !gaugesReady">
        <div class="app__loader text-center">
          <div class="home-loading-ripple" />
        </div>
      </div>
      <router-view v-else />
    </transition>

    <!-- v-show directive should be set to showOfflineBar for production -->
    <transition
      name="slide-from-bottom"
      mode="out-in"
    >
      <offline-bar
        v-show="showOfflineBar"
        :sync-queue="syncQueue"
        :is-offline="isOffline"
      />
    </transition>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions
} from 'vuex'
import DisplayManagerMixin from '@/mixins/DisplayManagerMixin'
import OfflineBar from '@/components/OfflineBar/OfflineBar'
import NotificationsConnectorMixin from '@/mixins/connectors/notifications'
import GaugesConnectorMixin from '@/mixins/connectors/gauges'

const DashboardContainer = {
  name: 'dashboard-container',

  components: {
    OfflineBar
  },

  mixins: [
    NotificationsConnectorMixin,
    GaugesConnectorMixin,
    DisplayManagerMixin
  ],

  created () {
    this.fetchGauges()
    this.fetchSyncQueue()
    this.fetchHelpers()
  },

  mounted () {},

  methods: {
    ...mapActions([
      'listNotifications',
      'fetchHelpers',
      'fetchSyncQueue'
    ])
  },

  computed: {
    ...mapState({
      syncQueue: state => state.offline.syncQueue
    }),
    ...mapGetters(['isOffline'])
  }
}

export default DashboardContainer
</script>

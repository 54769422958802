<template>
  <div class="tooltip__wrapper">
    <div class="tooltip" 
      @mouseover="handleMouseEnter" 
      @mouseleave="handleMouseLeave"
      @click="handleToggle">
      <slot name="activator">
        <v-icon color="common lighten-2" size="20">{{ $vuetify.icons.values.mrl_tip }}</v-icon>
      </slot>
      <transition name="tooltip">
        <div class="tooltip__content" :class="classObj" v-if="hover || toggle">
          <slot>This will be some data that hovers</slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  let Tooltip = {
    data() {
      return {
        hover: false,
        toggle: false,
        top: false,
        bottom: false,
        right: false,
        left: false
      }
    },
    computed: {
      classObj() {
        return {
          'tooltip__content--top': this.top,
          'tooltip__content--bottom': this.bottom,
          'tooltip__content--right': this.right,
          'tooltip__content--left': this.left,
          'tooltip__content--stay': this.toggle
        }
      }
    },
    methods: {
      killStates() {
        if (!this.toggle) {
          this.hover = false
          this.top = false
          this.bottom = false
          this.right = false
          this.left = false
        }
      },
      handleToggle() {
        this.toggle = !this.toggle
        const windowWidth = window.outerWidth

        if (this.$el.getBoundingClientRect().top < 100) this.bottom = true
        else this.top = true

        if (this.$el.getBoundingClientRect().left < 100) this.right = true
        else if ((windowWidth - this.$el.getBoundingClientRect().right) < 100) this.left = true

        if (!this.toggle) this.killStates()
      },
      handleMouseEnter() {
        const windowWidth = window.outerWidth

        if (this.$el.getBoundingClientRect().top < 100) this.bottom = true
        else this.top = true

        if (this.$el.getBoundingClientRect().left < 100) this.right = true
        else if ((windowWidth - this.$el.getBoundingClientRect().right) < 100) this.left = true

        this.hover = true
      },
      handleMouseLeave() {
        this.killStates()
      }
    }
  }

  export default Tooltip
</script>

<style lang="sass" src="./Tooltip.sass"></style>

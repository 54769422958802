import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'getUserId',
      'getUserFullName',
      'isAuthenticated',
      'getUserAttribute',
      'getUserEmail',
      'isUserEmailVerified'
    ])
  }
}

import {
  UI_ADD_LOADING_STATE,
  UI_REMOVE_LOADING_STATE
} from '../mutation-types'
import chartApi from '../../api/charts'

// initial state
const state = {}

// getters
const getters = {}

// actions creator
const actions = {
  async getTheoreticaldata ({ commit }, payload) {
    const { gid } = payload
    const loadingType = `theoreticalDataLoading-${gid}`
    const failedType = `theoreticalDataFailed-${gid}`

    try {
      commit(UI_REMOVE_LOADING_STATE, failedType)
      commit(UI_ADD_LOADING_STATE, loadingType)

      // see chartApi for shape of payload
      const response = await chartApi.getTheoreticalData(payload)

      commit(UI_REMOVE_LOADING_STATE, loadingType)

      return response
    } catch (e) {
      commit(UI_ADD_LOADING_STATE, failedType)
      commit(UI_REMOVE_LOADING_STATE, loadingType)

      throw e
    }
  },

  async getHistoricalData ({ commit, getters }, payload) {
    // creating a unique key based on GID for loading the report for gauge
    const { gid, checkLoading } = payload
    const loadingType = `historicalDataLoading-${gid}`
    const failedType = `historicalDataFailed-${gid}`

    // if gauge is in the middle of loading historical data, do nothing
    if (getters.isLoading(loadingType) && checkLoading) return

    try {
      commit(UI_REMOVE_LOADING_STATE, failedType)
      commit(UI_ADD_LOADING_STATE, loadingType)

      const response = await chartApi.getHistoricalData(payload)

      commit(UI_REMOVE_LOADING_STATE, loadingType)

      return response
    } catch (e) {
      commit(UI_ADD_LOADING_STATE, failedType)
      commit(UI_REMOVE_LOADING_STATE, loadingType)

      throw e
    }
  }
}

// mutations (reducer)
const mutations = {}

export default {
  state,
  actions,
  mutations,
  getters
}

<template>
  <sortable-table v-if="logs.length"
    class="activity-log-table"
    :schema="activityLogTableSchema" 
    :data="logs"
    sort-column="logDateTime"
    :sort-direction="false"
    v-on:cell-click="handleClick"/>
  <div v-else style="margin: 15px 0;">
    No activity in the current date range.
  </div>
</template>

<script>
  import { dateToDay } from '@/utils/dateUtils'
  
  let ActivityLogTable = {
    components: {},

    props: {
      logs: {
        type: Array,
        required: true,
        default: () => {
          return []
        }
      }
    },

    data() {
      return {
        activityLogTableSchema: [
          { label: 'Helper', pointer: 'helperName' },
          { label: 'Gauge', pointer: 'gaugeName' },
          { label: 'Record', pointer: 'record' },
          { label: 'Date', pointer: 'logDateTime', format: val => dateToDay(val) }
        ]
      }
    },

    methods: {
      handleClick(e) {
        let { 
          value,
          data: {
            gid
          } = {},
          cell 
        } = e

        console.log(e)

        switch(cell) {
          case 'gaugeName':
            this.$router.push({ name: 'gaugeDetail', params: { gid } })
            break;
          default:
            break;
        }
      }
    }
  }

  export default ActivityLogTable
</script>

<style lang="scss">
  .activity-log-table {
    &.sortable-table td {
      border-right: 0;
    }

    .sortable-table__th {
      padding-left: 5px;
      padding-right: 5px;
    }

    .sortable-table__tr {
      cursor: default;
    }

    .sortable-table__td__gaugeName {
      color: $color-primary;
      cursor: pointer;
    }

    .sortable-table__td__helperName {
      font-weight: bold;
    }

    .sortable-table__td__record {
      font-weight: bold;
      font-size: $font-size-common;
    }

    .sortable-table__td__created {
      text-transform: uppercase;
      color: $color-gray-dark;
    }
  }
</style>
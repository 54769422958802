import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  customVariables: ['~/sass/variables.sass'],
  treeShake: true,
  icons: {
    iconfont: 'md',
    values: {
      // "icomoon" and "im-custom-icon" will be classes appended to the <i> tag
      // component syntax is a little different; https://vuetifyjs.com/en/framework/icons
      'mrl_rain_alert': 'icomoon im-rain-alert',
      'mrl_local_observation': 'icomoon im-Local-Observation',
      'mrl_gauge': 'icomoon im-Gauge',
      'mrl_add_gauge': 'icomoon im-Add-Gauge',
      'mrl_add_observation': 'icomoon im-Add-Observation',
      'mrl_add_user': 'icomoon im-Add-User',
      'mrl_im_add': 'icomoon im-Add',
      'mrl_arrow_down': 'icomoon im-Arrow-Down',
      'mrl_arrow_left': 'icomoon im-Arrow-Left',
      'mrl_arrow_right': 'icomoon im-Arrow-Right',
      'mrl_arrow_up': 'icomoon im-Arrow-Up',
      'mrl_better_management': 'icomoon im-Better-Management',
      'mrl_calendar': 'icomoon im-Calendar',
      'mrl_check': 'icomoon im-Check',
      'mrl_checkbox': 'icomoon im-Checkbox',
      'mrl_close': 'icomoon im-Close',
      'mrl_edit': 'icomoon im-Edit',
      'mrl_free_easy': 'icomoon im-Free-Easy',
      'mrl_full_control': 'icomoon im-Full-Control',
      'mrl_gauge_location': 'icomoon im-Gauge-Location',
      'mrl_gauge_placeholder': 'icomoon im-Gauge-Placeholder',
      'mrl_login': 'icomoon im-LogIn',
      'mrl_menu': 'icomoon im-Menu',
      'mrl_other_gauges': 'icomoon im-Other-Gauges',
      'mrl_outbound_link': 'icomoon im-Outbound-Link',
      'mrl_precipitation_report': 'icomoon im-Precipitation-Report',
      'mrl_reminder': 'icomoon im-Reminder',
      'mrl_save_time': 'icomoon im-Save-Time',
      'mrl_tip': 'icomoon im-Tip'
    }
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: {
          base: '#45B0C7',
          lighten1: '#9EE1F1'
        },
        secondary: '#0E718A',
        accent: '#93AF80',
        error: '#E77132',
        common: {
          base: '#363636',
          lighten1: '#707C8A',
          lighten2: '#A6ADB6',
          lighten3: '#e0e0e0'
        }
      }
    }
  }
})

/* eslint-disable */
import {
  dateToLocal,
  dateDifference
} from '@/utils/dateUtils'

const startingYear = 1981;
const historicEndingYear = new Date().getFullYear() - 1;
export const historicStart = `${startingYear}-01-01`;
export const historicEnd = historicEndingYear + "-12-31"; //make this dynamic later
export const quantileKeys = ['Q1', 'Q2', 'Q3', 'Q4', 'Q5']
export const historicalLabel = "Historical Rainfall"

var numYears = parseInt(historicEnd) - parseInt(historicStart) + 1;

export function massageTheoreticalData(data) {
  return data.reduce((accum, value, index) => {
    // if first record, force to be 0.00
    if (index === 0) { 
      return accum.concat({
        x: dateToLocal(value[0], ["YYYY-MM-DD"]),
        y: 0.00
      })
    }

    // some values are -999, setting those to 0
    if (value[1] < 0) value[1] = 0

    // if there was actual precip, add it to the previous value
    if (value[1] >= 0) {
      return accum.concat({
        x: dateToLocal(value[0], ["YYYY-MM-DD"]),
        y: value[1] + accum[accum.length - 1].y
      })
    }

    return accum
  }, [])
}

export function massageHistoricalData(data) {
  return quantileKeys.reduce((accum, value, index) => {
    accum[value] = []
    let tempCumulative = 0

    data.forEach((data, i) => {
      accum[value].push({
        x: data[0],
        y: data[index + 1]
      })  
    })
    
    return accum
  }, {})  
}

//---------------------------------------------------
// apply correction to daily dataset while collating all values by relevant doy (day of year)
//---------------------------------------------------
export function processHistoricalData({startDate, endDate, rawDaily, rawMonthly}) {
    //determine correction factor
    var avgYearlySumDaily = determineAverageYearlySum(rawDaily);
    var avgYearlySumMonthly = determineAverageYearlySum(rawMonthly);
    var correctionFactor = avgYearlySumMonthly / avgYearlySumDaily;
    var startYear = parseInt(startDate)
    var endYear = parseInt(endDate)
    var crossyear = endYear > startYear;

    // this return a float value of the difference between (user inputs) startDate and endDate
    var differenceInYears = dateDifference(startDate, endDate, 'years', true);
    // simple boolean flag if differenceInYears is great than 1
    var beyondFullYear = differenceInYears > 1;
    // rounded down differenceInYears to know how many times the years "lapped"
    var yearsOverlapped = Math.floor(differenceInYears);

    console.log("correction factor calculated...");
    console.log(correctionFactor);

    console.log("collating by DOY...");
    var precipValuesByDOY = {};
    var runningTotal = 0;
    var startDoy = startDate.substring(5);
    var endDoy = endDate.substring(5);

    //first, we carve off the beginning of 1981 to match up with the requested startDate for accumulation reasons
    for (let i=0; i < 365; i++) {
        let currentDay = rawDaily[0];
        let currentDoy = currentDay[0].substring(5);
        if (currentDoy < startDoy) {
            //removes this 0th element from the array
            rawDaily.shift()
        }
        else {
            break;
        }
    }

    /**
     * The two scenarios: Beyond One Year vs. Within One year
     * Are broken into a single if statement
     * 1. Beyond One Year requires a recursive method since we re "time jumping"
     * 2. Within One Year remains linear and loops through all rawDaily to determine if day in question
     * is between date range (year agnostic)
     * 
     * Both scenarios do the following:
     * slice out the appropriate dates from every year into precipValuesByDOY, while also filtering out Feb 29
     * apply correction factor to each precip value while doing this
     */
    if (beyondFullYear) {
      const getPrecipRecurse = (year, runningValues, nextIndex = 0) => {
        let passedStartYear = false
        let lapCount = []
        // where to start the for loop
        let rawDailyIndex = nextIndex
        const precipValues = runningValues

        // if the current year that is being process (i.e. 1981, 1982, 1983, ...)
        // plus the differenceInYear (user inputs) are great than historicEndingYear
        // don't append any additional DOY values
        if ((year + differenceInYears) > historicEndingYear) {
          return precipValues
        }

        //then we start accumulating for each requested date range
        for (let i = rawDailyIndex; i < rawDaily.length; i++) {
          let currentDay = rawDaily[i];
          let currentDoy = currentDay[0].substring(5);

          if (passedStartYear || currentDay[0].includes(year)) {
            passedStartYear = true

            if (currentDoy === startDoy) {
              if (lapCount.length === 1) {
                rawDailyIndex = i
              }
              lapCount.push(currentDoy);
            }
  
            /**
             * In the "Within One year" scenario we are keying precipValues by DOY:
             * { '01-01': [], '01-02': [] }
             * In the "Beyond One Year" scenario, those DOY values are no longer unique
             * because the years can "lap", in this scenario we are used the "lapCount" to suffix the DOY key:
             * { '01-01--1': [], '01-02--1': [] }
             * If the year laps over January:
             * { '01-01--1': [], '01-01--2': [], '01-02--1': [], '01-02--2': [] }
             */
            let precipKey = `${currentDoy}--${lapCount.length}`
  
            if (currentDoy === "02-29") {
              continue;
            } else {
              if (!precipValues[precipKey]) {
                precipValues[precipKey] = [];
              }
  
              runningTotal += currentDay[1] * correctionFactor;
              precipValues[precipKey].push(runningTotal);
  
              if (
                lapCount.length > yearsOverlapped &&
                currentDoy === endDoy
              ) {
                runningTotal = 0;
                break;
              }
            }
          }
        }

        // if the current year that is being process (i.e. 1981, 1982, 1983, ...)
        // is the same as the ending historic year, stop the recursive invocations and return precipValues
        return year === historicEndingYear
          ? precipValues
          : getPrecipRecurse(year + 1, precipValues, rawDailyIndex)
      }

      precipValuesByDOY = getPrecipRecurse(startingYear, [])
    } else {
      for (let i = 0; i < rawDaily.length; i++) {
        let currentDay = rawDaily[i];
        let currentDoy = currentDay[0].substring(5);
  
        if (currentDoy === "02-29") {
          continue;
        }
        else if (
          // IF crossyear AND NOT (greater than enddate AND less than startdate) OR NOT crossyear AND in between start and end dates
          // basically is the date in question between the date range, but logic is difficult
          // because we are only deailing with "day of year", no year
          ((crossyear && !(currentDoy > endDoy && currentDoy < startDoy)) || (!crossyear && currentDoy >= startDoy && currentDoy <= endDoy))
        ) {
          if(!precipValuesByDOY[currentDoy]) {
            precipValuesByDOY[currentDoy] = [];
          }
  
          runningTotal += currentDay[1] * correctionFactor;
          precipValuesByDOY[currentDoy].push(runningTotal);
  
          if (currentDoy === endDoy) {
            runningTotal = 0;
          }
        }
      }
    }
    console.log("done collating...");
    // console.log(precipValuesByDOY);

    //---------------------------------------------------
    // calculate quantiles for each DOY into historicalQuantiles
    //---------------------------------------------------

    //output an array of arrays like so: [['2017-01-01',0.01,0.23,0.74,1.98,4.66],['2017-01-02',0.02,0.25,0.77,2.04,4.74],...]
    var historicalQuantiles = [];
    var currentDate = new Date(startDate);
    var firstDoy = currentDate.toISOString().substring(5,10);
    var stopDate = new Date(endDate);
    var yearCount = 0

    console.log('PRECIP', precipValuesByDOY)
    console.log("calculating quantiles...");
    while (currentDate <= stopDate) {
      let currentQuantiles = [];
      let currentDoy = currentDate.toISOString().substring(5,10);
      if (currentDoy === firstDoy) {
        yearCount = yearCount + 1 
      }

      // account for new "lapCount" suffix if beyondFullYear is true
      let precipKey = beyondFullYear ? `${currentDoy}--${yearCount}` : currentDoy
      let currentArray = precipValuesByDOY[precipKey] || [];

      currentQuantiles.push(currentDate.toISOString().substring(0,10));

      currentArray.sort(sortNumber);
      currentQuantiles.push(quantile(currentArray,2));
      currentQuantiles.push(quantile(currentArray,16));
      currentQuantiles.push(quantile(currentArray,50));
      currentQuantiles.push(quantile(currentArray,84));
      currentQuantiles.push(quantile(currentArray,98));

      historicalQuantiles.push(currentQuantiles);

      let newDate = currentDate.setDate(currentDate.getDate() + 1);
      currentDate = new Date(newDate);
    }
    console.log("done calculating quantiles...");
    // console.log(historicalQuantiles);

    return historicalQuantiles
  }
//---------------------------------------------------
// functions for calculating quantiles
//---------------------------------------------------

//this function ASSUMES array is already sorted
function quantile(array, percentile) {
  var result;
  var index = percentile/100. * (array.length-1);
  if (Math.floor(index) === index) {
    result = array[index];
  } else {
    let i = Math.floor(index);
    let fraction = index - i;

    result = array[i] + (array[i+1] - array[i]) * fraction;
  }
  return result;
}

function sortNumber(a,b) {
  return a-b;
}


//---------------------------------------------------
// function for helping with determine correction factor
//---------------------------------------------------

//this function iterates over the given dataset, sums all the precipitation across all days/months then divides the sum by numYears to return an average yearly sum of precipitation
function determineAverageYearlySum(dataset) {
  var totalPrecip = 0;
  for (let i=0; i < dataset.length; i++) {
    var currentData = dataset[i];

    if (currentData[1] === -999) {
      //in Mike's code they alter the value to "NA" but I figure that will mess up our calculations and zero should be OK
      currentData[1] = 0;
    }

    //adding all values across all years and will later divide by numYears to determine average yearly sum
    totalPrecip += currentData[1];
  }

  //global numYears
  return totalPrecip / numYears;
}
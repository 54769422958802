<template>
  <page-section centered>
    <h2>{{ title || 'The Numbers' }}</h2>

    <public-summary-wrapper>
      <v-row v-if="!publicLoading" class="py-5">
        <v-col cols="12" sm="6" md="3">
          <div class="stat">
            <div class="stat__large-digit"><number-counter :number="getPublicTotalInches" :format="thousandSeparated"/>"</div>
            <div class="stat__large-digit-sub">PRECIPITATION LOGGED</div>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <div class="stat">              
            <div class="stat__large-digit"><number-counter :number="getPublicGaugeCount"/></div>
            <div class="stat__large-digit-sub">GAUGES</div>
          </div>  
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <div class="stat">              
            <div class="stat__large-digit"><number-counter :number="getPublicLogCount" :format="thousandSeparated"/></div>
            <div class="stat__large-digit-sub">LOGS RECORDED</div>
          </div>  
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <div class="stat">              
            <div class="stat__large-digit"><number-counter :number="getPublicUsersWithGauges" :format="thousandSeparated"/></div>
            <div class="stat__large-digit-sub">PARTICIPANTS</div>
          </div>
        </v-col>
      </v-row>
      
      <spinner v-else :size="50"/>

      <slot name="actions"></slot>
    </public-summary-wrapper>
    
  </page-section>
</template>

<script>
import PublicSummaryWrapper from '@/components/PublicSummaryWrapper/PublicSummaryWrapper'
import { mapGetters } from 'vuex'
import { thousandSeparated } from '@/utils/numUtils'

let TheNumbers = {
  components: {
    PublicSummaryWrapper
  },

  mounted() {
    setTimeout(() => {
      this.publicLoading = false
    }, 1250)
  },

  props: {
    title: String
  },

  data() {
    return {
      thousandSeparated,
      publicLoading: true
    }
  },

  computed: {
    ...mapGetters([
      'getPublicGaugeCount',
      'getPublicLogCount',
      'getPublicTotalInches',
      'getPublicUsersWithGauges'
    ])
  },
}

export default TheNumbers
</script>

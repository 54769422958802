<template>
  <div :class="getClasses">
    <div class="filter-list__search-wrapper">
      <v-text-field
        v-model="mx_value"
        :label="label"
        outlined
        single-line
        append-icon="search"
        name="filterInput"
        aria-autocomplete="off"
        autocomplete="off"
      />
    </div>

    <spinner :size="40" v-if="loading"/>

    <ul class="filter-list__list" v-else>
      <slot name="list"
        v-for="item in filteredItems"
        :item="item">
      </slot>

      <div v-if="!filteredItems.length" class="filter-list__list-empty">
        <slot name="empty-item">
          No items matched
        </slot>
      </div>
    </ul>
  </div>
</template>

<script>
  import ExternalBind from '@/mixins/ExternalBind'
  import { filter, values, map } from 'lodash'

  let FilterList = {
    components: {},

    mixins: [ExternalBind],

    watch: {
      'this.$refs.menu'(val) {
        console.log(val)
      }
    },

    mounted() {
      if (this.filterSort && !this.$slots['menu']) {
        console.warn('[FilterList] warning: filterSort is enabled but no menu slot found')
      }
    },

    props: {
      items: {
        type: Array,
        default() {
          return []
        },
        required: true
      },
      label: String,
      loading: Boolean,
      itemsPerPage: {
        type: [Number, String],
        default: 10
      },
      filterSort: {
        type: Boolean,
        default: false
      },
      quickFilterFieldName: String, // TODO: not in use right now
      quickFilterPredicates: {
        type: Array,
        default: () => {
          return []
        }
      },
      sortBy: {
        type: Object,
        default: () => {
          return {
            field: 'Name',
            order: 'asc'
          }
        }
      }
    },

    data() {
      return {
        menu: false
      }
    },

    computed: {
      innerItems() {
        let { field, order } = this.sortBy
        let innerItems = this.items.slice()

        return innerItems.sort((a, b) => {
          return (a[field] > b[field])
            ? (order === 'asc') ? 1 : -1
            : (order === 'asc') ? -1 : 1
        })
      },
      filteredItems() {
        return filter(this.innerItems, (item) => {
          let searchMe = values(item).toString().toLowerCase()

          let arrayOfPassingFieldConditions = map(this.quickFilterPredicates, (value) => {
            return (typeof value === 'function')
              ? value(item)
              : true
          })

          /**
            This could be a version if we wanted to pass in field columns

            let arrayOfPassingFieldConditions = map(this.quickFilterValues, (value) => {
              return (typeof value === 'string')
                ? !!(item[this.quickFilterFieldName].toLowerCase() === value.toLowerCase())
                : value(item[this.quickFilterFieldName])
            })
           */

          let meetsConditions = (this.quickFilterPredicates.length)
            ? arrayOfPassingFieldConditions.includes(true)
            : true

          return searchMe.includes(this.mx_value.toLowerCase()) && meetsConditions
        }).slice(0)
      },
      getClasses() {
        return {
          'filter-list': true,
          'filter-list--with-menu': this.filterSort
        }
      }
    }
  }

  export default FilterList
</script>

<style lang="scss">
  .filter-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }
  
  .filter-list--with-menu {
    .filter-list__search-wrapper {
      padding: 10px 5px 10px 15px;
    }

    input {
      outline: none;
    }


  }
  
  .filter-list__menu {
    border-radius: 0 !important;
    
    .card, .card__text {
      border-radius: 0 !important;  
    }
    
    .list__tile {
      min-height: 48px;
      height: auto;
    }

    .btn-toggle {
      display: flex !important;
      flex-direction: column !important;
    }
    
    .btn-toggle .btn:not(:last-child) {
      border-right: none;
    }
  }
  
  .filter-list__list {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    flex: 1;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .filter-list__list-empty {
    li { pointer-events: none; }
  }
</style>
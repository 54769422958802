import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions([
      'addGauge',
      'updateGauge',
      'archiveGauge',
      'unarchiveGauge',
      'deleteGauge',
      'sortGauges',
      'addGaugeLog',
      'updateLog',
      'deleteLog',
      'massageLogs'
    ])
  }
}
<template>
  <v-container fluid grid-list-lg id="contact" class="contact pt-0">
    <page-section header>
      <h1>Say Hello</h1>
      <p>Drop us a note and we'll get back to you right away.</p>
    </page-section>
    <page-section centered>
      <div class="form__wrapper">
        <transition name="fade" mode="out-in">
          <div class="form__errors" v-if="error">
            {{ error }}
          </div>
        </transition>

        <transition>
          <div class="form__success" v-if="successMsg">
            {{ successMsg }}
          </div>
        </transition>

        <form @submit.prevent="formSubmit(sendEmail)" class="form" novalidate="novalidate">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.name"
                v-validate="'required'"
                label="Name"
                required
                data-vv-name="name"
                name="name"
                outlined
                single-line
                :error-messages="errors.collect('name')"
              />

              <v-text-field
                v-model="form.email"
                v-validate="'required|email'"
                label="Email"
                required
                data-vv-name="email"
                name="email"
                outlined
                single-line
                :error-messages="errors.collect('email')"
              />

              <v-textarea
                v-model="form.message"
                v-validate="'required'"
                :error-messages="errors.collect('message')"
                name="message"
                :label="question.message"
                rows="4"
                outlined
              />

              <div class="my-3">
                <p>While you’re here, we’re curious if you have any suggestions or experienced issues using this site. Perhaps you’d like to see some features added.</p>
                <p>We want to hear from you.</p>
              </div>

              <v-text-field
                v-model="form.problems"
                :label="question.problems"
                outlined
                single-line
                name="problems"
              />

              <v-text-field
                v-model="form.ideas"
                :label="question.ideas"
                outlined
                single-line
                name="ideas"
              />

              <v-text-field
                v-model="form.suggestions"
                :label="question.suggestions"
                outlined
                single-line
                name="suggestions"
              />

              <checkbox
                v-model="form.loveThisSite"
                name="loveThisSite"
                :label="question.loveThisSite"
                outline
                single-line
                class="contact__form-confirmation"
              />
            </v-col>
          </v-row>

          <div class="form__actions">
            <v-btn type="submit" color="accent" depressed rounded :loading="loading" large>Say Hello</v-btn>
          </div>              
        </form>  
      </div>
    </page-section>

    <page-section centered slim>
      <div class="team-member">
        <div class="team-member__img"><img src="../About/images/MikeCrimmins.jpg" /></div>
        <h3 class="team-member__name">Michael A. Crimmins <span class="subset">Ph.D.</span></h3>
        <ul class="team-member__titles">
          <li>Associate Professor &amp; Extension Specialist - Climate Science</li>
          <li><strong>Department of Soil, Water, &amp; Environmental Science</strong></li>
          <li><strong>The University of Arizona</strong></li>
          <li><a href="tel:1-520-626-4244">(520) 626-4244</a></li>
          <li>crimmins@email.arizona.edu</li>
        </ul>  
      </div>
    </page-section>

    <get-started/>
  </v-container>
</template>

<script>
  import GetStarted from '@/components/GetStarted/GetStarted'
  import FormValidation from '@/mixins/FormValidation'
  import SendMessageApi from '@/api/sendMessage'
  import scroll from 'zenscroll'

  export default {
    name: 'contact',
    components: {
        GetStarted
    },
    mixins: [FormValidation],
    data () {
      return {
        loading: false,
        title: "We want to help you succeed",
        tagline: "We’d love to hear from you. Drop us a note and we’ll get back to you right away.",
        msg: '',
        error: '',
        successMsg: '',
        question: {
          message: "What's on your mind?",
          problems: "Experience any problems? Let us know. (optional)",
          ideas: "Ideas for help features. Please tell. (optional)",
          suggestions: "Suggestions for the gauges? We're all ears. (optional)",
          loveThisSite: "I love this site!",
        },
        form: {
          name: '',
          email: '',
          message: '',
          problems: '',
          ideas: '',
          suggestions: '',
          loveThisSite: false
        }
      }
    },
    methods: {
      sendEmail: function (event) {
        const form = { ...this.form },
              questions = { ...this.question }
        // log("contact -> message: %o", form);

        var br = "\n";//"\r\n";// "%0D%0A"

        var formatIfAnswered = function (field) {
          if (form[field]) {
            return br + `* ${questions[field]}` + br + `${form[field]}` + br
          }
          return '';
        }
        var loveThisSite = form.loveThisSite ? br + questions.loveThisSite : ""

        var msg = "Sent via the Contact Form at https://MyRAINgeLog.arizona.edu :" + br + br

        msg += `From: ${form.name} (${form.email})` + br + br

        msg += formatIfAnswered('message')
        msg += formatIfAnswered('problems')
        msg += formatIfAnswered('ideas')
        msg += formatIfAnswered('suggestions')
        msg += loveThisSite
        msg += br

        var onSuccess = this.onSuccess,
            onError = this.onError;

        // (email, message, subject)
        SendMessageApi.sendMessage('myRAINgeLog@cals.arizona.edu', msg, "Contact from MyRAINgeLog.arizona.edu web form" )
          .then(function (response) {
              console.log("sendMessage resp:",response);
              if (response.success) {
                onSuccess("Mail sent successfully, we'll be in contact soon!")
              } else {
                onError("Oops, something strange happened! Please Try Again")
              }
          })
          .catch(function (error) {
              console.warn("sendMessage ERROR:",error);
              onError("Oops, something unexpected happened! Please Try Again")
          })
      },

      onSuccess: function (msg) {
        scroll.to(this.$el)
        this.loading = false
        this.successMsg = msg
        this.form.name = ''
        this.form.email = ''
        this.form.message = ''
        this.form.problems = ''
        this.form.ideas = ''
        this.form.suggestions = ''
        this.form.loveThisSite = false
        this.$nextTick(() => {
          this.errors.clear()
        })
      },

      onError: function (err) {
        this.loading = false
        this.error = err
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" src="./Contact.sass"></style>

<template>
  <v-container fluid id="gauge_add" class="gauge-add pt-0">
    <form @submit.prevent="formSubmit(saveGauge)" class="form" novalidate>
      <page-section headerImage>
        <image-input name="editImage" v-model="form.image">
          <template slot="button">
            <v-btn outlined small rounded text color="accent" @click.prevent class="ma-0">
              <v-icon size="32">photo_camera</v-icon>
              Add Photo 
            </v-btn>
          </template>
          <template #preview="{ src }">
            <div class="gauge-add__preview-img"
              :style="{
                backgroundImage: `url(${src})`
              }">
            </div>
          </template>
        </image-input>
        <h1 class="mb-2">Add Gauge</h1>

        <div class="form__wrapper">
          <v-text-field
            class="page-section__large-input"
            v-model="form.title"
            v-validate="'required'"
            placeholder="Enter Gauge Name"
            required
            outlined
            single-line
            data-vv-name="title"
            name="title"
            persistent-hint
            hint='(e.g. "Gauge at SW Corner")'
            :error-messages="errors.collect('title')"
            aria-autocomplete="off"
            autocomplete="off"
          />
        </div>
      </page-section>

      <page-section>
        <div class="form__errors" v-if="isOffline">
          This application does not currently support adding gauges while offline.
        </div>

        <template v-else>
          <div class="form__errors" v-if="error">
            {{ error }}
          </div>

          <div class="form__wrapper">
            <div class="gauge-add__preferences-wrapper pa-4 mb-3">
              <v-row class="align-center">
                <v-col cols="12" sm="7">
                  <h3>First Observation (Base Level)</h3>
                  <p>
                    When setting up your rain gauge it should begin with a base level to ensure cumulative values are added correctly. These values can be adjusted later. Read our <a href="https://extension.arizona.edu/sites/extension.arizona.edu/files/pubs/az1747-2017_0.pdf" target="_blank" rel="noopener">gauge construction guide <v-icon color="primary" size="15">open_in_new</v-icon></a> for more information.
                  </p>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-row>
                    <v-col cols="12">
                      <date-menu
                        v-model="logDateTime"
                        v-validate="'validDate'"
                        :error-messages="errors.collect('date')"
                        data-vv-name="date"
                        name="date"
                        hide-actions
                        label="Date of First Observation"
                        :text-field-props="{
                          hideDetails: true,
                          outlined: true,
                          singleLine: true
                        }"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="reading"
                        v-validate="'required|decimal:3'"
                        label="Reading"
                        required
                        type="number"
                        aria-required="true"
                        data-vv-name="reading"
                        name="reading"
                        ref="reading"
                        outlined
                        single-line
                        hide-details
                        class="white"
                        :error-messages="errors.first('reading')"
                        v-input-no-scroll="'reading'"
                        aria-autocomplete="off"
                        autocomplete="off"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-select 
                        outlined
                        single-line
                        hide-details
                        class="white"
                        v-model="form.units"
                        :items="items"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            
            <v-row class="mb-2">
              <v-col cols="12" sm="6">
                <gauge-add-map v-model="mapLatLng" v-lock-on-scroll/>
              </v-col>

              <v-col cols="12" sm="6">
                <h3 class="mt-3">Location</h3>
                <p class="mb-3">Allow the system to detect your location or double click and drag the marker to set the latitude and longitude values.</p>

                <v-text-field
                  v-model="form.latitude"
                  v-validate="'required'"
                  label="Latitude"
                  required
                  outlined
                  single-line
                  hide-details
                  data-vv-name="latitude"
                  name="latitude"
                  class="mb-3"
                  :error-messages="errors.collect('latitude')"
                  aria-autocomplete="off"
                  autocomplete="off"
                />

                <v-text-field
                  v-model="form.longitude"
                  v-validate="'required'"
                  label="Longitude"
                  required
                  outlined
                  single-line
                  hide-details
                  data-vv-name="longitude"
                  name="longitude"
                  class="mb-3"
                  :error-messages="errors.collect('longitude')"
                  aria-autocomplete="off"
                  autocomplete="off"
                />

                <a href="http://rcn.montana.edu/Resources/Converter.aspx" target="_blank" rel="noopener">Convert your geographic units</a>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <p id="description_label">
                  <strong>Describe the gauge type and location</strong>
                </p>
                <v-textarea
                  v-model="form.description"
                  name="description"
                  aria-labelledby="description_label"
                  outlined
                  single-line
                  placeholder='For example “Clear PVC gauge with cap mounted on T-post. Located near water trough on NE side, 30 ft from any trees."'
                  rows="4">
                </v-textarea>
              </v-col>
            </v-row>
          </div>

          <h2>Preferences</h2>
          
          <div class="form__wrapper gauge-add__preferences-wrapper pa-4">
            <gauge-preferences
              v-model="preferences"
              :gauge="gauge"
              :notification-settings="notificationSettings"
            />
          </div>

          <div class="form__actions">
            <v-btn type="submit" color="accent" depressed rounded :loading="loading" large>Add Gauge</v-btn>
            <v-btn 
              text
              large
              exact
              :to="{ name: 'dashboard' }"
              class="bare primary--text"
            >
              Cancel
            </v-btn>
          </div>
        </template>
      </page-section>
    </form>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { NETWORK_ERROR, UNITS } from '@/utils/constants'
import FormValidation from '@/mixins/FormValidation'
import GaugeAddMap from '@/components/GaugeAddMap/GaugeAddMap'
import RccDataMixin from '@/mixins/RccDataMixin'
import GaugeFormMixin from '@/mixins/GaugeFormMixin'
import GaugePreferences from '@/components/GaugePreferences/GaugePreferences'
import NotificationsConnectorMixin from '@/mixins/connectors/notifications'
import GaugesConnectorMixin from '@/mixins/connectors/gauges'
import { pick } from 'lodash'

import {
  dateToDay,
  dateToUTC,
  dateToTime
} from '@/utils/dateUtils'

import convert from 'convert-units'
import numbro from 'numbro'
import scroll from 'zenscroll'

export default {
  name: 'GaugeAdd',

  components: {
    GaugeAddMap,
    GaugePreferences
  },
  
  mixins: [
    FormValidation,
    RccDataMixin,
    GaugeFormMixin,
    NotificationsConnectorMixin, // TODO: add new preference to gauge add submit
    GaugesConnectorMixin
  ],

  data () {
    // data.form is in GaugeFormMixin
    return {
      error: false,
      reading: 0,
      logDateTime: dateToDay(),
      items: UNITS.slice(),
      preferences: {}
    }
  },

  computed: {
    ...mapState({
      isOffline: (state, getters) => getters.isOffline,
    }),
    mapLatLng: {
      // validation for this latLng array happend in GaugeAddMap component
      get () {
        return [this.form.latitude, this.form.longitude]
      },
      set (value) {
        this.form.latitude = value[0]
        this.form.longitude = value[1]
      }
    }
  },

  methods: {
    ...mapActions(['addGauge', 'addGaugeLog']),
    async saveBaseLevel ({ gid, created, units }) {
      try {
        const baseLevel = {
          userId: this.getUserId,
          gid: gid,
          logDateTime: dateToUTC(`${this.logDateTime} ${dateToTime()}`, ['MM/DD/YYYY HH:mm:ss']),
          units: units,
          reading: numbro(convert(this.reading).from(units).to('in')).format('0.000'),
          notes: '',
          isReset: true
        }

        this.addGaugeLog(baseLevel)
      } catch (e) {
        // we can let this error out because the LogAdd accont for Base Level as well
        console.warn(e)
      }
    },
    async saveGauge () {
      try {
        const gauge = {
          userId: this.getUserId,
          title: this.form.title,
          description: this.form.description,
          latitude: this.form.latitude,
          longitude: this.form.longitude,
          image: (Array.isArray(this.form.image)) ? this.form.image[0] : this.form.image
        }

        const gaugePreferences = pick(this.preferences, ['isPublic', 'publicProps', 'units'])
        const notificationPrefereneces = pick(this.preferences, ['sendEmail', 'threshold'])

        const response = await this.addGauge({
          ...gauge,
          ...gaugePreferences
        })

        if (!response.gid) {
          throw new Error('[addGauge] did not return valid gid')
        }

        try {
          await this.createNotification({
            gid: response.gid,
            ...notificationPrefereneces
          })
        } catch (e) {
          // handle notification error separately
        }

        this.dispatchHistoricalData({ gid: response.gid })
        this.saveBaseLevel(response)
        this.$router.push({ name: 'gaugeAddSuccess', params: { gid: response.gid } })
        setTimeout(() => { this.loading = false }, 600)
      } catch (e) {
        scroll.to(this.$el)
        this.error = NETWORK_ERROR
        this.loading = false

        throw e
      }
    }
  }
}
</script>

<style lang="sass" src="./GaugeAdd.sass"></style>

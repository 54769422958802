<template>
  <v-dialog
    ref="dialog"
    persistent
    v-model="modal"
    width="290px"
    :class="getClasses">
    <template v-slot:activator="{ on }">
      <v-text-field
        class="white"
        v-on="on"
        v-model="dateFormatted"
        outlined
        single-line
        dense
        v-bind="defaultInputProps"
        @click:append="on.click"/>
    </template>
    <v-btn icon class="date-picker__clear" @click.stop="clear" v-if="innerDate && clearable && !disabled">
      <v-icon>close</v-icon>
    </v-btn>
    <v-date-picker v-bind="defaultPickerProps" v-model="innerDate" @input="modal = (hideActions) ? false : true">
      <template v-if="!hideActions">
        <v-spacer></v-spacer>
        <v-btn text rounded @click="handleCancel">Cancel</v-btn>
        <v-btn text rounded color="secondary" @click="handleSave">Okay</v-btn>
      </template>
    </v-date-picker>
  </v-dialog>
</template>

<script>
  import { dateToDay, datePicker } from '@/utils/dateUtils'

  let DatePicker = {
    components: {},

    watch: {
      // "date" is the value syncing from the parent
      value: {
        handler(val) {
          // this.innerDate is being used by the VDatePicker
          this.innerDate = (val) ? datePicker(val) : null

          // this.dateFormatted is used by v-text-field
          this.dateFormatted = (val) ? this.displayFormat(val) : null
        },
        immediate: true
      },
      innerDate(val) {
        // only emit 'input' if there are no actions buttons for manually "Saving"
        if (this.hideActions) {
          val
            ? this.$emit('input', this.returnFormat(val))
            : this.$emit('input', null)
        }
      }
    },

    props: {
      errorMessages: [Array, String],
      label: String,
      name: String,
      value: null,
      hideActions: Boolean,
      clearable: Boolean,
      // formats the date for date.sync
      // and formats the date for $emit('save')
      returnFormat: {
        type: Function,
        default: dateToDay
      },
      // how to display the date in the input field
      displayFormat: {
        type: Function,
        default: (val) => {
          return dateToDay(val)
        }
      },
      disabled: Boolean,
      // pass in VTextProps
      // https://vuetifyjs.com/en/components/text-fields
      textFieldProps: {
        type: Object,
        default: () => ({})
      },
      // pass in VDatePicker props
      // https://vuetifyjs.com/en/components/date-pickers
      pickerProps: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        // this.innerDate is bound to the date pick
        // Vuetify datePicker requires format to be YYYY-MM-DD
        innerDate: null,
        dateFormatted: null,
        // boolean to hide/show dialog window
        modal: false
      }
    },

    computed: {
      defaultPickerProps() {
        return {
          color: 'secondary',
          ...this.pickerProps
        }
      },
      defaultInputProps() {
        return {
          label: this.label,
          readonly: true,
          name: this.name,
          disabled: this.disabled,
          appendIcon: 'event',
          errorMessages: this.errorMessages,
          ...this.textFieldProps
        }
      },
      getClasses() {
        return {
          'date-picker': true,
          'date-picker--disabled': this.disabled
        }
      }
    },

  methods: {
      handleCancel() {
        this.innerDate = null
        this.modal = false
      },
      handleSave() {
        this.$emit('save', this.returnFormat(this.innerDate))
        this.$emit('input', this.returnFormat(this.innerDate))
        this.$refs.dialog.save(this.innerDate)
      },
      clear() {
        this.innerDate = null
      }
    }
  }

  export default DatePicker
</script>

<style lang="sass">
  .date-picker
    .v-text-field
      .v-icon
        color: $theme-accent
        
  .date-picker--disabled
    .v-input__slot
      border-style: dashed !important
    .v-dialog__activator
      pointer-events: none
  
  .date-picker__clear
    position: absolute
    right: -8px
    top: 8px
</style>
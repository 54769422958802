<template>
  <div class="gauge-list-item-stats">
    <div
      v-if="getHelperCount(gid)"
      class="gauge-list-item-stats__helpers-total"
    >
      {{ getHelperLabel }}
    </div>
    <stat
      :stat="cumulative"
      label="Total"
      class="mr-2"
    />
    <stat
      class="gauge-list-item-stats__log-date"
      :stat="lastLogDate"
      label="last log"
    />

    <tooltip>{{ getCumulativeTooltip }}</tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { dateToDayShort } from '@/utils/dateUtils'

export default {
  name: 'GaugeListItemStats',

  props: {
    gid: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters([
      'getHelpersByGid',
      'getLastLogDateByGid',
      'getTotalCumulation',
      'getTotalCumulationWithModifier',
      'getLogListByDateRange'
    ]),

    cumulative () {
      return this.getTotalCumulationWithModifier({ gid: this.gid })
        ? this.getTotalCumulationWithModifier({ gid: this.gid })
        : 'N/A'
    },

    getHelperLabel () {
      return this.getHelperCount(this.gid) === 1
        ? `${this.getHelperCount(this.gid)} Helper` 
        : `${this.getHelperCount(this.gid)} Helpers`
    },

    getCumulativeTooltip () {
      return !this.getTotalCumulationWithModifier({ gid: this.gid })
        ? 'No observations recorded within set date range'
        : `Cumulative value across ${this.getLogListByDateRange(this.gid).length} observations(s)`
    },
    lastLogDate () {
      return dateToDayShort(this.getLastLogDateByGid(this.gid)) || 'No Observations'
    }
  },

  methods: {
    getHelperCount (gid) {
      return this.getHelpersByGid(gid, true).length
    }
  }
}
</script>

<style lang="sass" src="./GaugeListItemStats.sass"></style>
